import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsubscribeComponent } from './unsubscribe.component';


@NgModule({
    declarations: [UnsubscribeComponent],
    imports: [ CommonModule ],
    exports: [UnsubscribeComponent],
    providers: [],
})
export class UnsubscribeModule {}