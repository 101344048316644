import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';

@Injectable()
export class ContactService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(private _http: HttpClient) {
        this.url = environment.urlAPI;
    }

    /**
     * Funcion para dar de alta una dirección
     * @param idclient del que vamos a crear la dirección
     * @param body datos de la direccion
     */
    public getTypesContact(): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETTYPESCONTACT;
        return this._http.get(this._apiUrl);
    }

    /**
     * Funcion para enviar el contacto
     * @param body información del contacto para enviar
     */
    public sendContact(body: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.SENDCONTACT;
        return this._http.post(this._apiUrl, body);
    }
}
