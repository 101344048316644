import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(private _http: HttpClient) {
        this.url = environment.urlAPI;
    }

    /**
     * Petición para obtener la confirmacion del email del cliente
     * @param idclient id del cliente
     */
    public getDataClient(idclient: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETDATACLIENT + idclient;
        return this._http.get(this._apiUrl);
    }

    /**
     * Funcion para modificar los datos del cliente
     * @param idclient id cliente a modificar
     * @param body datos del cliente
     */
    public getDataDirectionClient(idclient: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETDATADIRECTIONCLIENT + idclient + '/addresses';
        return this._http.get(this._apiUrl);
    }

    /**
     * Funcion para modificar los datos del cliente
     * @param idclient id del cliente a modificar
     * @param obj datos del cliente para modificar
     */
    public updateDataClient(idclient: any, obj: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.UPDATEDATACLIENT + idclient;
        return this._http.put(this._apiUrl, obj);
    }

    /**
     * Funcion para saber las estrellas del cliente
     * @param idclient id del cliente
     */
    public getScoreClient(idclient: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETSCORECLIENT + idclient + '/balance';
        let params: HttpParams = new HttpParams();
        params = params.append('loyaltyUnitId', '7');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener las estrellas que van a caducar del cliente
     * @param idclient id del cliente
     */
    public getExpirationScoreClient(idclient: any): Observable<any> {
      this._apiUrl = this.url + AppSettings.GETEXPIRATIONSCORE + idclient + '/balanceexpiration';
      let params: HttpParams = new HttpParams();
      params = params.append('loyaltyUnitId', '7');
      return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener si el cliente tiene el producto gratis
     * @param idclient id del cliente sobre el que se consulta
     */
    public getScoreClientFree(idclient: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETSCORECLIENT + idclient + '/balance';
        let params: HttpParams = new HttpParams();
        params = params.append('loyaltyUnitId', '8');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los puntos de venta cercanos a la posición del cliente
     * @param idbrand id de la marca a buscar el pppvv
     * @param lat latitud del cliente
     * @param long longitud del cliente
     */
    public getPointsNearClient(idbrand: any, lat: any, long: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETPOINTSNEAR + idbrand;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('lat', lat);
        params = params.append('lng', long);
        params = params.append('availables', 'true');
        params = params.append('allowExchanges', 'true');
        params = params.append('maxppvv', '25');
        return this._http.get(this._apiUrl, { params });
    }
}
