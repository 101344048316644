import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkinTestComponent } from './skintest.component';

@NgModule({
    declarations: [SkinTestComponent],
    imports: [ CommonModule ],
    exports: [SkinTestComponent],
    providers: [],
})
export class SkinTestModule {}