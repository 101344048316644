<div class="container">
    <div class="row" style="text-align: center">
        <div class="col-md-12">
            <img src="../../../assets/images/LOGO-NaosClub@1x.png" />
        </div>
    </div><br>
    <div class="row" style="text-align: center">
        <div class="col-md-12">
            <h5>Puntos de venta excluidos del programa de fidelización de NAOS Club</h5>
        </div>
    </div><br>
    <div class="row" style="text-align: center">
        <div class="col-md-12">
            <ul>
                <!-- <li>www.druni.es (venta online y offline)</li> -->
                <li>www.bonpreuesclat.cat (venta online y offline)</li>
                <!-- <li>https://www.primor.eu (venta online y offline)</li> -->
                <li>www.notino.es  (venta online) </li>
                <li>https://es.boxofcolor.com/es  (venta online) </li>
                <li>https://www.caretobeauty.com/es  (venta online) </li>
                <li>https://www.cocooncenter.es (venta online)</li>
                <li>https://shopcosmetis.com/en (venta online)</li>
                <li>https://www.easyparapharmacie.es (venta online)</li>
                <li>https://www.brasty.es (venta online)</li>
                <li>https://www.carelineage.com/en  (venta online)</li>
                <li>https://www.sanareva.es (venta online)</li>
                <li>https://www.viata.es/farmacia-online (venta online)</li>
                <li>https://www.amazon.es/ (venta online)</li>
            </ul>
        </div>
    </div>
    <div class="row" style="text-align: center">
        <div class="col-md-12">
            <button class="btn btnSendTicket" (click)="this.goToAfter()">
                Volver
            </button>
        </div>
    </div>
</div>
