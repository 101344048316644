<div class="container mw-100">
    <div class="row loader" *ngIf="!this.loader">
        <div class="col-md-12  text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
    <ng-container *ngIf="this.loader">
        <ng-container *ngFor="let item of this.infoDirection;let i = index;">
            <div class="row rowDirection" *ngIf="item.localty !== 'NAOSGENERICLOCALTY'">
                <div class="col-md-12">
                    <div class="row rowFilaDirection">
                        <div class="col-md-6">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="mainAdress" [(ngModel)]="item.mainAddress" [id]="'mainAdressmod'+i">
                                <label class="form-check-label textconditions" for="defaultCheck1">
                                    Dirección Principal
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="!item.deliveryAvailable">
                        <div class="col-12">
                            <p class="noavailable">
                                Esta dirección no admite envío a domicilio.
                            </p>
                        </div>
                    </div>
                    <div class="row rowFilaDirection">
                        <div class="col-md-6">
                            <div class="row form-group">
                                <label for="localidad" class="col-sm-3 col-form-label">Localidad *</label>
                                <div class="col-sm-9 ng-autocomplete inputRegister">
                                    <ng-autocomplete
                                        [(ngModel)]="item.localty"
                                        [data]="item.poblaciones"
                                        [searchKeyword]="'nombre'"
                                        (selected)='this.selectEventLocalityModified($event, i)'
                                        (inputChanged)='this.onChangeSearchLocalityModified($event, i)'
                                        (inputFocused)='this.onFocusedLocalityModified($event)'
                                        [itemTemplate]="itemTemplateLocalityModified"
                                        (closed)="this.closedEventLocalityModified(i)"
                                        [notFoundTemplate]="notFoundTemplateLocalityModified">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateLocalityModified let-item class="listAutocomplete">
                                    <a [innerHTML]="item.nombre"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplateLocalityModified let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row form-group">
                                <label for="localidad" class="col-sm-3 col-form-label">Dirección *</label>
                                <div class="col-sm-9 ng-autocomplete inputRegister">
                                    <ng-autocomplete
                                        [(ngModel)]="item.addressName"
                                        [data]="item.vias"
                                        [searchKeyword]="'nombre'"
                                        (selected)='this.selectEventStreetModified($event, i)'
                                        (inputChanged)='this.onChangeSearchStreetModified($event, i)'
                                        (inputFocused)='this.onFocusedStreetModified($event)'
                                        [itemTemplate]="itemTemplateStreetModified"
                                        (closed)="this.closedEventStreetModified(i)"
                                        [notFoundTemplate]="notFoundTemplateStreetModified">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateStreetModified let-item>
                                    <a [innerHTML]="item.nombre"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplateStreetModified let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row rowFilaDirection">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Num.*</label>
                                        <input type="text" class="inputNumber" [(ngModel)]="item.number" (change)="this.onChangeSearchNumberModified(item.number, i)" [attr.list]="'numbersmodified'+i">
                                        <datalist [id]="'numbersmodified'+i">
                                        <option *ngFor="let item of item.numeros" [value]="item.numero">
                                        </datalist>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="portal">Portal</label>
                                        <input type="text" [(ngModel)]="item.portal" (change)="this.setWhiteSpaceAdditionalAddressModified(i)" class="form-control inputRegister" [id]="'portalmod'+i">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="escalera">Escalera</label>
                                        <input type="text" [(ngModel)]="item.stairs" (change)="this.setWhiteSpaceAdditionalAddressModified(i)" class="form-control inputRegister" [id]="'escaleramod'+i">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="piso">Piso</label>
                                        <input type="text" [(ngModel)]="item.floor" (change)="this.setWhiteSpaceAdditionalAddressModified(i)" class="form-control inputRegister" [id]="'floormod'+i">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="puerta">Puerta</label>
                                        <input type="text" [(ngModel)]="item.door" (change)="this.setWhiteSpaceAdditionalAddressModified(i)" class="form-control inputRegister" [id]="'lettermod'+i">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="cp">Código Postal *</label>
                                <input type="text" [(ngModel)]="item.postalCode" (change)="this.setWhiteSpaceAdditionalAddressModified(i)" class="form-control inputRegister" [id]="'cpmod'+i">
                            </div>
                        </div>
                    </div>
                    <div class="row rowFilaDirection">
                        <div class="col-md-6">
                            <div class="row form-group">
                                <label for="provincia" class="col-sm-3 col-form-label">Provincia *</label>
                                <input type="text" [(ngModel)]="item.province" (change)="this.setWhiteSpaceAdditionalAddressModified(i)" class="col-sm-9 form-control inputRegister" [id]="'provinciamod'+i">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row form-group">
                                <label for="pais" class="col-sm-2 col-form-label">País *</label>
                                <input type="text" [(ngModel)]="item.country" (change)="this.setWhiteSpaceAdditionalAddressModified(i)" class="col-sm-10 form-control inputRegister" [id]="'paismod'+i" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="row rowFilaDirection">
                        <div class="col-md-12">
                            <div class="row form-group">
                                <label for="provincia" class="col-sm-2 col-form-label">Resto dirección</label>
                                <input type="text" [(ngModel)]="item.additionalAddress" class="col-sm-10 form-control inputRegister" [id]="'restodirect'+i">
                            </div>
                        </div>
                    </div>
                    <div class="row rowFilaDirection">
                        <div class="col-md-6">
                            <div class="row form-group">
                                <label for="nombreContacto" class="col-sm-4 col-form-label">Nombre contacto *</label>
                                <input type="text" [(ngModel)]="item.contactName" class="col-sm-8 form-control inputRegister" id="nombreContacto">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row form-group">
                                <label for="surname1" class="col-sm-5 col-form-label">Primer Apellido contacto *</label>
                                <input type="text" [(ngModel)]="item.contactSurname" class="col-sm-7 form-control inputRegister" id="surname1">
                            </div>
                        </div>
                    </div>
                    <div class="row rowFilaDirection">
                        <div class="col-md-6">
                            <div class="row form-group">
                                <label for="surname2" class="col-sm-5 col-form-label">Segundo Apellido contacto</label>
                                <input type="text" [(ngModel)]="item.contactSurname2" class="col-sm-7 form-control inputRegister" id="surname2">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row form-group">
                                <label for="telfcontact" class="col-sm-4 col-form-label">Teléfono contacto</label>
                                <input type="text" [(ngModel)]="item.contactPhone" class="col-sm-8 form-control inputRegister" id="telfcontact">
                            </div>
                        </div>
                    </div>
                    <div class="row rowFilaDirection">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <button class="btn btnEnviar" (click)="this.sendDataUpdateDirection(item.idAddress,i)">
                                        Modificar
                                    </button>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 lineaBorrar">
                                    <ng-container *ngIf="!item.mainAddress && this.infoDirection.length > 1">
                                        <button class="btn btnEnviar" (click)="this.deleteAddress(item.idAddress)">
                                        Eliminar
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="item.mainAddress"></ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="row headingOne">
            <div *ngIf="this.infoDirection.length > 0 && this.infoDirection[0].localty !== 'NAOSGENERICLOCALTY'" class="col-md-12">
                Otra Dirección &nbsp;&nbsp;&nbsp;<a class="btn btnPlusDirection" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <i class="fa fa-plus" aria-hidden="true"></i>
                </a>
            </div>
            <div *ngIf="this.infoDirection.length == 0 || this.infoDirection[0].localty == 'NAOSGENERICLOCALTY'" class="col-md-12">
                Añadir Dirección &nbsp;&nbsp;&nbsp;<a class="btn btnPlusDirection" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <i class="fa fa-plus" aria-hidden="true"></i>
                </a>
            </div>
        </div>

        <div class="collapse" id="collapseExample">
            <div class="row rowFilaDirection">
                <div class="col-md-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="legal" [(ngModel)]="this.mainAddress" id="legal">
                        <label class="form-check-label textconditions" for="defaultCheck1">
                            Dirección Principal
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                </div>
            </div>
            <div class="row rowFilaDirection">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="localidad">Localidad *</label>
                        <div class="ng-autocomplete inputRegister" style="width:100%">
                            <ng-autocomplete #newlocality
                                [data]="this.dataLocalityNew"
                                [searchKeyword]="'nombre'"
                                (selected)='this.selectEventLocalityNew($event)'
                                (inputChanged)='this.onChangeSearchLocalityNew($event)'
                                (inputFocused)='this.onFocusedLocalityNew($event)'
                                [itemTemplate]="itemTemplateLocalityNew"
                                (closed)="this.closedEventLocalityNew()"
                                [notFoundTemplate]="notFoundTemplateLocalityNew">
                            </ng-autocomplete>

                            <ng-template #itemTemplateLocalityNew let-item>
                            <a [innerHTML]="item.nombre"></a>
                            </ng-template>

                            <ng-template #notFoundTemplateLocalityNew let-notFound>
                            <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="address">Dirección *</label>
                        <div class="ng-autocomplete" style="width:100%">
                            <ng-autocomplete #newdirection
                                [data]="this.dataStreetNew"
                                [searchKeyword]="'nombre'"
                                (selected)='this.selectEventStreetNew($event)'
                                (inputChanged)='this.onChangeSearchStreetNew($event)'
                                (inputFocused)='this.onFocusedStreetNew($event)'
                                [itemTemplate]="itemTemplateStreetNew"
                                (closed)="this.closedEventStreetNew()"
                                [notFoundTemplate]="notFoundTemplateStreetNew">
                            </ng-autocomplete>

                            <ng-template #itemTemplateStreetNew let-item>
                            <a [innerHTML]="item.nombre"></a>
                            </ng-template>

                            <ng-template #notFoundTemplateStreetNew let-notFound>
                            <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row rowFilaDirection">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Num.*</label>
                                <input type="text" class="inputNumber" [(ngModel)]="this.numberNewSelected" (change)="this.selectEventNumberNew(this.numberNewSelected)" list="numbersnew" style="width: 100%">
                                <datalist id="numbersnew">
                                <option *ngFor="let item of this.dataNumberNew" [value]="item.numero">
                                </datalist>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="portal">Portal</label>
                                <input type="text" [(ngModel)]="this.portal" (change)="this.setWhiteSpaceAdditionalAddress()" class="form-control inputRegister" [id]="'portalmod'+i" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="escalera">Escalera</label>
                                <input type="text" [(ngModel)]="this.stairs" (change)="this.setWhiteSpaceAdditionalAddress()" class="form-control inputRegister" [id]="'escaleramod'+i" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="floor">Piso</label>
                                <input type="text" [(ngModel)]="this.floor" (change)="this.setWhiteSpaceAdditionalAddress()" class="form-control inputRegister" id="floor" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="letter">Letra</label>
                                <input type="text" [(ngModel)]="this.door" (change)="this.setWhiteSpaceAdditionalAddress()" class="form-control inputRegister" id="letter" autocomplete="off">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cp">Código postal *</label>
                        <input type="text" [(ngModel)]="this.postalCode" (change)="this.setWhiteSpaceAdditionalAddress()" class="form-control inputRegister" id="cp" autocomplete="off">
                    </div>
                </div>
            </div>
            <div class="row rowFilaDirection">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="provincia">Provincia *</label>
                        <input type="text" [(ngModel)]="this.province" (change)="this.setWhiteSpaceAdditionalAddress()" class="form-control inputRegister" id="provincia" autocomplete="off">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="pais">País *</label>
                        <input type="text" [(ngModel)]="this.country" (change)="this.setWhiteSpaceAdditionalAddress()" class="form-control inputRegister" id="pais" autocomplete="off" disabled>
                    </div>
                </div>
            </div>
            <div class="row rowFilaDirection">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="provincia">Resto dirección</label>
                        <input type="text" [(ngModel)]="this.additionalAddress" class="form-control inputRegister" id="additionalAddress" autocomplete="off">
                    </div>
                </div>
            </div>
            <div class="row rowFilaDirection">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="nombreContacto">Nombre contacto *</label>
                        <input type="text" [(ngModel)]="this.contactName" class="form-control inputRegister" id="nombreContacto" autocomplete="off">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="surname1">Primer Apellido contacto *</label>
                        <input type="text" [(ngModel)]="this.contactSurname" class="form-control inputRegister" id="surname1" autocomplete="off">
                    </div>
                </div>
            </div>
            <div class="row rowFilaDirection">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="surname2">Segundo Apellido contacto</label>
                        <input type="text" [(ngModel)]="this.contactSurname2" class="form-control inputRegister" id="surname2" autocomplete="off">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="contact_telf">Teléfono contacto</label>
                        <input type="text" [(ngModel)]="this.contactTelf" class="form-control inputRegister" id="contact_telf" autocomplete="off">
                    </div>
                </div>
            </div>
            <div *ngIf="this.infoDirection.length == 0" class="row rowFilaDirection justify-content-center mt-5" style="text-align: center">
                <div class="col-md-3 col-7 col-sm-4 col-lg-2">
                    <button class="btn btnEnviarForms w-100" (click)="this.sendDataClientDirection()">
                        Enviar
                    </button>
                </div>
            </div>
            <div *ngIf="this.infoDirection.length >= 1 && this.infoDirection[0].addressName !== 'NAOSGENERICSTRRET'" class="row rowFilaDirection justify-content-center mt-5" style="text-align: center">
                <div class="col-md-3 col-7 col-sm-4 col-lg-2">
                    <button class="btn btnEnviarForms w-100" (click)="this.sendDataClientDirection()">
                        Enviar
                    </button>
                </div>
            </div>
            <div *ngIf="this.infoDirection.length === 1 && this.infoDirection[0].addressName === 'NAOSGENERICSTRRET'" class="row rowFilaDirection justify-content-center mt-5" style="text-align: center">
                <div class="col-md-3 col-7 col-sm-4 col-lg-2">
                    <button class="btn btnEnviarForms w-100" (click)="this.sendDataClientDirectionGeneric()">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!-- <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationDataDirection" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>

<!-- Modal -->
<div class="modal fade" id="modalEliminarDireccion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Eliminar dirección</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="this.loaderConfirm">
                    <div class="row loader">
                            <div class="col-md-12">
                                <img src="../../../assets/images/loader.gif" />
                            </div>
                        </div>
            </ng-container>
            <ng-container *ngIf="!this.loaderConfirm">
                ¿Estás seguro de eliminar esta dirección?
            </ng-container>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btnEnviarFormsCerrar" data-bs-dismiss="modal">Cerrar</button>
          <button (click)="this.deleteAddressClient(this.idremoveaddress)" type="button" class="btn btnEnviarForms">Eliminar</button>
        </div>
      </div>
    </div>
  </div>
