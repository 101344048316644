<div class="container mw-90 my-5 w-mobile-100 mw-mobile-100 boardStore">
    <div class="row justify-content-center m-5">
        <div class="mt-4 col-12 col-lg-4 nav-store-option row justify-content-center selected" (click)="this.clickOpt(1)" id="opt1">
            <label class="my-2 cursor-pointer">Catálogo</label>
        </div>
        <div class="mt-4 col-12 col-lg-4 nav-store-option row justify-content-center" (click)="this.clickOpt(2)" id="opt2">
            <label class="my-2 cursor-pointer">Histórico</label>
        </div>
        <div class="mt-4 col-12 col-lg-4 nav-store-option row justify-content-center" (click)="this.clickOpt(3)" id="opt3">
            <a class="enlace-cesta cursor-pointer">Cesta</a>
            <button class="btn btnCesta"><i id="btniconbag" class="fa fa-shopping-bag iconBag" aria-hidden="true"></i></button>
            <span class="badge badge-notify">{{this.bag.length}}</span>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
