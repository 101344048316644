import { Component, OnInit } from '@angular/core';
import { NAOSService } from 'src/app/services/naos.service';
import { Router } from '@angular/router';
import { LandingService } from 'src/app/services/landing.service';
import { JsonPipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-landingpromotions',
  templateUrl: './landingpromotions.component.html',
  styleUrls: ['./landingpromotions.component.css'],
  providers: [NAOSService, LandingService]
})
export class LandingPromotionsComponent implements OnInit {

  public loader: boolean;
  public idpromotion;
  public advserveDataPromotion: any;
  public idclient: any;
  public intervalRefresAdvserver: any;
  public code: any;
  public present: any;
  public imgPromotionHeader: any;

  public textModal: string;
  public textUrl: string;
  public link: boolean;

  constructor(
    private _NAOSService: NAOSService,
    private _router: Router,
    private _landingService: LandingService
  ) {
    this.loader = false;
  }

  ngOnInit(): void {
    this.idclient = this._NAOSService.desencryptData(sessionStorage.getItem('ILCK'));

    if (!localStorage.getItem('landingpromotion')) {
      this.idpromotion = this.getParameterByName('promotion');

      localStorage.setItem('landingpromotion', this.idpromotion);
    }

    this.getAdvserverPrivate();

    this.intervalRefresAdvserver = setInterval(() => {
      this.idpromotion = localStorage.getItem('landingpromotion');
      if (this.idpromotion !== '') {
        this.advserveDataPromotion = this._NAOSService.getLandingPromotion(this.idpromotion, JSON.parse(this._NAOSService.desencryptData(sessionStorage.getItem('ADVP'))));
      } else {
        this.advserveDataPromotion = this._NAOSService.getLandingPromotion(this.imgPromotionHeader.content.externalId, JSON.parse(this._NAOSService.desencryptData(sessionStorage.getItem('ADVP'))));
      }

      if (this.advserveDataPromotion !== undefined && this.advserveDataPromotion !== null && this.advserveDataPromotion !== '') {
        this.loader = true;
        clearInterval(this.intervalRefresAdvserver);
      } else {
        clearInterval(this.intervalRefresAdvserver);
        this.loader = true;
        this.textModal = 'Promoción no encontrada';
        this.textUrl = '/home';
        $('#exampleModalNotificationLanding').modal('show');
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    localStorage.removeItem('landingpromotion');
    clearInterval(this.intervalRefresAdvserver)
  }

  /**
     * Funcion para obtener el contenido de advserver de la parte privada
     */
    public getAdvserverPrivate(): void {
      this._NAOSService.getAdvserverContentPrivate(this.idclient).subscribe(
        response => {
          sessionStorage.setItem('ADVP',this._NAOSService.encryptData(JSON.stringify(response)));
          this.imgPromotionHeader = this._NAOSService.getOfferAdvByNameContent('Landingpromotion', response);
        },
        error => {
            console.error('Error ' + JSON.stringify(error));
        }
      );
  }

  public getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  /**
   * Funcion para volver a la home al hacer click en el logo
   */
  public goToHome(): void {
    this._router.navigate(['/home']);
  }

  /**
   * Funcion para redireccionar al hacer click en el popup
   */
  public redirectPopupNotification(): void {
    if (this.textUrl !== null) {
        this._router.navigate([this.textUrl]);
    }
  }

    /**
     * Funcion para volver atras
     */
    public goToAfter(): void {
      this._NAOSService.returnNavigationPageBackLandingPromotion();
    }

  /**
   * Funcion para enviar el código de la promocion
   */
  public sendCode(): void {
    this.loader = false;
    if (this.code !== null && this.code !== undefined && this.code !== '' && this.code !== '000000') {
      if (this.idpromotion == undefined || this.idpromotion == null || this.idpromotion == '') {
        this.idpromotion = this.advserveDataPromotion.content.externalId;
      }
      this._landingService.sendCode(this.code, this.idclient, this.idpromotion).subscribe(
        response => {
          this.present = response;
          this.loader = true;
          this.textModal = this.present.what.jwhat.product_info.item_ld.es;
          this.textUrl = null;
          this.code = '000000';
          $('#exampleModalNotificationLanding').modal('show');
        },
        error => {
          if (error.error.code == '400.2') {
            console.error('Error ' + JSON.stringify(error));
            this.loader = true;
            this.textModal = 'El código introducido no es válido.';
            this.textUrl = null;
            this.code = '000000';
            $('#exampleModalNotificationLanding').modal('show');
          } else if (error.error.code == '401') {
            console.error('Error ' + JSON.stringify(error));
            this.loader = true;
            this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
            this.textUrl = null;
            this.code = '000000';
            $('#exampleModalNotificationLandingError401').modal('show');
          } else if (error.error.code == '403') {
            console.error('Error ' + JSON.stringify(error));
            this.loader = true;
            this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
            this.textUrl = null;
            this.code = '000000';
            $('#exampleModalNotificationLandingError401').modal('show');
          } else if (error.error.code == '404.3') {
            console.error('Error ' + JSON.stringify(error));
            this.loader = true;
            this.textModal = 'El código introducido no es válido.';
            this.textUrl = null;
            this.code = '000000';
            $('#exampleModalNotificationLanding').modal('show');
          } else if (error.error.code == '406.34') {
            console.error('Error ' + JSON.stringify(error));
            this.loader = true;
            this.textModal = 'El código ya ha sido validado con anterioridad.';
            this.textUrl = null;
            this.code = '000000';
            $('#exampleModalNotificationLanding').modal('show');
          } else if (error.error.code == '406.35') {
            console.error('Error ' + JSON.stringify(error));
            this.loader = true;
            this.textModal = 'El código introducido no es válido.';
            this.textUrl = null;
            this.code = '000000';
            $('#exampleModalNotificationLanding').modal('show');
          } else if (error.error.code == '406.36') {
            console.error('Error ' + JSON.stringify(error));
            this.loader = true;
            this.textUrl = null;
            this.code = '000000';
            $('#exampleModalNotificationLandingError406').modal('show');
          } else if (error.error.code == '406.5') {
            console.error('Error ' + JSON.stringify(error));
            this.loader = true;
            this.textModal = 'El código introducido ha caducado.';
            this.textUrl = null;
            this.code = '000000';
            $('#exampleModalNotificationLandingError4065').modal('show');
          }
        }
      );
    } else {
      this.loader = true;
      this.textModal = 'Introduzca un código';
      this.textUrl = null;
      $('#exampleModalNotificationLanding').modal('show');
    }
  }

}
