import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { NAOSService } from 'src/app/services/naos.service';
import { UserService } from 'src/app/services/user.service';
import { LogService } from '@dagonmetric/ng-log';
declare var $: any;

@Component({
    selector: 'app-catalogo',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.css'],
    providers: [StoreService, NAOSService, UserService, LogService]
})
export class CatalogoComponent implements OnInit {

    public listadoProductos: Array<any>;
    public listadoProductosFormated: Array<any>;
    public bag: Array<any>;
    public loader: boolean;

    public idBioderma: any;
    public idEsthederm: any;

    public textModal: string;
    public textUrl: string;
    public idclient: any;
    public scoreClient: any;
    public scoreBag: any;

    public modalImgAlt: string;
    public modalTitle: string;
    public numberStartsModal: string;
    public modalItem: any;
    public modalItemDetail: string;


    constructor(
        private _router: Router,
        private _storeService: StoreService,
        public _naosService: NAOSService,
        private _userService: UserService,
        private readonly logService: LogService
    ) {
        this.listadoProductos = [];
        this.listadoProductosFormated = [];
        this.bag = [];
        this.loader = false;
        this.modalImgAlt = "";
        this.modalImgAlt = "";
        this.numberStartsModal= "";
        this.modalItem = {};
        this.modalItemDetail = "";
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Catálogo',
            uri: '/home/store'
        });
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        if (localStorage.getItem('BC' + this.idclient)) {
            this.bag = JSON.parse(localStorage.getItem('BC' + this.idclient));
        } else {
            localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
        }
        this.getProductsByIdClient(this.idclient);
    }

    /**
     * Metodo para convertir las estrellas a euros
     * @param stars Estrellas del producto
     * @returns los euros a los que equivalen las estrellas
     */
    public convertStarsToEuros(stars: any): any {
        var euros: any;
        euros = stars / parseInt(sessionStorage.getItem('TAR'));
        return parseInt(euros);
    }

    /**
     * Funcion para obtener las estrellas del cliente
     */
    public getScoreClient(): void {
        this._userService.getScoreClient(this.idclient).subscribe(
            response => {
                this.scoreClient = response[0].available;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Metodo para coger solamente las unidades de fidelización con id 7
     * @param arrayUnit array de unidades de fidelización del producto
     */
    public showLoyaltyUnitPrize(arrayUnit: Array<any>): number {
      let prize: number = 0;
      for (let item of arrayUnit) {
        if (item.loyalty_unit_id == 7) {
          prize = item.loyalty_unit_quantity;
        }
      }
      return prize;
    }

    /**
     * Funcion para obtener las estrellas que llevamos en la bolsa
     */
    public getScoreBagClient(): number {
        let auxCont: number = 0;
        for (let item of this.bag) {
            auxCont = auxCont + parseInt(item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity);
        }
        return auxCont;
    }

    /**
     * Funcion para obtener los productos
     */
    /* public getProducts(): void {
        this._storeService.getProductos().subscribe(
            response => {
                this.listadoProductos = response;
                this.listadoProductosFormated = this.listadoProductos;
                this.getMarcasProductsIds();
                this.getScoreClient();
            },
            () => {
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationCatalogo').modal('show');
            }
        );
        this.loader = true;
    } */

    public getProductsByIdClient(idclient: any): void {
        this._storeService.getProductsByClient(idclient).subscribe(
            response => {
                this.listadoProductos = response;
                this.listadoProductosFormated = this.orderProductsCoral(this.listadoProductos);
                this.getMarcasProductsIds();
                this.getScoreClient();
            },
            () => {
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationCatalogo').modal('show');
            }
        );
        this.loader = true;
    }

    public orderProductsCoral(arrayProductos: Array<any>): Array<any> {
        let arrayProductDigital: Array<any> = [];
        let arrayformat: Array<any> = [];
        for (let x = 0; x < arrayProductos.length; x++) {
            if (arrayProductos[x].json_type_ld == 'JREWARDS_PO') {
                arrayProductDigital.push(arrayProductos[x]);
            }
        }
        for (let item of arrayProductos) {
            if (item.json_type_ld == 'JREWARDS_PO') {
                arrayProductos = this.removeItemFromArr(arrayProductos, item);
            }
        }
        arrayformat = arrayProductDigital.concat(arrayProductos);
        return arrayformat;
    }

    public removeItemFromArr( arr, item ) {
        return arr.filter( function( e ) {
            return e !== item;
        } );
    };

    /**
     * Funcion para obtener los ids de las marcas bioderma y esthederm
     */
    public getMarcasProductsIds(): void {
        this._storeService.getMarcasIds().subscribe(
            response => {
                for (let item of response) {
                    if (item.description.toUpperCase() == 'BIODERMA') {
                        this.idBioderma = item.id;
                    } else if (item.description.toUpperCase() == 'ESTHEDERM') {
                        this.idEsthederm = item.id;
                    }
                }
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Funcion para controlar que los productos que metemos en la bolsa de compra no supera el numero de estrellas del cliente
     */
    public checkScoreProductsClient(item: any): boolean {
        let permiso: boolean = false;
        this.scoreBag = this.getScoreBagClient();
        let auxBag = parseInt(this.scoreBag) + parseInt(item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity);
        if (auxBag <= this.scoreClient) {
            permiso = true;
        } else {
            permiso = false;
        }
        return permiso;
    }

    /**
     * Funcion para añadir producto a la bolsa de compra
     * @param item producto que se añade a la bolsa de compra
     */
    public addProductToBag(item: any): void {
        if (this.bag.length > 0) {
            // Se controla que los productos para hacer un pedido sean de la misma marca y del mismo tipo
            if (this.bag[0].classification[0] == item.classification[0]) {
                if (this.bag[0].jwhat.delivery_type == item.jwhat.delivery_type) {
                // Se controla que el numero de estrellas que tiene el cliente en la bolsa es menor o igual al numero de estrellas que posee.
                if (this.checkScoreProductsClient(item)) {
                    item.destiny = 'catalogo';
                    this.bag.push(item);
                    localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                    this.textModal = 'Producto añadido a la cesta.';
                    this.textUrl = null;
                    $('#exampleModalNotificationCatalogo').modal('show');
                } else {
                    this.textModal = 'Lo sentimos, saldo insuficiente.';
                    this.textUrl = null;
                    $('#exampleModalNotificationCatalogo').modal('show');
                }
            } else {
                this.textModal = 'Lo sentimos, debes hacer dos pedidos independientes.';
                this.textUrl = null;
                $('#exampleModalNotificationCatalogo').modal('show');
            }
            } else {
                this.textModal = 'Lo sentimos, no es posible hacer un pedido con productos de diferentes marcas. Puedes hacer dos pedidos independientes.';
                this.textUrl = null;
                $('#exampleModalNotificationCatalogo').modal('show');
            }
        } else {
            // Se controla que el numero de estrellas que tiene el cliente en la bolsa es menor o igual al numero de estrellas que posee.
            if (this.checkScoreProductsClient(item)) {
                item.destiny = 'catalogo';
                this.bag.push(item);
                localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                this.textModal = 'Producto añadido a la cesta.';
                this.textUrl = null;
                $('#exampleModalNotificationCatalogo').modal('show');
            } else {
                this.textModal = 'Lo sentimos, saldo insuficiente.';
                this.textUrl = null;
                $('#exampleModalNotificationCatalogo').modal('show');
            }
        }
    }

    /**
     * Funcion para añadir producto a la bolsa de compra para el modal
     * @param item producto que se añade a la bolsa de compra
     */
    public addProductToBagModal(item: any): void {
        // close modal
        $('#ModalCatalogoItem').modal('hide');

        // wait to show the others modals
        setTimeout(() => {
            if (this.bag.length > 0) {
                // Se controla que los productos para hacer un pedido sean de la misma marca y del mismo tipo
                if (this.bag[0].classification[0] == item.classification[0]) {
                    if (this.bag[0].jwhat.delivery_type == item.jwhat.delivery_type) {
                    // Se controla que el numero de estrellas que tiene el cliente en la bolsa es menor o igual al numero de estrellas que posee.
                    if (this.checkScoreProductsClient(item)) {
                        item.destiny = 'catalogo';
                        this.bag.push(item);
                        localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                        this.textModal = 'Producto añadido a la cesta.';
                        this.textUrl = null;
                        $('#exampleModalNotificationCatalogo').modal('show');
                    } else {
                        this.textModal = 'Lo sentimos, saldo insuficiente.';
                        this.textUrl = null;
                        $('#exampleModalNotificationCatalogo').modal('show');
                    }
                } else {
                    this.textModal = 'Lo sentimos, debes hacer dos pedidos independientes.';
                    this.textUrl = null;
                    $('#exampleModalNotificationCatalogo').modal('show');
                }
                } else {
                    this.textModal = 'Lo sentimos, no es posible hacer un pedido con productos de diferentes marcas. Puedes hacer dos pedidos independientes.';
                    this.textUrl = null;
                    $('#exampleModalNotificationCatalogo').modal('show');
                }
            } else {
                // Se controla que el numero de estrellas que tiene el cliente en la bolsa es menor o igual al numero de estrellas que posee.
                if (this.checkScoreProductsClient(item)) {
                    item.destiny = 'catalogo';
                    this.bag.push(item);
                    localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                    this.textModal = 'Producto añadido a la cesta.';
                    this.textUrl = null;
                    $('#exampleModalNotificationCatalogo').modal('show');
                } else {
                    this.textModal = 'Lo sentimos, saldo insuficiente.';
                    this.textUrl = null;
                    $('#exampleModalNotificationCatalogo').modal('show');
                }
            }
        }, 400);
    }

    /**
     * Funcion para ir a la vista detalle
     * @param product producto para ver en detalle
     */
    public goToViewDetail(item: any): void {
        this.modalItem = item;
        this.modalTitle = item.jwhat.product_info.item_sd.es;
        this.modalItemDetail = item.jwhat.product_info.item_ld.es;
        this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
        this.textUrl = item.jwhat.product_info.multimedia.mainImage[1].url;
        this.modalImgAlt = item.jwhat.item_sd;
        this.numberStartsModal = this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units).toString();
        $('#ModalCatalogoItem').modal('show');
    }

    /**
     * Funcion para ordenar segun lo seleccionado en el filtro
     * @param evento tipo de orden del filtro
     */
    public orderFilter(evento: any): void {
        this.loader = false;
        if (evento !== 'X') {
            if (evento == 'asc') {
                this.listadoProductosFormated = this.listadoProductosFormated.sort(function (a, b) {
                    if (a.jwhat.payments.loyalty_units[0].loyalty_unit_quantity > b.jwhat.payments.loyalty_units[0].loyalty_unit_quantity) {
                      return 1;
                    }
                    if (a.jwhat.payments.loyalty_units[0].loyalty_unit_quantity < b.jwhat.payments.loyalty_units[0].loyalty_unit_quantity) {
                      return -1;
                    }
                    return 0;
                  });
            } else if (evento == 'desc') {
                this.listadoProductosFormated = this.listadoProductosFormated.sort(function (a, b) {
                    if (a.jwhat.payments.loyalty_units[0].loyalty_unit_quantity < b.jwhat.payments.loyalty_units[0].loyalty_unit_quantity) {
                      return 1;
                    }
                    if (a.jwhat.payments.loyalty_units[0].loyalty_unit_quantity > b.jwhat.payments.loyalty_units[0].loyalty_unit_quantity) {
                      return -1;
                    }
                    return 0;
                  });
            }
        }
        this.loader = true;
    }

    /**
     * Funcion para buscar productos por nombre
     * @param name nombre del producto
     */
    public searchNameProduct(name: string): void {
        this.loader = false;
        let arrAux: Array<any> = [];
        let contAux: number = 0;
        if (name.length > contAux) {
                for (let item of this.listadoProductosFormated) {
                    if (this._naosService.removeAccents(item.jwhat.product_info.item_sd.es).toUpperCase().indexOf(this._naosService.removeAccents(name).toUpperCase()) > -1) {
                        arrAux.push(item);
                    }
                }
                this.listadoProductosFormated = arrAux;
        } else {
            for (let item of this.listadoProductos) {
                if (item.jwhat.product_info.item_sd.es.toUpperCase().indexOf(name.toUpperCase()) > -1) {
                    arrAux.push(item);
                }
            }
            this.listadoProductosFormated = arrAux;
        }
        contAux = name.length;
        this.loader = true;
    }

    /**
     * Funcion para aplicar el filtro según lo seleccionado
     * @param evento tipo de filtro que se va a aplicar
     */
    public filterProduct(evento: any) {
        this.loader = false;
        this.listadoProductosFormated = this.listadoProductos;
        let arrAux: Array<any> = [];
        switch (evento) {
            case 'Todos':
                arrAux = this.listadoProductos;
                break;
            case 'bioderma':
                for (let item of this.listadoProductosFormated) {
                    if (item.classification[0] == this.idBioderma) {
                        arrAux.push(item);
                    }
                }
                break;
            case 'esthederm':
                    for (let item of this.listadoProductosFormated) {
                        if (item.classification[0] == this.idEsthederm) {
                            arrAux.push(item);
                        }
                    }
                break;
            case '0':
                for (let item of this.listadoProductosFormated) {
                    if (parseInt(item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity) < 500) {
                        arrAux.push(item);
                    }
                }
                break;
            case '500':
                    for (let item of this.listadoProductosFormated) {
                        if (parseInt(item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity) >= 500 && parseInt(item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity) <= 1000) {
                            arrAux.push(item);
                        }
                    }
                break;
            case '1000':
                    for (let item of this.listadoProductosFormated) {
                        if (parseInt(item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity) >= 1000) {
                            arrAux.push(item);
                        }
                    }
                break;
        }
        this.listadoProductosFormated = arrAux;
        this.loader = true;
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }
}
