<div *ngIf="this.loader" class="container mt-3 w-mobile-100 mw-mobile-100">
    <div class="row">
        <div class="col text-center mb-5 align-self-center mt-4">
            <img class="h-auto cursor-pointer main-logo" (click)="this.goToHome()" [src]="this.urlLogoHome" />
        </div>
    </div>
</div>

<div *ngIf="this.loader" class="container-fluid mw-100 mt-3 w-mobile-100 mw-mobile-100">
    
    <!-- Estrellas para escritorio y tablet -->
    <div class="row hidden-mobile">
      <div class="col-lg-4 col-md-5 col-xs-4 col-sm-4">
        <div class="container-menu">
            <div class="element">
                <div class="icon-menu cursor-pointer" [class.collapse]="collapsed" id="navbarContent" style="padding-left: 2%"></div>
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item" ngbDropdown>
                            <span class="nav-profile-option" ngbDropdownToggle id="navbarDropdown">
                                <i class="fas fa-bars fa-2x icon-bars"></i>
                            </span>
  
                            <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu mt-4">
                                <!-- <a class="nav-links-menu mt-2" ngbDropdownItem href="/home">HOME</a> -->
                                <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/profilepage">MI PERFIL</a>
                                <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/clubperks">VENTAJAS DEL CLUB</a>
                                <!-- <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/clubperks">CÓMO CONSEGUIR ESTRELLAS</a> -->
                                <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/store/catalogo">CANJEA TUS ESTRELLAS</a>
                                <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/transactions">SUBE TU TICKET</a>
                                <!-- <a class="nav-links-menu mt-2" ngbDropdownItem (click)="this.goToPromotions()">VALIDA TU CÓDIGO</a> -->
                                <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/transactions">MIS TICKETS/MIS COMRAS</a>
                                <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/myskin">MI RUTINA PERSONALIZADA</a>
                                <a class="nav-links-menu mt-2" ngbDropdownItem href="/mapappvv">PUNTOS DE VENTA</a>
                                <a class="nav-links-menu mt-2 mb-1" (click)="this.goToFaqs()" ngbDropdownItem href="/home/contact">PREGUNTAS FRECUENTES</a>
                                <a class="nav-links-menu mt-2 hidden-desktop" ngbDropdownItem (click)="this.closeSession()" href="/main-naos">CERRAR SESIÓN</a>
                            </div>
                        </li>
                    </ul>
                    
                </div>
                
                
            
            <div class="element"><i class="fa fa-user-o fa-2x icon-user cursor-pointer" style="padding-left: 2%" (click)="this.goToProfile()"></i></div>
            <div class="element" style="width: 100%;"><label class="name-title text-user cursor-pointer" (click)="this.goToProfile()"> Hola {{this.infoClient?.firstname}} <a class="hidden-mobile" data-toggle="tooltip" data-placement="top" title="Cerrar sesión" (click)="this.closeSession()"><i class="fa fa-power-off" aria-hidden="true"></i></a></label></div>
        </div>
      </div>

      <div class="col-lg-6 col-md-7 col-xs-8 col-sm-8">
        <div class="row w-100 lineStarExpired" style="margin-right: 0px !important" *ngIf="this.arrayScoreExpired.length > 0">
          <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 w-mobile-100">
              <label class="text-stars cursor-pointer w-100" (click)="this.goToHistory()">
                  <img src="../../../assets/images/Group@2x.png" class="starScore" />
                  <span class="text-start-name">Tus estrellas {{this.scoreSclient | number:'1.0-3':'de'}} ({{this.scoreSclientEuro}} €)</span>
          </label>
          </div>
          <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 w-mobile-100 expiredStars">
              <label class="text-stars cursor-pointer w-100">
                  <img src="../../../assets/images/estrellagris.png" class="starScore" />
                  <i *ngIf="this.arrayScoreExpired.length > 0" [placement]="['right', 'bottom', 'auto']" [ngbPopover]="popContent" class="fas fa-info-circle iconInfo"></i>
                  <span class="text-start-name-silver" (click)="this.goToHistory()">Caducan pronto {{this.scoreClientCaducidad | number:'1.0-3':'de'}} ({{this.scoreClientCaducidadEuro}} €)</span>
              </label>
          </div>
        </div>

        <div class="row w-100" *ngIf="this.arrayScoreExpired.length == 0">
          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 w-mobile-100">
              <label class="text-stars cursor-pointer w-100" (click)="this.goToHistory()">
                  <img src="../../../assets/images/Group@2x.png" class="starScore" />
                  <span class="text-start-name">Tus estrellas {{this.scoreSclient | number:'1.0-3':'de'}} ({{this.scoreSclientEuro}} €)</span>
          </label>
          </div>
        </div>
      </div>

      <ng-template #popContent>
        <ul class="ullistexpired">
          <li *ngFor="let item of this.arrayScoreExpired">El dia {{item.date | date: 'dd/MM/yyyy'}} caducan {{item.quantity | number:'1.0-3':'de'}} estrellas.</li>
        </ul>
        <div class="p-2" *ngIf="this.showMessageScoreExpired" style="color:silver">Para más información ponte en contacto con nosotros en infonaosclub&#64;es.naos.com.</div>
      </ng-template>

    </div>
    <!-- Estrellas para mobile -->
    <div class="row justify-content-between hidden-desktop hidden-tablet pl-2">
        <div class="col-xs-6">
            <div class="d-flex">
            <div class="pt-3 icon-menu cursor-pointer" [class.collapse]="collapsed" id="navbarContent">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item" ngbDropdown>
                        <span class="nav-profile-option" ngbDropdownToggle id="navbarDropdown">
                            <i class="fas fa-bars fa-2x icon-bars"></i>
                        </span>

                        <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu mt-4">
                            <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/profilepage">MI PERFIL</a>
                            <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/clubperks">VENTAJAS DEL CLUB</a>
                            <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/store/catalogo">CANJEA TUS ESTRELLAS</a>
                            <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/transactions">SUBE TU TICKET</a>
                            <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/transactions">MIS TICKETS/MIS COMPRAS</a>
                            <a class="nav-links-menu mt-2" ngbDropdownItem href="/home/myskin">MI RUTINA PERSONALIZADA</a>
                            <a class="nav-links-menu mt-2" ngbDropdownItem href="/mapappvv">PUNTOS DE VENTA</a>
                            <a class="nav-links-menu mt-2 mb-1" (click)="this.goToFaqs()" ngbDropdownItem href="/home/contact">PREGUNTAS FRECUENTES</a>
                            <a class="nav-links-menu mt-2 hidden-desktop" ngbDropdownItem (click)="this.closeSession()" href="/main-naos">CERRAR SESIÓN</a>
                        </div>
                    </li>
                </ul>
            </div>
            <i class="fa fa-user-o fa-2x icon-user pt-3 px-2 cursor-pointer" (click)="this.goToProfile()"></i>
            <label class="name-title text-user p-2 cursor-pointer" (click)="this.goToProfile()"> Hola {{this.infoClient?.firstname}} <a class="hidden-mobile ml-3" data-toggle="tooltip" data-placement="top" title="Cerrar sesión" (click)="this.closeSession()"><i class="fa fa-power-off" aria-hidden="true"></i></a></label>
            </div>
        </div>
        <div class="col-xs-6">
            <label class="text-stars p-2 ml-auto d-flex cursor-pointer" (click)="this.goToHistory()" *ngIf="this.arrayScoreExpired.length == 0">
                <img src="../../../assets/images/Group@2x.png" class="starScore p-2" />
                <span class="text-start-name">Tus estrellas</span>
                <div class="text-start-number">{{this.scoreSclient | number:'1.0-3':'de'}}</div>
                <div class="text-start-number cursor-pointer" (click)="this.goToHistory()">({{this.scoreSclientEuro}} €)</div>
            </label>
        </div>
    </div>
    <!-- <div class="row hidden-desktop hidden-tablet" style="float: right; margin-top: -15px;" *ngIf="this.arrayScoreExpired.length == 0">
        <div class="col-12" style="padding-right: 6px !important;">
            <div class="text-start-number2 cursor-pointer" (click)="this.goToHistory()">({{this.scoreSclientEuro}} €)</div>
        </div>
    </div> -->

    <!-- <div class="row mt-2 hidden-desktop hidden-tablet" *ngIf="this.arrayScoreExpired.length > 0">
        <div class="col-12" style="padding-left: 46%;padding-right: 0px !important;">
            <label class="text-stars2 cursor-pointer w-100" (click)="this.goToHistory()">
                <img src="../../../assets/images/estrellagris.png" class="starScoreSilver"/>
                <i [placement]="['right', 'bottom', 'auto']" [ngbPopover]="popContent" class="fas fa-info-circle iconInfo"></i>
                <span class="text-start-name-silver" >Caducan pronto {{this.scoreClientCaducidad | number:'1.0-3':'de'}}</span>
                <ng-template #popContent>
                    <ul class="ullistexpired">
                      <li *ngFor="let item of this.arrayScoreExpired">El dia {{item.date | date: 'dd/MM/yyyy'}} caducan {{item.quantity | number:'1.0-3':'de'}} estrellas.</li>
                    </ul>
                    <div class="p-2" *ngIf="this.showMessageScoreExpired" style="color:silver">Para más información ponte en contacto con nosotros en infonaosclub@es.naos.com.</div>
                  </ng-template>
            </label>
        </div>
    </div>
    <div class="row hidden-desktop hidden-tablet" style="float: right; margin-top: -15px;">
        <div class="col-12" style="padding-right: 6px !important;">
            <div class="text-start-number2 cursor-pointer" style="color: silver;" (click)="this.goToHistory()">({{this.scoreClientCaducidadEuro}} €)</div>
        </div>
    </div> -->
</div>

<div class="container hidden-desktop hidden-tablet w-100" *ngIf="this.arrayScoreExpired.length > 0 && this.loader" style="float: right;">
    <div class="row" style="margin: 0px !important;" (click)="this.goToHistory()">
        <div class="col-12" style="padding-left: 0px !important;margin-left: -5px;">
            <div>
                <div class="text-start-number">
                    <img src="../../../assets/images/Group@2x.png" class="starScore p-2" />
                    Tus estrellas {{this.scoreSclient | number:'1.0-3':'de'}} ({{this.scoreSclientEuro}} €)
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin: 0px !important;" (click)="this.goToHistory()">
        <div class="col-12" style="padding-left: 0px !important;">
            <div style="display: block;">
                <img src="../../../assets/images/estrellagris.png" class="starScoreSilver"/>
                <i [placement]="['right', 'bottom', 'auto']" [ngbPopover]="popContent" class="fas fa-info-circle iconInfo"></i>
                <span class="text-start-name-silver">Caducan pronto {{this.scoreClientCaducidad | number:'1.0-3':'de'}} ({{this.scoreClientCaducidadEuro}} €)</span>
                <ng-template #popContent>
                    <ul class="ullistexpired">
                      <li *ngFor="let item of this.arrayScoreExpired">El dia {{item.date | date: 'dd/MM/yyyy'}} caducan {{item.quantity | number:'1.0-3':'de'}} estrellas.</li>
                    </ul>
                    <div class="p-2" *ngIf="this.showMessageScoreExpired" style="color:silver">Para más información ponte en contacto con nosotros en infonaosclub&#64;es.naos.com.</div>
                  </ng-template>
            </div>
        </div>
    </div>
</div>

<div *ngIf="this.loader" class="container mw-80 mt-3 w-mobile-100 mw-mobile-100 hidden-mobile">
    <div class="row" style="padding-right: 15%;padding-left: 15%">
        <div class="col-12 text-center align-self-center">
            <div class="row justify-content-center border-bottom mb-4 grey lighten-4 py-4">
                <div class="col-sm-10 col-md-3 col-lg-3 nav-option p-2 border-right" (click)="this.clickOpt(1)" id="optmenu1">
                    <label class="mb-0 mt-2 cursor-pointer">VENTAJAS DEL CLUB</label>
                </div>
                <div class="col-sm-10 col-md-3 col-lg-3 nav-option p-2 border-right" (click)="this.clickOpt(2)" id="optmenu2">
                    <label class="mb-0 mt-2 cursor-pointer">SUBE TU TICKET</label>
                </div>
                <div class="col-sm-10 col-md-3 col-lg-3 nav-option p-2" (click)="this.clickOpt(3)" id="optmenu3">
                    <label class="mb-0 mt-2 cursor-pointer">VALIDA TU CÓDIGO</label>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="this.loader" class="container mw-80 mt-3 w-mobile-100 mw-mobile-100 hidden-desktop hidden-tablet">
    <div class="row" style="padding-right: 15%;padding-left: 15%">
        <div class="col-12 text-center align-self-center">
            <div class="row justify-content-center border-bottom mb-4 grey lighten-4 py-4">
                <div class="col-sm-10 col-md-3 col-lg-3 nav-option p-2" (click)="this.clickOpt(1)" id="optmenu1">
                    <label class="mb-0 mt-2 cursor-pointer">VENTAJAS DEL CLUB</label>
                </div>
                <div class="col-sm-10 col-md-3 col-lg-3 nav-option p-2" (click)="this.clickOpt(2)" id="optmenu2">
                    <label class="mb-0 mt-2 cursor-pointer">SUBE TU TICKET</label>
                </div>
                <div class="col-sm-10 col-md-3 col-lg-3 nav-option p-2" (click)="this.clickOpt(3)" id="optmenu3">
                    <label class="mb-0 mt-2 cursor-pointer">VALIDA TU CÓDIGO</label>
                </div>
            </div>
        </div>
    </div>
</div>
