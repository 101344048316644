import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogoComponent } from './catalogo.component';
import { FormsModule } from '@angular/forms';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

@NgModule({
    declarations: [CatalogoComponent],
    imports: [
        CommonModule,
        FormsModule,
        NaosNotificationModule
    ],
    exports: [CatalogoComponent],
    providers: [],
})
export class CatalogoModule {}