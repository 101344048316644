import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';
declare var $: any;


@Component({
    selector: 'app-unsubscribe',
    templateUrl: './unsubscribe.component.html',
    styleUrls: ['./unsubscribe.component.css'],
    providers: [NAOSService]
})
export class UnsubscribeComponent implements OnInit {

    private parameter1: any;
    public textModal: string;

    constructor(
        private route: ActivatedRoute,
        private _naosService: NAOSService,
        private _router: Router
    ) {}

    ngOnInit(): void {
        // Recogemos los parametros de la url (de momento no sabemos nombres, solo que son 2)
        this.route.queryParams
        .subscribe(params => {
            console.log(params);
            this.parameter1 = params.data;
        }
        );
    }

    /**
     * Metodo para redireccionar a la home
     */
    public goToHome(): void {
        this._router.navigate(['/main-naos']);
    }

    /**
     * Metodo para enviar los datos de la baja comercial
     */
    public sendUnsubscribe(): void {
        if (this.parameter1 !== undefined) {
            console.log('JSON ' + JSON.stringify(this.parameter1))
            this._naosService.sendUnsubscribeComers(this.parameter1).subscribe(
                response => {
                    console.log(JSON.stringify(response));
                    this.textModal = 'Hemos recibido correctamente tu solicitud de baja de las comunicaciones comerciales de NAOS CLUB y la estamos tramitando para que dejes de recibir nuestras newsletters cuanto antes. Aún así, este proceso no es automático y puede que te llegue todavía alguna comunicación. Si es así, por favor, no la tengas en cuenta e ignórala. Recuerda que si quieres volver a recibir comunicaciones sobre las ventajas que te ofrece NAOS Club por vías electrónicas, podrás darte de alta otra vez dentro del apartado MI PERFIL de NAOS Club. ¡Hasta pronto!';
                    $('#exampleModalNotificationLeave').modal('show');
                },
                error => {
                    console.error('Error ' + JSON.stringify(error));
                    this.textModal = 'Tu baja no se ha podido procesar, inténtalo mas tarde.';
                    $('#exampleModalNotificationLeave').modal('show');
                    
                }
            );
        } else {
            this.textModal = 'Tu baja no se ha podido procesar, inténtalo mas tarde.';
            $('#exampleModalNotificationLeave').modal('show');
        }
    }
}