import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NAOSService } from 'src/app/services/naos.service';
declare var $: any;

@Component({
    selector: 'app-naos-selectsites',
    templateUrl: './selectsites.component.html',
    styleUrls: ['./selectsites.component.css'],
    providers: [NAOSService]
})
export class SelectSitesComponent implements OnInit {

    @Input() type: string;
    @Output() selectedSite: EventEmitter<any> = new EventEmitter();
    public sites: Array<any>;
    public sitesPharmacy: Array<any>;
    public sitesEsthetic: Array<any>;
    public loadersites: boolean;
    public pageSites: any;
    public showDrop: boolean;
    public stringName: string;

    constructor(
        private _NAOSService: NAOSService
    ) {
        this.sites = [];
        this.sitesPharmacy = [];
        this.sitesEsthetic = [];
        this.loadersites = false;
        this.pageSites = 0;
        this.showDrop = false;
    }

    ngOnInit(): void {
        if (this.type == 'pharmacy') {
            this.getSitesPharmacy(0);
        } else if (this.type == 'esthetic') {
            this.getSitesEsthetic(0);
        } else {
            this.getSites(0);
        }

    }

    public getSites(page: any): void {
        this.sites = [];
        this.pageSites = page;
        this._NAOSService.getShopSite(this.pageSites).subscribe(
            response => {
                    this.sites = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
    }

    public getSitesPharmacy(page: any): void {
        this.sitesPharmacy = [];
        this.pageSites = page;
        this._NAOSService.getShopSiteProfile(this.pageSites, 1).subscribe(
            response => {
                    this.sitesPharmacy = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
    }

    public getSitesEsthetic(page: any): void {
        this.sitesEsthetic = [];
        this.pageSites = page;
        this._NAOSService.getShopSiteProfile(this.pageSites, 2).subscribe(
            response => {
                    this.sitesEsthetic = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
    }

    public formatLabelSelectCenters(arraySites: any): any {
        let auxArray: Array<any> = [];
        for (let item of arraySites) {
            if (item.jwhereAddress) {
                item.fullName = item.jwhere.where_ld.toUpperCase() + ' (' + item.where_cd + ' - ' + item.jwhereAddress.postal_cd + ')';
            } else {
                item.fullName = item.jwhere.where_ld.toUpperCase() + ' (' + item.where_cd + ')';
            }
            auxArray.push(item);
        }
        return auxArray;
    }

    public setPagePlus(): void {
        this.pageSites++;
        this._NAOSService.getShopSite(this.pageSites).subscribe(
            response => {
                this.sites = this.formatLabelSelectCenters(response.data);
                this.loadersites = false;
            },
            () => {
                this.loadersites = false;
                console.error('Error al obtener los puntos de venta.');
            }
        );
    }

    public setPagePlusPharmacy(): void {
        this.pageSites++;
        this._NAOSService.getShopSiteProfile(this.pageSites, 1).subscribe(
            response => {
                this.sitesPharmacy = this.formatLabelSelectCenters(response.data);
                this.loadersites = false;
            },
            () => {
                this.loadersites = false;
                console.error('Error al obtener los puntos de venta.');
            }
        );
    }

    public setPagePlusPEsthetic(): void {
        this.pageSites++;
        this._NAOSService.getShopSiteProfile(this.pageSites, 2).subscribe(
            response => {
                this.sitesEsthetic = this.formatLabelSelectCenters(response.data);
                this.loadersites = false;
            },
            () => {
                this.loadersites = false;
                console.error('Error al obtener los puntos de venta.');
            }
        );
    }

    public setPageMinus(): void {
        if ( this.pageSites > 0) {
            this.pageSites--;
            this._NAOSService.getShopSite(this.pageSites).subscribe(
                response => {
                    this.sites = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
        } else {
            this._NAOSService.getShopSite(0).subscribe(
                response => {
                    this.sites = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
        }
    }

    public setPageMinusPharmacy(): void {
        if ( this.pageSites > 0) {
            this.pageSites--;
            this._NAOSService.getShopSiteProfile(this.pageSites, 1).subscribe(
                response => {
                    this.sitesPharmacy = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
        } else {
            this._NAOSService.getShopSiteProfile(0, 1).subscribe(
                response => {
                    this.sitesPharmacy = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
        }
    }

    public setPageMinusEsthetic(): void {
        if ( this.pageSites > 0) {
            this.pageSites--;
            this._NAOSService.getShopSiteProfile(this.pageSites, 2).subscribe(
                response => {
                    this.sitesEsthetic = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
        } else {
            this._NAOSService.getShopSiteProfile(0, 1).subscribe(
                response => {
                    this.sitesEsthetic = this.formatLabelSelectCenters(response.data);
                    this.loadersites = false;
                },
                () => {
                    this.loadersites = false;
                    console.error('Error al obtener los puntos de venta.');
                }
            );
        }
    }

    public setSelectedSite(site: any, name: string): void {
        this.stringName = name;
        this.selectedSite.emit(site);
        this.showDrop = false;
    }

    public showHideDropdown(): void {
        if (this.showDrop) {
            this.showDrop = false;
        } else {
            this.showDrop = true;
        }
    }
}
