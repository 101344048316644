import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
declare var $: any;

@Component({
    selector: 'app-arearestringed',
    templateUrl: './arearestringed.component.html',
    styleUrls: ['./arearestringed.component.css']
})
export class AreaRestringedComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {

    }

    public abrirModalRegister(): void {
        /* $('#modalRegisterRed').modal('show'); */
    }
}
