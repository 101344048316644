// PREPRODUCCION - BETA
export const environment = {
    production: true,
    VALIDATIONEMAIL: false,
    urlAPI: 'https://pre.api.loyaltysp.es/',
    API_KEY: 'b7ae6949-971b-4126-ae69-49971bb12620',
    REGISTERRED: false,
    URLAWSBANNERS: 'https://lsp-images.s3.amazonaws.com/f3da8c048aa2e1c3ddc2da150cec8768b960a0d7/pre/banners/',
    URLAWSBANNERSMOVIL: 'https://lsp-images.s3.amazonaws.com/f3da8c048aa2e1c3ddc2da150cec8768b960a0d7/pre/banners/movil/',
    /* APIKEYGEOCODINGBIODERMA: 'AIzaSyCC1yI635TNNhvpNp-vKpoUR7WjXoNdUlM', */
    /* APIKEYGEOCODINGBIODERMA: 'AIzaSyCETzxLCCqxtITw-3WlVVONtGZbNamZjgY',
    APIKEYVANADIS: 'AIzaSyAlXxn-L_T5W9RHH0c9uUjQxUSy8GerGzQ',
    APIKEYGEOCODINGESTHEDERM: 'AIzaSyAFWZQq3MdjpnhmISkQX0BtVspC6fT5mL8', */

    
    APIKEYGEOCODINGBIODERMA: 'AIzaSyCETzxLCCqxtITw-3WlVVONtGZbNamZjgY',
    APIKEYMAPS : 'AIzaSyCETzxLCCqxtITw-3WlVVONtGZbNamZjgY',
    APIKEYGEOCODINGESTHEDERM: 'AIzaSyD633Bo6Ff74kwpK3L1fOhPVHM4os2dkb4',
    /* APIKEYGEOCODINGBIODERMA:'AIzaSyATI7p2pLbGtSVlUyC-6IjJ5kL1KitfwNM',
    APIKEYMAPS:'AIzaSyATI7p2pLbGtSVlUyC-6IjJ5kL1KitfwNM', */

    MEDIKTORAPI: 'https://int.mediktor.com/backoffice/',
    MEDIKTORAPITEST: 'https://bioderma.int.mediktor.com',
    MEDIKTORVERTEST: 'https://bioderma.int.mediktor.com/cmd/sessionResume',
    ENTORNOGTM: 'https://uxcare-es.pp.bioderma.com/naosclub',
    ENTORNOCOOKIE: 'naosclub.preprod.bioderma.es',
    ENTORNOAPIKEY: 'https://naosclub.preprod.bioderma.es',
    ENTORNOPROJECT: 'pre',
    KEYCRIPT: 's34qL7npUNQfgfq9MZ2PJJYU$qT&s^JY'
  };
