import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListExclusionComponent } from './listexclusion.component';

@NgModule({
    declarations: [ListExclusionComponent],
    imports: [ CommonModule ],
    exports: [ListExclusionComponent],
    providers: [],
})
export class ListExclusionModule {}
