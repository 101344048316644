import { Component, OnInit } from '@angular/core';
import { NAOSService } from 'src/app/services/naos.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AppSettings } from './../../constants.component';
import { DirectionService } from 'src/app/services/direction.service';
import { LoginService } from 'src/app/services/login.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { LogService } from '@dagonmetric/ng-log';

declare var $: any;
declare var require: any
var unorm = require('unorm');

@Component({
    selector: 'app-dataclient-naos',
    templateUrl: './dataclient.component.html',
    styleUrls: ['./dataclient.component.css'],
    providers: [ NAOSService, UserService, DirectionService, LoginService, LogService]
})
export class DataClientComponent implements OnInit {

    public contErrors: any;
    public idfarmacia: any;
    public idestetica: any;
    public idcodesite: any;
    public intervalRefreshData: any;
    public textModal: string;
    public nombreEnroladorCompleto: string;
    public pageSite: number;

    public idclient: any;
    public scoreClient: string;
    public infoClient: any;
    public infoDirection: any;

    public puntosVenta: Array<any>;
    public puntosVentaFarmacias: Array<any>;
    public puntosVentaEsteticas: Array<any>;
    public ppvvFarmacia: any;
    public ppvvEstetica: any;
    public ppvv: string;
    public enroladores: Array<any>;
    public enroladores2: Array<any>;
    public enrolador: string;

    // Datos direccion
    public postalCode: string = '';
    public number: string = '';
    public listNumbers: Array<any>;
    public municipalityIne: string = '';
    public provinceIne: string = '';
    public contactName: string = '';
    public addressType: string = '';
    public locality: string = '';
    public localityCode: string = '';
    public listLocality: Array<any>;
    public alias: string = '';
    public mainAddress: boolean;
    public contactSurname2: string = '';
    public stairs: string = '';
    public contactSurname: string = '';
    public door: string = '';
    public portal: string = '';
    public floor: string = '';
    public country: string = '';
    public addressName: string = '';
    public listAddress: Array<any>;
    public directionValue: string = '';
    public province: string = '';

    public updateUser: boolean;
    public loader: boolean;

    public idpharmacy: string;
    public idsteticcenter: string;
    public pharmacyString: string;
    public steticCenterString: string;

    public disabledCommentBajaValue: boolean;
    public commentBaja: string;
    public valueCheckBaja1: any;
    public valueCheckBaja2: any;
    public valueCheckBaja3: any;
    public valueCheckBaja4: any;

    public loaderFarmcia: boolean;
    public loaderEstetica: boolean;
    public listReason: Array<any>;
    public textModalProfile: string;

    public loaderSelectPPVV: boolean;
    public textUrl: string;

    public nombreFarmacia: string;
    public nombreEstetica: string;

    public valueProvinceImportantPharmacy: string;
    public valueProvinceImportantEstetica: string;
    public listProvinces: Array<any>;
    public listadoSitiosPharmacy: Array<any>;
    public listadoSitiosEstetica: Array<any>;
    public stringNamePharmacy: string;
    public stringNameEstetica: string;
    public showPharmacyInput: boolean;
    public showEsteticaInput: boolean;

    public arrType1: Array<any> = [];
    public arrType3: Array<any> = [];
    public arrType4: Array<any> = [];
    public arrType5: Array<any> = [];
    public arrTotal: Array<any> = [];

    public friendsCodeComplete: boolean;
    public infoAdvServePrivateMGM: any;
    public intervalRefresAdvserver: any;
    public intervalRefreshDataClient: any;

    public puntoventa1: any;
    public puntoventa2: any;
    public enrolador_code: boolean;
    public numson: number;
    public arraySonsDate: Array<any>;

    public minDateInput: any;
    public maxDateInput: any;
    public currentDateInput: any;

    constructor(
        private _NAOSService: NAOSService,
        private _userService: UserService,
        private _router: Router,

        private _loginService: LoginService,
        private config: NgSelectConfig,
        private readonly logService: LogService
    ) {
        this.loader = false;
        this.puntosVenta = [];
        this.puntoventa1 = '';
        this.puntoventa2 = '';
        this.enrolador = '';
        this.enroladores = [];
        this.enroladores2 = [];
        this.contErrors = 0;
        this.enrolador_code = false;
        this.numson = 0;
        this.arraySonsDate = [];
        this.pageSite = 0;
        this.listLocality = [];
        this.listNumbers = [];
        this.listAddress = [];
        this.mainAddress = false;
        this.updateUser = false;
        this.loaderFarmcia = false;
        this.loaderEstetica = false;
        this.config.notFoundText = 'Elemento no encontrado';
        this.puntosVentaFarmacias = [];
        this.puntosVentaEsteticas = [];
        this.disabledCommentBajaValue = false;
        this.commentBaja = '';
        this.listReason = [];
        this.loaderSelectPPVV = false;
        this.textUrl = null;
        this.textModalProfile = undefined;
        this.listProvinces = this.orderProvinces(AppSettings.provincias.data.provinces);
        this.listadoSitiosEstetica = [];
        this.listadoSitiosPharmacy = [];
        this.showPharmacyInput = false;
        this.showEsteticaInput = false;
        this.friendsCodeComplete = false;

        this.minDateInput = this.getDateMaxYears();
        this.maxDateInput = this.getDate18Years();
        this.currentDateInput = this.getDateCurrent();
    }

    ngOnInit(): void {
      this.infoAdvServePrivateMGM = this._NAOSService.getOfferAdvByName('Mi Perfil- MGM',JSON.parse(this._NAOSService.desencryptData(sessionStorage.getItem('ADVP'))));

        this.logService.trackPageView({
            name: 'Mi perfil',
            uri: '/home/profilepage'
        });
        this.initSubMenu();
        this.idclient = this._NAOSService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getAdvserverPrivate();
        this.getDataClient();
        this.getReason();
        let intervaloError = setInterval(() => {
            if (this.contErrors >= 4) {
                this.loader = true;
                this.initButtonsSex();
                clearInterval(intervaloError);
            }
        }, 1000);

        this.intervalRefreshDataClient = setInterval(() => {
            if (localStorage.getItem('refreshDataClient')) {
                this.getDataClient();
                localStorage.removeItem('refreshDataClient');
                clearInterval(this.intervalRefreshDataClient);
            }
        }, 2000);
    }

    ngOnDestroy(): void {
        clearInterval(this.intervalRefresAdvserver);
        clearInterval(this.intervalRefreshDataClient);
    }

    public getDateMaxYears(): any {
        var today = new Date();
        var anno = today.getFullYear() - 120;
        var fechaMin = anno + '-01-01'
        return fechaMin
    }

    public getDate18Years(): any {
        var today = new Date();
        var anno = today.getFullYear() - 18;
        var fechaMin = anno + '-' + today.getMonth() + 1 + '-' + today.getDate();
        return fechaMin
    }

    public getDateCurrent(): any {
        var today = new Date();
        return today.getFullYear() + '-' + today.getMonth() + 1 + '-' + today.getDate();
    }

    public getAdvserverPrivate(): void {
      this._NAOSService.getAdvserverContentPrivate(this.idclient).subscribe(
        response => {
          sessionStorage.setItem('ADVP',this._NAOSService.encryptData(JSON.stringify(response)));
          this.infoAdvServePrivateMGM = this._NAOSService.getOfferAdvByName('Mi Perfil- MGM',JSON.parse(this._NAOSService.desencryptData(sessionStorage.getItem('ADVP'))));
          this.contErrors++;
        },
        error => {
            console.error('Error ' + JSON.stringify(error));
            this.contErrors++;
        }
      );
    }

    /**
     * Funcion para ordenar las provincias
     * @param arrayProvincias array de provincias
     */
    public orderProvinces(arrayProvincias: Array<any>): Array<any> {
        let arrayOrder: Array<any> = [];
        arrayOrder = arrayProvincias.sort(function (a, b) {
            if (unorm.nfc(a.dcprovincia).replace(/[\u0300-\u036f]/g, "") > unorm.nfc(b.dcprovincia).replace(/[\u0300-\u036f]/g, "")) {
              return 1;
            }
            if (unorm.nfc(a.dcprovincia).replace(/[\u0300-\u036f]/g, "") < unorm.nfc(b.dcprovincia).replace(/[\u0300-\u036f]/g, "")) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        return arrayOrder;
    }

    public goToHome(): void {
        this._router.navigate(['/home']);
    }

    public closedModalConfirm(): void {
        if (this.infoClient.whereid_1 == '') {
            $('#confirmaFarmaciaModal').modal('hide');
            this.infoClient.whereid_1 = '';
            this.showPharmacyInput = false;
            this.valueProvinceImportantPharmacy = '';
        }
        if (this.infoClient.whereid_2 == '') {
            $('#confirmaEsteticaModal').modal('hide');
            this.infoClient.whereid_2 = '';
            this.showEsteticaInput = false;
            this.valueProvinceImportantEstetica = '';
        }
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                this.getEnroladorExist(this.infoClient.whereid_1, this.infoClient.whereid_2);
                localStorage.setItem('refreshname', '1');
                this.initButtonsSex();
                this.formatStringCentersByIdPharmacy(this.infoClient.whereid_1);
                this.formatStringCentersByIdEsthetic(this.infoClient.whereid_2);

                if (this.infoClient.coms == 'false') {
                    this.infoClient.coms = false;
                } else {
                    this.infoClient.coms = true;
                }
                if (this.infoClient.whereid_1 !== '') {
                    this.idpharmacy = this.infoClient.whereid_1;
                    this.ppvvFarmacia = this.idpharmacy;
                } else if (this.infoClient.whereid_2 !== '') {
                    this.idsteticcenter = this.infoClient.whereid_2;
                    this.ppvvEstetica = this.idsteticcenter;
                }
                this.getScoreClient();

                if(this.infoClient.childNumber) {
                    this.arraySonsDate = [];
                    this.numson = this.infoClient.childNumber;
                    this.initArraySons();
                }

                this.contErrors++;
            },
            error => {
                this.textModal = error.error.message;
                this.textUrl = null;
                $('#exampleModalNotificationDataClient').modal('show');
                console.error('Error ' + JSON.stringify(error));
                this.contErrors++;
            }
        );
    }

    public filterFunctionPharmacy() {
        let input, filter, ul, li, a, i, div;
        input = document.getElementById('myInputPharmacy');
        filter = input.value.toUpperCase();
        div = document.getElementById('myDropdownPharmacy');
        a = div.getElementsByTagName('a');
        for (i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = '';
        } else {
            a[i].style.display = 'none';
        }
        }
    }

    public filterFunctionEstetica() {
        let input, filter, ul, li, a, i, div;
        input = document.getElementById('myInputEstetica');
        filter = input.value.toUpperCase();
        div = document.getElementById('myDropdownEstetica');
        a = div.getElementsByTagName('a');
        for (i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = '';
        } else {
            a[i].style.display = 'none';
        }
        }
    }

    public validateDateBirth(dateBirth: any): void {
        if (dateBirth) {
            let dateFormat: any = new Date(dateBirth);
            let timeDiff = Math.abs(Date.now() - <any>dateFormat);
            let edad: any = Math.ceil((timeDiff / (1000 * 3600 * 24)) / 365);
            if (edad < 18 || edad > 120) {
                this.textModal = 'Edad, no válida. Tiene que ser mayor de 18 años.';
                this.textUrl = null;
                $('#exampleModalNotificationDataClient').modal('show');
                this.contErrors++;
            }
        }
    }

    public validateDateBirthSons(dateBirth: any): void {
        if (dateBirth) {
            let dateFormat: any = new Date(dateBirth);
            let timeDiff = Math.abs(Date.now() - <any>dateFormat);
            let edad: any = Math.ceil((timeDiff / (1000 * 3600 * 24)) / 365);
            if (edad < 0 || edad > 120) {
                this.textModal = 'Edad, no válida. Tiene que ser mayor de 18 años.';
                this.textUrl = null;
                $('#exampleModalNotificationDataClient').modal('show');
                this.contErrors++;
            }
        }
    }

    // Funcion para copiar el codigo
    public copyMessage(val: string){
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

    }

    public closedInputSearchPharmacy(): void {
        this.showPharmacyInput = false;
        this.infoClient.whereid_1 = '';
        $('#myDropdownPharmacy').addClass('hide');
    }

    public closedInputSearchEstetica(): void {
        this.showEsteticaInput = false;
        this.infoClient.whereid_2 = '';
        $('#myDropdownEstetica').addClass('hide');
    }

    public selectSitesPharmacy(): void {
        let ppvvonline: Array<any> = [];
        let ppvvmixtos: Array<any> = [];
        let ppvvonline2: Array<any> = [];
        let ppvvonlinemixtos: Array<any> = [];
        if (this.valueProvinceImportantPharmacy !== '') {
            this.loaderFarmcia = true;
            $('#myDropdownPharmacy').removeClass('hide');
            $('#myDropdownEstetica').addClass('hide');
            this.listadoSitiosPharmacy = [];
            this.stringNamePharmacy = '';
            if (this.valueProvinceImportantPharmacy === 'XX') {
                this._NAOSService.getPuntosVentaOnlinePharmacy().subscribe(
                    response => {
                        if (response.length > 0) {
                            ppvvonline = response;
                        }
                        this._NAOSService.getPuntosVentaMixtosPharmacy().subscribe(
                            response => {
                                if (response.length > 0) {
                                    ppvvmixtos = response;
                                }

                                this._NAOSService.getPuntosVentaPruebaPPVVLISTA().subscribe(
                                    responseOnline => {
                                        ppvvonline2 = responseOnline;
                                        ppvvonlinemixtos = ppvvonline.concat(ppvvmixtos).concat(ppvvonline2);

                                        if (ppvvonlinemixtos.length > 0) {
                                            this.listadoSitiosPharmacy = this.formatLabelSelectCentersPharmarcy(ppvvonlinemixtos);
                                            $('#myDropdownPharmacy').addClass('show');
                                            this.loaderFarmcia = false;
                                        } else {
                                            this.listadoSitiosPharmacy = [];
                                            this.loaderFarmcia = false;
                                            this.closedInputSearchPharmacy();
                                        }
                                    },
                                    error => {
                                        this.loaderSelectPPVV = false;
                                        console.error('Error al obtener los puntos de venta.');
                                        this.loaderFarmcia = false;
                                    }
                                );

                                /* ppvvonlinemixtos = ppvvonline.concat(ppvvmixtos);

                                if (ppvvonlinemixtos.length > 0) {
                                    this.listadoSitiosPharmacy = this.formatLabelSelectCentersPharmarcy(ppvvonlinemixtos);
                                    $('#myDropdownPharmacy').addClass('show');
                                    this.loaderFarmcia = false;
                                } else {
                                    this.listadoSitiosPharmacy = [];
                                    this.loaderFarmcia = false;
                                    this.closedInputSearchPharmacy();
                                } */

                            },
                            error => {
                                this.loaderSelectPPVV = false;
                                console.error('Error al obtener los puntos de venta.');
                                this.loaderFarmcia = false;
                            }
                        );
                    },
                    error => {
                        this.loaderSelectPPVV = false;
                        console.error('Error al obtener los puntos de venta.');
                        this.loaderFarmcia = false;
                    }
                );
            } else {
                this.arrType1 = [];
                this.arrType3 = [];
                this.arrType4 = [];
                this.arrType5 = [];
                this.arrTotal = [];
                this._NAOSService.getShopSiteProvince(this.valueProvinceImportantPharmacy, '1').subscribe(
                    response => {
                            this.arrType1 = response;
                            this._NAOSService.getShopSiteProvince(this.valueProvinceImportantPharmacy, '3').subscribe(
                                response => {
                                    this.arrType3 = response;
                                        this._NAOSService.getShopSiteProvince(this.valueProvinceImportantPharmacy, '4').subscribe(
                                            response => {
                                                this.arrType4 = response;
                                                    this._NAOSService.getShopSiteProvince(this.valueProvinceImportantPharmacy, '5').subscribe(
                                                        response => {
                                                            this.arrType5 = response;
                                                            this.arrTotal = this.arrType1.concat(this.arrType3).concat(this.arrType4).concat(this.arrType5);
                                                            this.listadoSitiosPharmacy = this.formatLabelSelectCentersPharmarcy(this.arrTotal);
                                                            $('#myDropdownPharmacy').addClass('show');
                                                            this.loaderFarmcia = false;
                                                        },
                                                        error => {
                                                            this.loaderSelectPPVV = false;
                                                            console.error('Error al obtener los puntos de venta.');
                                                            this.loaderFarmcia = false;
                                                        }
                                                    );
                                            },
                                            error => {
                                                this.loaderSelectPPVV = false;
                                                console.error('Error al obtener los puntos de venta.');
                                                this.loaderFarmcia = false;
                                            }
                                        );
                                },
                                error => {
                                    this.loaderSelectPPVV = false;
                                    console.error('Error al obtener los puntos de venta.');
                                    this.loaderFarmcia = false;
                                }
                            );
                            /* this.listadoSitiosPharmacy = this.formatLabelSelectCentersPharmarcy(response); */

                    },
                    error => {
                        this.loaderSelectPPVV = false;
                        console.error('Error al obtener los puntos de venta.');
                        this.loaderFarmcia = false;
                    }
                );
            }
        } else {
            this.closedInputSearchPharmacy();
        }
    }

    public selectSitesEstetica(): void {
        let ppvvonline: Array<any> = [];
        let ppvvmixtos: Array<any> = [];
        let ppvvonlinemixtos: Array<any> = [];
        if (this.valueProvinceImportantEstetica !== '') {
            this.loaderEstetica = true;
            $('#myDropdownEstetica').removeClass('hide');
            $('#myDropdownPharmacy').addClass('hide');
            this.listadoSitiosEstetica = [];
            this.stringNameEstetica = '';
            if (this.valueProvinceImportantEstetica === 'XX') {
                this._NAOSService.getPuntosVentaOnlineEsthetic().subscribe(
                    response => {
                        if (response.length > 0) {
                            ppvvonline = response;
                        }
                        this._NAOSService.getPuntosVentaMixtosEsthetic().subscribe(
                            response => {
                                if (response.length > 0) {
                                    ppvvmixtos = response;
                                }
                                ppvvonlinemixtos = ppvvonline.concat(ppvvmixtos);
                                if (ppvvonlinemixtos.length > 0) {
                                    this.listadoSitiosEstetica = this.formatLabelSelectCentersPharmarcy(ppvvonlinemixtos);
                                    $('#myDropdownEstetica').addClass('show');
                                    this.loaderEstetica = false;
                                } else {
                                    this.listadoSitiosEstetica = [];
                                    this.loaderEstetica = false;
                                    this.closedInputSearchEstetica();
                                }
                            },
                            error => {
                                this.loaderSelectPPVV = false;
                                console.error('Error al obtener los puntos de venta.');
                                this.loaderEstetica = false;
                            }
                        );
                    },
                    error => {
                        this.loaderSelectPPVV = false;
                        console.error('Error al obtener los puntos de venta.');
                        this.loaderEstetica = false;
                    }
                );
            } else {
                this._NAOSService.getShopSiteProvince(this.valueProvinceImportantEstetica, '2').subscribe(
                    response => {
                        if (response.length > 0) {
                            this.listadoSitiosEstetica = this.formatLabelSelectCentersPharmarcy(response);
                            $('#myDropdownEstetica').addClass('show');
                        }
                        this.loaderEstetica = false;
                    },
                    error => {
                        this.loaderSelectPPVV = false;
                        console.error('Error al obtener los puntos de venta.');
                        this.loaderEstetica = false;
                    }
                );
            }
        } else {
            this.closedInputSearchEstetica();
        }
    }

    public formatLabelSelectCentersPharmarcy(arraySites: any): any {
        let auxArray: Array<any> = [];
        for (let item of arraySites) {
            if (item.jwhereAddress && item.jwhereAddress.postal_cd !== '') {
                item.fullName = item.jwhere.where_ld.toUpperCase() + ' (' + item.where_cd + ' - ' + item.jwhereAddress.postal_cd + ')';
            } else {
                item.fullName = item.jwhere.where_ld.toUpperCase() + ' (' + item.where_cd + ')';
            }
            auxArray.push(item);
        }
        $('#myDropdownPharmacy').addClass('show');
        return auxArray;
    }

    public getSiteSelectedPharmacy(evento: any): void {
        this.ppvvFarmacia = evento;
        $('#confirmaFarmaciaModal').modal('show');
    }

    public getSiteSelectedEsthetic(evento: any): void {
        this.ppvvEstetica = evento;
        $('#confirmaEsteticaModal').modal('show');
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    public setSelectedSitePharmacy(sitewhere_id: any, name: string): void {
        this.stringNamePharmacy = name;
        this.showEsteticaInput = false;
        $('#myDropdownEstetica').addClass('hide');
        document.getElementById('myDropdownPharmacy').classList.toggle('hide');
        this.showPharmacyInput = true;
        this.getSiteSelectedPharmacy(sitewhere_id);
    }

    public setSelectedSiteEstetica(sitewhere_id: any, name: string): void {
        this.stringNameEstetica = name;
        this.showPharmacyInput = false;
        $('#myDropdownPharmacy').addClass('hide');
        document.getElementById('myDropdownEstetica').classList.toggle('hide');
        this.showEsteticaInput = true;
        this.getSiteSelectedEsthetic(sitewhere_id);
    }

    /**
     * Funcion para devolver el nombre del centro asociado al cliente
     * @param id id del centro asociado al cliente
     */
    public formatStringCentersByIdPharmacy(id: any): void {
        if (id !== undefined && id !== null) {
            this._NAOSService.getShopDetailById(id).subscribe(
                response => {
                    this.stringNamePharmacy = response[0].jwhere.where_ld;
                },
                error => {
                    console.error('Error ' + JSON.stringify(error));
                }
            );
        } else {
            this.stringNamePharmacy = '';
        }
    }

    public formatStringCentersByIdEsthetic(id: any): void {
        if (id !== undefined && id !== null) {
            this._NAOSService.getShopDetailById(id).subscribe(
                response => {
                    this.stringNameEstetica = response[0].jwhere.where_ld;
                },
                error => {
                    console.error('Error ' + JSON.stringify(error));
                }
            );
        } else {
            this.stringNameEstetica = '';
        }
    }

    /**
     * Funcion para guardar la farmacia
     * @param idppvv
     */
    public setCenterSiteFarmacia(center: any): void {
        this.infoClient.whereid_1 = center;
        $('#confirmaFarmaciaModal').modal('hide');
        this.updateDataClient();
        sessionStorage.setItem('refreshDataClient', '1');
    }

    /**
     * Funcion para guardar la farmacia/centro estética
     * @param idppvv
     */
    public setCenterSiteEstetica(center: any): void {
        this.infoClient.whereid_2 = center;
        $('#confirmaEsteticaModal').modal('hide');
        this.updateDataClient();
        sessionStorage.setItem('refreshDataClient', '1');
    }

    /**
     * Funcion para cambiar el estado del comentario de baja
     * @param evento evento al hacer click en el checkbox de baja
     */
    public changeStateCommentBaja(): void {
        if (!this.disabledCommentBajaValue) {
            this.disabledCommentBajaValue = true;
        } else if (this.disabledCommentBajaValue) {
            this.disabledCommentBajaValue = false;
            this.commentBaja = '';
        }
    }

    /**
     * Funcion para obtener el listado de razones para la baja del cliente
     */
    public getReason(): void {
        this._loginService.getReasonRemove().subscribe(
            response => {
                let cont: number = 1;
                this.listReason = response;
                for (let item of this.listReason) {
                    item.valueModel = '';
                    item.value = cont;
                    item.valueid = 'defaultCheck' + cont;
                    cont++;
                }
                this.contErrors++;
            },
            error => {
                console.error('Error' + JSON.stringify(error));
                this.contErrors++;
            }
        );
    }

    /**
     * Funcion para dar de baja un cliente del club
     */
    public sendBajaClient(): void {
        $('#bajaModal').modal('hide');
        let arrayReasonsSend: Array<any> = [];
        for (let item of this.listReason) {
            let obj: any;
            if (item.valueModel !== '') {
                if (item.text == 'Otro' && this.commentBaja !== '') {
                    obj = {
                            'id': item.id,
                            'text': this.commentBaja
                    };
                    arrayReasonsSend.push(obj);
                } else if (item.text !== 'Otro') {
                    obj = {
                            'id': item.id,
                            'text': item.text
                    };
                    arrayReasonsSend.push(obj);
                }
            }
        }
        let objAuxBaja = {
            'reason_delete': arrayReasonsSend
        };
        clearInterval(this.intervalRefresAdvserver);
        clearInterval(this.intervalRefreshDataClient);
        this._loginService.removeCount(this.idclient, objAuxBaja).subscribe(
            () => {
                this.textUrl = '/main';
                this.textModalProfile = 'Hemos recibido correctamente tu solicitud de baja de NAOS CLUB. Te informamos que, una vez tramitada dicha solicitud, procederemos a cancelar tus datos, tal y como explicamos en nuestra política de privacidad.Recuerda que si quieres volver a disfrutar de las ventajas que te ofrece NAOS CLUB, deberás registrarte de nuevo. ¡Hasta pronto!';
                $('#exampleModalNotificationProfile').modal('show');
                localStorage.clear();
                sessionStorage.clear();
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.textModalProfile = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotification').modal('show');
            }
        );
    }

    /**
     * Funcion para validar email
     */
    public validateEmail(): boolean {
        let valido: boolean = true;
        if (environment.VALIDATIONEMAIL) {
            if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/.test(this.infoClient.email)) {
                valido = true;
            } else {
                valido = false;
            }
        }
        return valido;
    }

    /**
     * Función para inicializar la primera opción del menu al entrar
     */
    public initSubMenu(): void {
        $('#opt1').removeClass('optSubMenu');
        $('#opt1').addClass('optSelected');
    }

    /**
     * Funcion que actua de submenu dentro de store
     * @param opt numero de opción sobre la que se ha echo clic
     */
    public clickOpt(opt: number): void {
        switch (opt) {
            case 1:
                $('#optprofile1').addClass('selected');
                $('#optprofile2').removeClass('selected');
                this._router.navigate(['/home/profilepage/dataclient']);
                break;
            case 2:
                $('#optprofile2').addClass('selected');
                $('#optprofile1').removeClass('selected');
                this._router.navigate(['/home/profilepage/datadirection']);
                break;
        }
    }

    /**
     * Funcion para obtener las estrellas del cliente
     */
    public getScoreClient(): void {
        this._userService.getScoreClient(this.idclient).subscribe(
            response => {
                this.scoreClient = response[0].available;
                this.contErrors++;
            },
            error => {
                this.contErrors++;
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Funcion para formatear los sitios que nos llegan para separarlos en farmacias y centros de estética
     * @param arrayCentros centros que nos llegan de la petición para separarlos
     */
    public formatPPVVSites(arrayCentros: Array<any>): void {
        for (let item of arrayCentros) {
            if (item.jwhere.cif !== 'GENERICO') {
                if (item.jwhere.shop_type_id == '2') {
                    this.puntosVentaEsteticas.push(item);
                } else {
                    this.puntosVentaFarmacias.push(item);
                }
            }
        }
        this.contErrors++;
    }

    /**
     * Funcion para formatear el label de los centros
     * @param nombre del centro
     * @param codigo código del centro
     */
    public formatLabelSelectCenters(): any {
        this.puntosVenta.map(
            (i) => {
                if (i.jwhereAddress) {
                    i.fullName = i.jwhere.where_ld + ' (' + i.where_cd + ' - ' + i.jwhereAddress.postal_cd + ')';
                }
            }
        );
        this.contErrors++;
    }

    /**
     * Funcion para validar los datos del cliente
     */
    public validateDataUpdateClient(): boolean {
        let validate: boolean;
        let regexFirstName = /^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]{3,30}$/g;
        let regexSurname = /^[a-zA-ZÁÉÍÓÚáéíóúñÑ\s]{3,30}$/g;
        let regexEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
        if (regexFirstName.test(this.infoClient.firstname) && regexEmail.test(this.infoClient.email)) {
            validate = true;
        }
        if (this.infoClient.surname !== '' && regexSurname.test(this.infoClient.surname)) {
            validate = true;
        }
        return validate;
    }

    /**
     * Funcion para inicializar los botones de sexo con el sexo del cliente
     */
    public initButtonsSex(): void {
        setTimeout(() => {
            if (this.infoClient.sex !== undefined && this.infoClient.sex !== null) {
                switch (this.infoClient.sex) {
                    case 'M':
                    $('#btnFemale').removeClass('btnSex');
                    $('#btnFemale').addClass('btnSexSelected');
                    break;
                case 'H':
                    $('#btnMale').removeClass('btnSex');
                    $('#btnMale').addClass('btnSexSelected');
                    break;
                case 'O':
                    $('#btnOther').removeClass('btnSex');
                    $('#btnOther').addClass('btnSexSelected');
                    break;
                }
            }
        }, 500);
    }

    public goToCCGG(): void {
        this._router.navigate(['/legals']);
    }

    public goToPrivacity(): void {
        this._router.navigate(['/privacitynew']);
    }

    /**
     * Funcion para modificar los datos del cliente
     */
    public updateDataClient(): void {
        if (this.infoClient.coms == 'false') {
            this.infoClient.coms = false;
        } else {
            this.infoClient.coms = true;
        }
        if (this.infoClient.whereid_1 !== '') {
            this.idpharmacy = this.infoClient.whereid_1;
        } else if (this.infoClient.whereid_2 !== '') {
            this.idpharmacy = this.infoClient.whereid_2;
        }
        this.initButtonsSex();
        if (this.validateDataUpdateClient()) {
            if (this.validateName()) {
                if (this.validateSurname()) {
                    if (this.validatePhone()) {
                        if (this.validateEmail()) {
                            this.sendDataOtherClient();
                            this.loader = false;
                            this._userService.updateDataClient(this.idclient, this.infoClient).subscribe(
                                () => {
                                    this.textModal = 'Los cambios se han guardado correctamente. Muchas gracias por actualizar tus datos.';
                                    this.textUrl = null;
                                    $('#exampleModalNotificationDataClient').modal('show');
                                    this.getDataClient();
                                    this.getAdvserverPrivate();
                                    localStorage.setItem('refreshDataClient', 'true');
                                    this.loader = true;
                                },
                                error => {
                                    if (error.error.code == '409.24') {
                                        this.textModal = 'No ha sido posible actualizar el email, el correo introducido ya está registrado con otro usuario. ';
                                        this.textUrl = null;
                                        $('#exampleModalNotificationDataClient').modal('show');
                                    } else if (error.error.code == '400.2') {
                                        if (error.error.message.indexOf('contact_telephone_sd') > -1) {
                                            this.textModal = 'Por favor, rellena un teléfono móvil válido.';
                                            this.textUrl = null;
                                            $('#exampleModalNotificationDataClient').modal('show');
                                        } else if (error.error.message.indexOf('surname') > -1) {
                                            this.textModal = 'Por favor, rellena un apellido válido.';
                                            this.textUrl = null;
                                            $('#exampleModalNotificationDataClient').modal('show');
                                        } else if (error.error.message.indexOf('datebirth') > -1) {
                                            this.textModal = 'Por favor, introduce una fecha de nacimiento válida';
                                            this.textUrl = null;
                                            $('#exampleModalNotificationDataClient').modal('show');
                                        } else if (error.error.message.indexOf('childNumber') > -1) {
                                            this.textModal = 'Por favor, introduce un número de hijos válido.';
                                            this.textUrl = null;
                                            $('#exampleModalNotificationDataClient').modal('show');
                                        } else {
                                            this.textModal = 'Por favor, introduce datos válidos en el formulario.';
                                            this.textUrl = null;
                                            $('#exampleModalNotificationDataClient').modal('show');
                                        }
                                    } else {
                                        this.textModal = 'No ha sido posible realizar los cambios. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                                        this.textUrl = null;
                                        $('#exampleModalNotificationDataClient').modal('show');
                                    }
                                    this.loader = true;
                                }
                            );
                        } else {
                            this.textModal = 'Por favor, introduce un email válido.';
                            this.textUrl = null;
                            $('#exampleModalNotificationDataClient').modal('show');
                        }
                    } else {
                        this.textModal = 'Por favor, introduce un teléfono válido.';
                        this.textUrl = null;
                        $('#exampleModalNotificationDataClient').modal('show');
                    }
                } else {
                    this.textModal = 'Por favor, introduce un apellido válido.';
                    this.textUrl = null;
                    $('#exampleModalNotificationDataClient').modal('show');
                }
            } else {
                this.textModal = 'Por favor, introduce un nombre válido.';
                this.textUrl = null;
                $('#exampleModalNotificationDataClient').modal('show');
            }
        } else {
            this.textModal = 'Por favor, rellena los campos obligatorios.';
            this.textUrl = null;
            $('#exampleModalNotificationDataClient').modal('show');
        }
        this.contErrors++;
    }

    public sendDataOtherClient(): void {
        this.idfarmacia = this.infoClient.whereid_1;
        this.idestetica = this.infoClient.whereid_2;
        this.infoClient.childNumber = this.numson;
        this.infoClient.datebirth_child1 = (this.arraySonsDate[0] !== undefined) ? this.arraySonsDate[0].date : '';
        this.infoClient.datebirth_child2 = (this.arraySonsDate[1] !== undefined) ? this.arraySonsDate[1].date : '';
        this.infoClient.datebirth_child3 = (this.arraySonsDate[2] !== undefined) ? this.arraySonsDate[2].date : '';
        this.infoClient.datebirth_child4 = (this.arraySonsDate[3] !== undefined) ? this.arraySonsDate[3].date : '';
        this.infoClient.whereid_1 = this.idfarmacia;
        this.infoClient.whereid_2 = this.idestetica;
        this.infoClient.employee_identifier = this.enrolador;
    }

    public checkComsClient(): void {
        if (this.infoClient.coms == true) {
            this._router.navigate(['/leavecomers']);
        }
    }

    public validateName(): boolean {
        if (/^[a-zA-ZÁÉÍÓÚáéíóúñÑÀÈÌÒÙÜÇàèìòùüçºª'\.\-\s]{3,30}$/.test(this.infoClient.firstname)) {
            return true;
        } else {
            return false;
        }
    }

    public validateSurname(): boolean {
        if (this.infoClient.surname !== '') {
            if (/^[a-zA-ZÁÉÍÓÚáéíóúñÑÀÈÌÒÙÜÇàèìòùüç\-\s]{3,30}$/.test(this.infoClient.surname)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public validatePhone(): boolean {
        if (this.infoClient.contact_telephone_sd !== '' && this.infoClient.contact_telephone_sd !== undefined) {
            if (/^(\+34|0034|34)?[6|7][0-9]{8}$/.test(this.infoClient.contact_telephone_sd)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    /**
     * Boton para elegir sexo del cliente
     * @param type tipo elegido
     */
    public setSexClient(type: string) {
        switch (type) {
            case 'M':
                this.infoClient.sex = type;
                $('#btnFemale').removeClass('btnSex');
                $('#btnFemale').addClass('btnSexSelected');
                $('#btnMale').removeClass('btnSexSelected');
                $('#btnMale').addClass('btnSex');
                $('#btnOther').removeClass('btnSexSelected');
                $('#btnOther').addClass('btnSex');
                break;
            case 'H':
                this.infoClient.sex = type;
                $('#btnMale').removeClass('btnSex');
                $('#btnMale').addClass('btnSexSelected');
                $('#btnFemale').removeClass('btnSexSelected');
                $('#btnFemale').addClass('btnSex');
                $('#btnOther').removeClass('btnSexSelected');
                $('#btnOther').addClass('btnSex');
                break;
            case 'O':
                this.infoClient.sex = type;
                $('#btnOther').removeClass('btnSex');
                $('#btnOther').addClass('btnSexSelected');
                $('#btnFemale').removeClass('btnSexSelected');
                $('#btnFemale').addClass('btnSex');
                $('#btnMale').removeClass('btnSexSelected');
                $('#btnMale').addClass('btnSex');
                break;
        }
    }

    /**
     * Funcion para inicializar array de hijos
     */
    public initArraySons(): void {
        switch (this.infoClient.childNumber) {
            case '1':
                    this.arraySonsDate.push(
                        {
                            'hijo': 1,
                            'date': this.infoClient.datebirth_child1
                        }
                    );
                break;
            case '2':
                    this.arraySonsDate.push(
                        {
                            'hijo': 1,
                            'date': this.infoClient.datebirth_child1
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 2,
                            'date': this.infoClient.datebirth_child2
                        }
                    );
                break;
            case '3':
                    this.arraySonsDate.push(
                        {
                            'hijo': 1,
                            'date': this.infoClient.datebirth_child1
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 2,
                            'date': this.infoClient.datebirth_child2
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 3,
                            'date': this.infoClient.datebirth_child3
                        }
                    );
                break;
            case '4':
                    this.arraySonsDate.push(
                        {
                            'hijo': 1,
                            'date': this.infoClient.datebirth_child1
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 2,
                            'date': this.infoClient.datebirth_child2
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 3,
                            'date': this.infoClient.datebirth_child3
                        }
                    );
                    this.arraySonsDate.push(
                        {
                            'hijo': 4,
                            'date': this.infoClient.datebirth_child4
                        }
                    );
                break;
        }
    }

    /**
     * Funcion para introducir en el array de hijos el numero de objetos como numero de hijos introducido
     * @param num numero de hijos a repetir en el array
     */
    public setNumSon(num: number): void {
        if (num <= 4) {
            let arrayAux: Array<any> = [];
            this.arraySonsDate = [];
            for (let x = 1; x <= num; x++) {
                let obj;
                if (x == 1 && this.infoClient.datebirth_child1 !== '') {
                    obj = {
                        'hijo': x,
                        'date': this.infoClient.datebirth_child1
                    };
                } else if (x == 1 && this.infoClient.datebirth_child1 == '') {
                    obj = {
                        'hijo': x,
                        'date': ''
                    };
                }
                if (x == 2 && this.infoClient.datebirth_child2 !== '') {
                    obj = {
                        'hijo': x,
                        'date': this.infoClient.datebirth_child2
                    };
                } else if (x == 2 && this.infoClient.datebirth_child2 == '') {
                    obj = {
                        'hijo': x,
                        'date': ''
                    };
                }
                if (x == 3 && this.infoClient.datebirth_child3 !== '') {
                    obj = {
                        'hijo': x,
                        'date': this.infoClient.datebirth_child3
                    };
                } else if (x == 3 && this.infoClient.datebirth_child3 == '') {
                    obj = {
                        'hijo': x,
                        'date': ''
                    };
                }
                if (x == 4 && this.infoClient.datebirth_child4 !== '') {
                    obj = {
                        'hijo': x,
                        'date': this.infoClient.datebirth_child4
                    };
                } else if (x == 4 && this.infoClient.datebirth_child4 == '') {
                    obj = {
                        'hijo': x,
                        'date': ''
                    };
                }
                arrayAux.push(obj);
            }
            this.arraySonsDate = arrayAux;
        } else {
            this.textModal = 'Solo puede introducir 4 hijos como máximo.';
            this.textUrl = null;
            $('#exampleModalNotificationDataOther').modal('show');
            window.parent.postMessage('HelloParentFrame!', '*');
        }
    }

    /**
     * Funcion para obtener el enrolador si ya existe
     * @param idppvv id del sitio que ya existe
     */
    public getEnroladorExist(idppvv, idppvv2): void {
      if (idppvv !== '') {
        this._NAOSService.getEnroladres(idppvv).subscribe(
          response => {
              this.enroladores = response;
              for (let item of this.enroladores) {
                  if (item.identityVld == this.infoClient.employee_identifier) {
                      this.nombreEnroladorCompleto = item.identityVld;
                  }
              }
          },
          error => {
              console.error('Error al obtener los puntos de venta.');
              this.textModal = error.error.message;
              this.textUrl = null;
              $('#exampleModalNotificationDataOther').modal('show');
              window.parent.postMessage('HelloParentFrame!', '*');
          }
      );
      }
      if (idppvv2 !== '') {
        this._NAOSService.getEnroladres(idppvv2).subscribe(
          response => {
              this.enroladores2 = response;
              for (let item of this.enroladores2) {
                  if (item.identityVld == this.infoClient.employee_identifier) {
                      this.nombreEnroladorCompleto = item.identityVld;
                  }
              }
          },
          error => {
              console.error('Error al obtener los puntos de venta.');
              this.textModal = error.error.message;
              this.textUrl = null;
              $('#exampleModalNotificationDataOther').modal('show');
              window.parent.postMessage('HelloParentFrame!', '*');
          }
      );
      }

    }
}
