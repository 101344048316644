import { Component, OnInit } from '@angular/core';
declare var $ : any;

@Component({
  selector: 'app-navbarbioderma',
  templateUrl: './navbarbioderma.component.html',
  styleUrls: ['./navbarbioderma.component.css']
})
export class NavbarBiodermaComponent implements OnInit {

  public showsubmenu: boolean;
  public idsubmenucontent: number;

  constructor() {
    this.showsubmenu = false;
  }

  ngOnInit(): void { }
}
