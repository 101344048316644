import { Component, OnInit } from '@angular/core';
import { NAOSService } from 'src/app/services/naos.service';

@Component({
  selector: 'app-privacitynewsecond',
  templateUrl: './privacitynewsecond.component.html',
  styleUrls: ['./privacitynewsecond.component.css']
})
export class PrivacityNewSecondComponent implements OnInit {
  constructor(
    private _naosService: NAOSService
  ) { }

  ngOnInit(): void {
  }

  /**
     * Funcion para volver atras
     */
    public goToAfter(): void {
      this._naosService.returnNavigationPageBack();
  }
}
