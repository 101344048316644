<div class="container containerDatos mw-100">
    <div class="row loader" *ngIf="!this.loader">
        <div class="col-md-12 text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
    <div class="row" *ngIf="this.loader">
        <div class="col-md-12">
            <div class="row" *ngIf="this.infoClient.employee_identifier == ''">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">  
                </div>
            </div>
            <div class="row" *ngIf="this.infoClient.employee_identifier !== ''">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="codigoEnrolador">Código enrolador</label><i [placement]="['right', 'bottom', 'auto']" ngbPopover="Es el código del empleado de la farmacia que te ayudó a darte de alta en el NAOS Club." class="fas fa-info-circle iconInfo"></i>
                        <input type="text" class="form-control" [(ngModel)]="this.nombreEnroladorCompleto" id="codigoEnrolador" disabled>
                    </div>
                </div>
                <div class="col-md-6">

                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="numson">Número de hijos</label>
                        <input type="number" class="form-control" max=4 (keyup)="this.setNumSon(this.numson)" (change)="this.setNumSon(this.numson)" [(ngModel)]="this.numson" id="numson">
                    </div>
                </div>
                <div class="col-md-6">

                </div>
            </div>
            <div class="row">
                <div class="col-md-4" *ngFor="let item of this.arraySonsDate;let i = index">
                    <div class="form-group">
                        <label [for]="'fechaNacimientoHijo' + this.arraySonsDate[i].hijo">Fecha de nacimiento hijo {{ this.arraySonsDate[i].hijo }}</label>
                        <input type="date" class="form-control" [(ngModel)]="this.arraySonsDate[i].date" [id]="'fechaNacimientoHijo' + i">
                    </div>
                </div>
            </div>
            <div class="row rowButton justify-content-center mt-5">
                <div class="col-md-3 col-7 col-sm-4 col-lg-2">
                    <button class="btn btnEnviarForms w-100" (click)="this.sendDataOtherClient()">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationDataOther" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>