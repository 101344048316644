import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './report.component';

@NgModule({
    declarations: [ReportComponent],
    imports: [ CommonModule ],
    exports: [ReportComponent],
    providers: [],
})
export class ReportModule {}