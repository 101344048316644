import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';
import { NAOSService } from './naos.service';

@Injectable()
export class ExchangesService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(
        private _http: HttpClient,
        private _naosService: NAOSService
    ) {
        this.url = environment.urlAPI;
    }

    /**
     * Funcion para hacer un canje de estrellas
     * @param idclient id del cliente que hace el pedido
     * @param body datos del pedido
     */
    public sendExchange(idclient: any, body: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.SENDEXCHANGE + idclient + '/exchanges';
        return this._http.post(this._apiUrl, body);
    }

    /**
     * Funcion obtener el listado de canjes de un cliente
     * @param idclient del que vamos a obtener los canjes
     */
    public getExchangesClient(idclient: any, dateIni: any, dateFin: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETEXCHANGESLIST + idclient + '/exchanges';
        let params: HttpParams = new HttpParams();
        params = params.append('dateFrom', dateIni);
        params = params.append('dateTo', dateFin);
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener todos los datos del histórico en la misma petición
     * @param idclient id del cliente para ver el historico
     * @param dateIni fecha de inicio
     * @param dateFin fecha de fin
     */
    public getExchangesAll(idclient: any, dateIni: any, dateFin: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETEXCHANGESLIST + idclient + '/activities';
        let params: HttpParams = new HttpParams();
        params = params.append('dateFrom', dateIni);
        params = params.append('dateTo', dateFin);
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los estados de un pedido
     * @param idclient id del cliente del pedido
     * @param idexchange id del pedido
     */
    public getStateExchange(idclient: any, idexchange: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETSTATUSEXCHANGE + idclient + '/exchanges/' + idexchange + '/status';
        return this._http.get(this._apiUrl);
    }

    /**
     * Funcion para cancelar un pedido
     * @param idclient id del cliente del pedido
     * @param idexchange id del pedido para cancelar
     */
    public cancelExchange(idclient: any, idexchange: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.CANCELEXCHANGE + idclient + '/exchanges/' + idexchange;
        return this._http.delete(this._apiUrl);
    }

    /**
     * Funcion para reenviar los datos de un pedido
     * @param idexchange id del pedido para reenviar
     */
    public resendOrderData(idcliente: any, idexchange: any, email: any, body): Observable<any> {
        this._apiUrl = this.url + AppSettings.RESENDORDERDATA + idcliente + '/deposits/' + idexchange + '/resend?to=' + email;
        return this._http.post( this._apiUrl, body);
    }
}
