import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';

@Injectable()
export class DirectionService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(private _http: HttpClient) {
        this.url = environment.urlAPI;
    }

    /**
     * Funcion para dar de alta una dirección
     * @param idclient del que vamos a crear la dirección
     * @param body datos de la direccion
     */
    public sendDirectionClient(idclient: any, body: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.NEWDIRECTIONCLIENT + idclient + '/addresses';
        return this._http.post(this._apiUrl, body);
    }

    /**
     * Funcion para dar de alta una dirección sin loguearse
     * @param idclient del que vamos a crear la dirección
     * @param body datos de la direccion
     */
    public sendDirectionClientPublic(idclient: any, body: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.NEWDIRECTIONCLIENTPUBLIC + idclient + '/addresses/public';
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        return this._http.post(this._apiUrl, body, { params });
    }

    /**
     * Función para modificar una dirección  de un cliente
     * @param idclient id del cliente de la dirección
     * @param idaddress id de la dirección que se va a modificar
     * @param body datos de la dirección
     */
    public updateDirectionClient(idclient: any, idaddress: any, body: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.UPDATEDATADIRECTIONCLIENT + idclient + '/addresses/' + idaddress;
        return this._http.put(this._apiUrl, body);
    }

    /**
     * Funcion para autocompletar la localidad
     * @param localty termino a buscar la localidad
     */
    public autoCompletionLocality(localty: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.AUTOCOMPLETELOCALITY;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('toSearch', localty);
        return this._http.get(this._apiUrl, { params } );
    }

    /**
     * Funcion para autocompletar la via
     * @param municiplalityCode codigo municipio seleccionado en locality
     * @param terms termino a buscar la via
     */
    public autoCompletionVias(municiplalityCode: string, terms: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.AUTOCOMPLETEVIA;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('municipalityCode', municiplalityCode);
        params = params.append('toSearch', terms);
        return this._http.get(this._apiUrl, { params } );
    }

    /**
     * Funcion para autocompletar el numero de la via por municipio y via
     * @param municipalityCode codigo del municipio por el que buscar
     * @param streetCode codigo de la calle por la que buscar
     * @param terms termino a buscar el numero
     */
    public autoCompletionNumbers(municipalityCode: string, streetCode: string, terms: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.AUTOCOMPLETENUMBER;
        let params: HttpParams = new HttpParams();
        params = params.append('companyId', '4');
        params = params.append('municipalityCode', municipalityCode);
        params = params.append('streetCode', streetCode);
        params = params.append('toSearch', terms);
        return this._http.get(this._apiUrl, { params } );
    }

    /**
     * Borrar direccion de un cliente
     * @param idclient id del cliente sobre el que borrar la direccion
     * @param iddirection id de la direccion a borrar
     */
    public deleteDirectionClient(idclient: any, iddirection: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.DELETEDIRECTIONCLIENT + idclient + '/addresses/' + iddirection;
        return this._http.delete(this._apiUrl);
    }


}
