import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';

@Component({
    selector: 'app-legals',
    templateUrl: './legals.component.html',
    styleUrls: ['./legals.component.css'],
    providers: [NAOSService]
})
export class LegalsComponent implements OnInit {


    constructor(
        private _router: Router,
        private _naosService: NAOSService
    ) { }

    ngOnInit(): void {
    }

    public goToExclusion(): void {
        this._router.navigate(['/sitesnoauthorization']);
    }

    public goToPrivacity(): void {
        this._router.navigate(['/privacitynew']);
    }

    public goToProfile(): void {
        this._router.navigate(['/home/profilepage']);
    }

    /**
     * Funcion para volver atras
     */
    public goToAfter(): void {
        this._naosService.returnNavigationPageBack();
    }
}
