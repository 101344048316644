import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyComponent } from './buy.component';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

@NgModule({
    declarations: [BuyComponent],
    imports: [
        CommonModule,
        FormsModule,
        TooltipModule,
        NgbModule,
        NaosNotificationModule
    ],
    exports: [BuyComponent],
    providers: [],
})
export class BuyModule {}
