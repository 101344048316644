import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent implements OnInit {

  public resultCheckHealth: any;

  constructor() {
    this.resultCheckHealth = '{"status":"UP"}';
  }

  ngOnInit(): void {
    
  }

}