<div class="container mt-3 w-mobile-100 mw-mobile-100 w-mobile-100 mw-mobile-100 justify-content-center">
  <ng-container *ngIf="this.loaderPromotions">
    <div class="row text-center">
      <div class="col-md align-self-center" *ngFor="let promo of this.contentAdvServerPromotions;let i = index">
        <!-- <div class="card flex-row responsiveCardSize" *ngIf="promo.content.legalLink.es !== undefined">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-xs-12">
              <img *ngIf="promo.content.data.es.cumplimiento !== undefined && promo.content.data.es.cumplimiento === 'TRUE'" (click)="this.showCumplimientoHover(i)" (mouseenter)="this.showCumplimientoHover(i)" (mouseleave)="this.deleteCumplimientoHover(i)" class="card-img-left example-card-img-responsive imagenFake" [src]="promo.content.images.web.es[0].url"/>
              <img *ngIf="promo.content.data.es.cumplimiento !== undefined && promo.content.data.es.cumplimiento === 'FALSE'" (click)="this.showCumplimientoHover(i)" class="card-img-left example-card-img-responsive imagenFake" [src]="promo.content.images.web.es[0].url"/>
              <div [attr.id]="'cumplimientotexto' + i" class="nomostrar">
                <div class="text-center mt-3 cursor-pointer" *ngIf="promo.offers !== null && promo.offers.length > 0" (mouseenter)="this.showCumplimientoHover(i)">
                  <ng-container *ngIf="promo.offers[0].accomplishment == 1">Completado: 100%</ng-container>
                  <ng-container *ngIf="promo.offers[0].accomplishment !== 1">Completado: 0%</ng-container>
                </div>
                <div class="text-center mt-3 cursor-pointer" *ngIf="promo.challenges !== null && promo.challenges.length > 0" (mouseenter)="this.showCumplimientoHover(i)">
                  <ng-container>Completado: {{this.calcPercentArray(promo.challenges)}}%</ng-container>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-xs-12 withoutFlex">
              <div class="card-body text-center bodyText">
                <span class="card-title h5 h4-sm titlePromo" [innerHTML]="promo.content.item_ld.es"></span>
                <span class="card-text textoPromo" [innerHTML]="promo.content.instructions.es.web"></span>
                <span *ngIf="promo.content.data.es !== '' && promo.content.data.es.tooltip !== ''" class="infoIcon"><i [placement]="['right', 'bottom', 'auto']" [ngbPopover]="promo.content.data.es.tooltip"
                  class="fas fa-info-circle iconInfo"></i></span>
                <ng-container *ngIf="promo.content.data.es !== ''">
                  <button class="btn btnSendTicket" (click)="this.goToButtonLink(promo.content.clikLink.es)">{{promo.content.data.es.boton}}</button>
                </ng-container>
                <ng-container *ngIf="promo.content.data.es == ''">
                  <button class="btn btnSendTicket" (click)="this.goToButtonLink(promo.content.clikLink.es)">¡Quiero participar!</button>
                </ng-container>
              </div>
            </div>
          </div>
        </div> -->
        <div class="card card-promotion mb-5" *ngIf="promo.content.legalLink.es == undefined">
          <img class="card-promotion-image" *ngIf="promo.content.data.es.cumplimiento !== undefined && promo.content.data.es.cumplimiento === 'TRUE'" 
            (mouseenter)="this.showCumplimientoHover(i)" (mouseleave)="this.deleteCumplimientoHover(i)" [src]="promo.content.images.web.es[0].url"/>
          <img class="card-promotion-image" *ngIf="promo.content.data.es.cumplimiento !== undefined && promo.content.data.es.cumplimiento === 'FALSE'" 
            [src]="promo.content.images.web.es[0].url"/>
          <div class="card-body" style="height: 400px;">
            <span class="title-card" [innerHTML]="promo.content.item_ld.es"></span>
            <hr>
            <span class="text-body-card" [innerHTML]="promo.content.instructions.es.web"></span>
            <span *ngIf="promo.content.data.es !== '' && promo.content.data.es.tooltip !== ''">
              <i [placement]="['right', 'bottom', 'auto']" [ngbPopover]="promo.content.data.es.tooltip" class="fas fa-info-circle iconInfo mb-5"></i>
            </span>
            <ng-container *ngIf="promo.content.data.es !== ''">
              <button class="btn btnSendTicket mt-3" (click)="this.goToButtonLink(promo.content.clikLink.es)">{{promo.content.data.es.boton}}</button>
            </ng-container>
            <ng-container *ngIf="promo.content.data.es == ''">
              <button class="btn btnSendTicket mt-3" (click)="this.goToButtonLink(promo.content.clikLink.es)">¡Quiero participar!</button>
            </ng-container>
          </div>
        </div>
            <!-- image card -->
            <!-- <div class="col-lg-6 col-md-12 col-xs-12">
              <img *ngIf="promo.content.data.es.cumplimiento !== undefined && promo.content.data.es.cumplimiento === 'TRUE'" (mouseenter)="this.showCumplimientoHover(i)" (mouseleave)="this.deleteCumplimientoHover(i)" class="card-img-left example-card-img-responsive imagenFake" [src]="promo.content.images.web.es[0].url"/>
              <img *ngIf="promo.content.data.es.cumplimiento !== undefined && promo.content.data.es.cumplimiento === 'FALSE'" class="card-img-left example-card-img-responsive imagenFake" [src]="promo.content.images.web.es[0].url"/>
              <div [attr.id]="'cumplimientotexto' + i" class="nomostrar">
                <div class="text-center mt-3 cursor-pointer" *ngIf="promo.offers !== null && promo.offers.length > 0" (mouseenter)="this.showCumplimientoHover(i)">
                  <ng-container *ngIf="promo.offers[0].accomplishment == 1">Completado: 100%</ng-container>
                  <ng-container *ngIf="promo.offers[0].accomplishment !== 1">Completado: 0%</ng-container>
                </div>
                <div class="text-center mt-3 cursor-pointer" *ngIf="promo.challenges !== null && promo.challenges.length > 0" (mouseenter)="this.showCumplimientoHover(i)">
                  <ng-container>Completado: {{this.calcPercentArray(promo.challenges)}}%</ng-container>
                </div>
              </div>
            </div> -->
            <!-- body card-->
            <!-- <div class="col-lg-6 col-md-12 col-xs-12 withoutFlex">
              <div class="card-body text-center bodyText">
                <span class="card-title h5 h4-sm titlePromo" [innerHTML]="promo.content.item_ld.es"></span>
                <span class="card-text textoPromo" [innerHTML]="promo.content.instructions.es.web"></span>
                <span *ngIf="promo.content.data.es !== '' && promo.content.data.es.tooltip !== ''" class="infoIcon">
                  <i [placement]="['right', 'bottom', 'auto']" [ngbPopover]="promo.content.data.es.tooltip" class="fas fa-info-circle iconInfo"></i>
                </span>
                <ng-container *ngIf="promo.content.data.es !== ''">
                  <button class="btn btnSendTicket" (click)="this.goToButtonLink(promo.content.clikLink.es)">{{promo.content.data.es.boton}}</button>
                </ng-container>
                <ng-container *ngIf="promo.content.data.es == ''">
                  <button class="btn btnSendTicket" (click)="this.goToButtonLink(promo.content.clikLink.es)">¡Quiero participar!</button>
                </ng-container>
              </div>
            </div> -->
        <!-- <div class="card flex-row responsiveCardSize2 pl-2" style="border: none !important" *ngIf="promo.content.legalLink.es !== undefined">
            <a *ngIf="promo.content.legalLink.es.indexOf('https:') === -1" href="#List-{{ i }}" class="buttonVerMas" data-toggle="collapse"><i class="fa fa-angle-down" aria-hidden="true"></i>&nbsp;Aviso Legal</a>
            <a *ngIf="promo.content.legalLink.es.indexOf('https:') > -1" [href]="promo.content.legalLink.es" target="_blank" class="buttonVerMas"><i class="fa fa-angle-down" aria-hidden="true"></i>&nbsp;Aviso Legal</a>
        </div> -->
      </div>
    </div>
  </ng-container>

</div>
