import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class LoginService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(
        private _http: HttpClient,
        private router: Router
    ) {
        this.url = environment.urlAPI;
    }

    /**
     * Peticion de registro usuario
     */
    public sendLogin(body: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.LOGIN;
        return this._http.post(this._apiUrl, body);
    }

    /**
     * Petición para obtener la confirmacion del email del cliente
     * @param token token de la confirmación de email
     */
    public confirmationEmail(token: string): Observable<any> {
        this._apiUrl = this.url + AppSettings.EMAILCONFIRM;
        let params: HttpParams = new HttpParams();
        params = params.append('token', token);
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para Scanner IA
     * @param memberId id del del usuario
     */
    public sendScannerIAData(memberId: any): Observable<any> {
      let obj = {
        'member_id': memberId
      };
      this._apiUrl = this.url + AppSettings.SENDSCANNERIA;
      return this._http.post(this._apiUrl, obj);
    }

    /**
     *
     * @param body
     */
    public confirmationEmailFake(token: string): Observable<any> {
        let obj = {
            'token': token
        };
        this._apiUrl = AppSettings.EMAILCONFIRMFAKE;
        return this._http.post(this._apiUrl, obj);
    }

    /**
     * Peticion para recuperar la contraseña
     * @param body email para recuperar la contraseña
     */
    public rememberPassword(body: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.REMEMBERPASSWORDSENDEMAIL;
        return this._http.post(this._apiUrl, body);
    }

    /**
     * Peticion para recuperar la contraseña
     * @param body obj json con la nueva contraseña
     */
    public rememberPasswordValidation(body: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.REMEMBERPASSWORDNEWPASSWORD;
        return this._http.post(this._apiUrl, body);
    }

    public logOut(): void {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
    }

    /**
     * Funcion para logueo de usuario de red a la web
     */
    /* public loginRed(user: string, password: string): boolean {
        let aux: boolean = false;
        for (let item of AppSettings.usersRed) {
            if (item.user.localeCompare(user) == 0 && item.password.localeCompare(password) == 0) {
                aux = true;
            }
        }
          return aux;
    } */

    /**
     * Function to remove client count
     * @param idclient id client for remove
     */
    public removeCount(idclient: any, obj: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.REMOVECOUNT + idclient;
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            }),
            body: obj
          };
        return this._http.delete(this._apiUrl, options);
    }

    /**
     * Funcion para obtener las razones del servicio de baja
     */
    public getReasonRemove(): Observable<any> {
        this._apiUrl = this.url + AppSettings.REASONSREMOVE;
        let params: HttpParams = new HttpParams();
        params = params.append('whatCd', '00010');
        return this._http.get(this._apiUrl, { params });
    }

    /**
     * Function to send and save logs LSP
     * @param obj data endpoint logs
     */
    public sendLogsLSP(obj: any, token: any): Observable<any> {
        let objFormated = encodeURIComponent(JSON.stringify(obj));
        this._apiUrl = this.url + AppSettings.LOGSLSP + '?companyId=4&data=' + objFormated;
        const options = {
            headers: new HttpHeaders({
              'apiKey': environment.API_KEY
            })
        };
        return this._http.post(this._apiUrl, {}, options);
    }
}
