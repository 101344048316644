import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BagService {

    constructor(
        private _http: HttpClient,
    ) {

    }

    /**
     * Funcion para obtener latitud y longitud de una dirección
     * @param address dirección para geolocalizar
     */
    public getGeocode(address: string): Observable<any> {
        /* return this._http.get(environment.ENTORNOAPIKEY + '/newproxy.php?url=' + 'maps/api/geocode/json&address='+address+'&key='+environment.APIKEYGEOCODINGBIODERMA); */
        return this._http.get(`https://maps.google.com/maps/api/geocode/json?address=${address}&sensor=false&key=${environment.APIKEYGEOCODINGBIODERMA}`);
    }
}
