import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NotifierService } from 'angular-notifier';
import { StoreService } from 'src/app/services/store.service';
import { BagService } from 'src/app/services/bag.service';
import { environment } from 'src/environments/environment';
import { LogService } from '@dagonmetric/ng-log';
import { NAOSService } from 'src/app/services/naos.service';
declare var jQuery: any;
declare var $: any;

@Component({
    selector: 'app-naos-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    providers: [UserService, StoreService, BagService, LogService, NAOSService]
})
export class DashboardComponent implements OnInit {

    @ViewChild('nextButton', { static: true }) buttonNext: ElementRef;
    @ViewChild('carousel', {static: true}) carousel: any;

    private readonly notifier: NotifierService;

    public idclient: string;
    public scoreSclient: string;
    public scoreFreeClient: any;
    public infoClient: any;
    public productInfoUrl: any;
    public productInfo: any;
    public bag: Array<any>;

    public showPopupOferta: boolean;

    public loader: boolean;
    public loaderModal: boolean;
    public contError: number;

    public intervaloHome: any;

    public urlBanners: string;
    public urlBannersMovil: string;
    public textModal: string;
    public textUrl: string;

    public arrayBannersWeb: Array<any>;
    public arrayBannersMobile: Array<any>;
    public loaderBanner: boolean;
    public arrayBannerAdvServer: Array<any>;
    public advServerLogoHome: any;
    public urlLogoHome: string;

    public homeContentMenu: Array<any>;

    constructor(
        private _notifierService: NotifierService,
        private _router: Router,
        private _userService: UserService,
        private _storeService: StoreService,
        private _bagService: BagService,
        private readonly logService: LogService,
        private _naosService: NAOSService
    ) {
        this.notifier = _notifierService;
        this.scoreSclient = '0';
        this.showPopupOferta = false;
        this.loaderModal = false;
        this.loader = false;
        this.contError = 0;
        this.bag = [];
        this.urlBanners = environment.URLAWSBANNERS;
        this.urlBannersMovil = environment.URLAWSBANNERSMOVIL;
        this.arrayBannersWeb = [];
        this.arrayBannersMobile = [];
        this.arrayBannerAdvServer = [];
        this.homeContentMenu = [];
        this.loaderBanner = true;
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Panel principal',
            uri: '/home'
        });
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getAdvserverPrivate();
        /* this.getBannersImage(); */
        /* this.getScoreClient(); */
        /* this.getProductFree(); */
        this.getDataClient();
        let intervaloLoader = setInterval(() => {
            if (this.contError == 2) {
                this.loader = true;
                clearInterval(intervaloLoader);
                if (this.showPopupOferta) {
                    $('#modalOfertaFree').modal('toggle');
                }
            }
        }, 1000);

        /* this.intervaloHome = setInterval(() => {
            let ielement = document.getElementById('nextButton') as HTMLElement;
            if (ielement !== null) {
                setTimeout(() => {
                    ielement.click();
                }, 1000);
                clearInterval(this.intervaloHome);
            }
        }, 1000); */

    }

    ngOnDestroy(): void {
        clearInterval(this.intervaloHome);
    }

    public clickLinkBanner(): void {
      for (let item of this.arrayBannerAdvServer) {
        if (item.content.clikLink.es == undefined) {
          item.content.clikLink.es = '';
        }
      }
    }

    public goToUrlRara(url: string): void {
      window.open(
        url,
        '_blank'
      );
    }

    /**
     * Funcion para obtener el contenido de advserver de la parte privada
     */
    public getAdvserverPrivate(): void {
      let intervaloAdvServer = setInterval(() => {
        if (sessionStorage.getItem('ADVP')) {
          var response = JSON.parse(this._naosService.desencryptData(sessionStorage.getItem('ADVP')));
          this.arrayBannerAdvServer = this._naosService.getOfferAdvByNameContentPlus('Home-Banner', response);
          this.homeContentMenu = this._naosService.getOfferAdvByNameContentPlus('Home-Modulo', response);
          // Formateamos la url de link por si no viene ninguna
          this.clickLinkBanner();
          // Cargamos el contenido del logo del advserver y en caso de que no exista metemos uno por defecto
          if (this._naosService.getOfferAdvByNameContent('Home-Logo', response) !== null) {
              this.advServerLogoHome = this._naosService.getOfferAdvByNameContent('Home-Logo', response);
              this.urlLogoHome = this.advServerLogoHome.content.images.web.es[0].url
          } else {
              this.urlLogoHome = '../../../assets/images/LogoHome.png';
          }
          this.contError++;
          clearInterval(intervaloAdvServer);
        }
      },1000);


        /* this._naosService.getAdvserverContentPrivate(this.idclient).subscribe(
          response => {
            sessionStorage.setItem('ADVP',this._naosService.encryptData(JSON.stringify(response)));
            this.arrayBannerAdvServer = this._naosService.getOfferAdvByNameContentPlus('Home-Banner', response);
            this.homeContentMenu = this._naosService.getOfferAdvByNameContentPlus('Home-Modulo', response);
            // Formateamos la url de link por si no viene ninguna
            this.clickLinkBanner();
            // Cargamos el contenido del logo del advserver y en caso de que no exista metemos uno por defecto
            if (this._naosService.getOfferAdvByNameContent('Home-Logo', response) !== null) {
                this.advServerLogoHome = this._naosService.getOfferAdvByNameContent('Home-Logo', response);
                this.urlLogoHome = this.advServerLogoHome.content.images.web.es[0].url
            } else {
                this.urlLogoHome = '../../../assets/images/LogoHome.png';
            }
            this.contError++;
          },
          error => {
              console.error('Error ' + JSON.stringify(error));
              this.contError++;
          }
        ); */
    }

    public getBannersImage(): void {
        this._naosService.getBanners().subscribe(
            response => {
                for (let item of response) {
                    for (let banner of item.jbannerInfo) {
                        if (banner.type === 'web') {
                            this.arrayBannersWeb.push(banner);
                        } else if (banner.type === 'mobile') {
                            this.arrayBannersMobile.push(banner);
                        }
                    }
                }
                this.contError++;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.contError++;
            }
        );
    }

    public goToDestinationBanner(url: string): void {
        this._router.navigate([url]);
    }

    /**
     * Funcion para ir a la pantalla de mis compras
     */
    public goToBuy(): void {
        this._router.navigate(['/home/transactions']);
    }

    /**
     * Funcion para ir al contacto de la home
     */
    public goToContactHome(): void {
        this._router.navigate(['/contact']);
    }

    /**
     * Funcion para ir a la pagina de ppvv de naos
     */
    public goToMapPPVV(): void {
      this._router.navigate(['/mapappvv']);
    }

    /**
     * Funcion para redireccionar a la pantalla de perfil
     */
    public goToProfile(): void {
        clearInterval(this.intervaloHome);
        this._router.navigate(['/home/profilepage']);
    }

    /**
     * Funcion para ir al test de piel
     */
    public goToTest(): void {
        clearInterval(this.intervaloHome);
        this._router.navigate(['/home/skintest']);
    }

    /**
     * Funcion para añadir producto a la bolsa de compra
     * @param item producto que se añade a la bolsa de compra
     */
    public addProductToBag(item: any): void {
        this.bag.push(item);
        localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
    }

    /**
     * Funcion para ir al carrito de compra y meter el producto en el carro
     */
    public goToStore(): void {
        clearInterval(this.intervaloHome);
        this.productInfo.jwhat.payments.loyalty_units[0].loyalty_unit_quantity = 0;
        this.addProductToBag(this.productInfo);
        $('#modalOfertaFree').modal('toggle');
        this._router.navigate(['/home/store/bag']);
    }

    /**
     * Funcion para obtener el id del producto por la url que nos llega
     * @param url url con la id del producto
     */
    public getIdProduct(url: string): string {
        let arr = url.split('/');
        return arr[arr.length - 1];
    }

    public goToHome(): void {
        this._router.navigate(['/home']);
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
      /* let intervaloDataClient = setInterval(() => {
        if (sessionStorage.getItem('inclco')) {
          this.infoClient = JSON.parse(sessionStorage.getItem('inclco'));
          this.contError++;
          clearInterval(intervaloDataClient);
        }
      }, 1000); */
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                this.contError++;
            },
            error => {
                this.textModal = 'Tu sesión ha caducado.';
                this.textUrl = '/login';
                $('#exampleModalNotificationLogin').modal('show');
                this.contError++;
            }
        );
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }
}
