<div class="container mw-60 mb-3 w-mobile-100 mw-mobile-100">
    <div class="row justify-content-center text-center blue-text bigger">
        <div class="col-12 col-lg-12">
            <label>Mis compras</label>
            <i style="font-size: 16px !important" [placement]="['right', 'bottom', 'auto']"
                ngbPopover="Acumularás 1 estrella por cada 1€ de compra en un punto de venta adherido a NAOS Club (2 estrellas si es en tu punto de venta origen). Sólo conseguirás estrellas por productos Bioderma o Institut Esthederm, el resto de ticket no cuenta. Recuerda canjear por un Producto Obsequio tus estrellas antes de que pasen 2 años desde que las conseguiste o caducarán."
                class="fas fa-info-circle iconInfo"></i>
        </div>
    </div>
</div>

<div class="container mw-60 mb-3 w-mobile-100 mw-mobile-100 boardSales">
    <div class="row justify-content-around mb-5 px-5 text-center">
        <div class="col-sm-8 col-md-5 col-lg-5 nav-transactions-option selected p-2" (click)="this.clickOpt(1)" id="opttransaction1">
            <label class="my-2 cursor-pointer">Sube tu ticket</label></div>
        <div class="col-sm-8 col-md-5 col-lg-5 nav-transactions-option p-2" (click)="this.clickOpt(2)" id="opttransaction2">
            <label class="my-2 cursor-pointer d-inline-block truncate-text">Histórico de movimientos</label>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-0 text-body-transactions">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<div class="container mw-80 w-mobile-100 mw-mobile-100">
    <!--     <div class="row">
        <div class="col-md-12 filaLogo">
            <img (click)="this.goToHome()" src="../../../assets/images/LOGO-NaosClub@1x.png" />
        </div>
    </div>
    <div class="row rowSubmenu">
        <div class="col-md-6 filaSubMenu">
            Mis compras <i style="font-size: 16px !important" [placement]="['right', 'bottom', 'auto']"
                ngbPopover="Acumularás 1 estrella por cada 1€ de compra (2 estrellas si es en tu punto de venta origen). Sólo conseguirás estrellas por productos Bioderma o Institut Esthederm, el resto de ticket no cuenta. Recuerda usar tus estrellas acumuladas antes de que pasen 2 años desde que las conseguiste o caducarán."
                triggers="mouseenter:mouseleave" class="fas fa-info-circle iconInfo"></i>
        </div>
        <div class="col-md-6">
            <div class="textScore scoreLine">
                <p class="scorep1"><img src="../../../assets/images/Group@2x.png" />&nbsp;&nbsp;Tus estrellas: <span
                        class="scoreClient">{{this.scoreSclient | number:'1.0-3':'de'}}</span></p>
            </div>
        </div>
    </div>
    <div class="row rowSubmenu2">
        <div class="col-md-12">
            <div class="row lineaSubmenu">
                <div (click)="this.clickOpt(1)" class="col-md-6 col-xs-6 col-sm-6 optSubMenu" id="opt1"
                    style="margin-left: -2%">
                    REGISTRO NUEVA COMPRA
                </div>
                <div (click)="this.clickOpt(2)" class="col-md-6 col-xs-6 col-sm-6 optSubMenu" id="opt2"
                    style="margin-left: 2%">
                    HISTÓRICO MIS MOVIMIENTOS
                </div>
            </div>
        </div>
    </div> -->
</div>
