import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { NAOSService } from 'src/app/services/naos.service';
import { UserService } from 'src/app/services/user.service';
import { LogService } from '@dagonmetric/ng-log';
declare var $: any;

@Component({
    selector: 'app-detalleproducto',
    templateUrl: './detalle.component.html',
    styleUrls: ['./detalle.component.css'],
    providers: [StoreService, NAOSService, UserService, LogService]
})
export class DetalleComponent implements OnInit {

    public idproduct: any;
    public productDetail: any;
    public bag: Array<any>;

    public loader: boolean;
    public sub: any;

    public textModal: string;
    public textUrl: string;
    public idclient: any;

    public scoreBag: any;
    public scoreClient: any;

    constructor(
        private _routeActivated: ActivatedRoute,
        private _storeService: StoreService,
        public _naosService: NAOSService,
        private _router: Router,
        private _userService: UserService,
        private readonly logService: LogService
    ) {
        this.bag = [];
        this.loader = false;
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Detalle producto',
            uri: '/home/store/detail'
        });
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        this.sub = this._routeActivated.paramMap.subscribe((params: ParamMap) => {
            this.idproduct = +params.get('id');
        });

        this.getDetailProduct();

        if (localStorage.getItem('BC' + this.idclient)) {
            this.bag = JSON.parse(localStorage.getItem('BC' + this.idclient));
        } else {
            localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
        }
    }

    public getDetailProduct(): void {
        this._storeService.getDetailProduct(this.idproduct).subscribe(
            response => {
                this.productDetail = response;
                this.getScoreClient();
                this.loader = true;
            },
            () => {
                this.textModal = 'No ha sido posible realizar la operación. Puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com.';
                this.textUrl = null;
                $('#exampleModalNotificationDetalle').modal('show');
            }
        );
    }

    /**
     * Funcion para obtener las estrellas del cliente
     */
    public getScoreClient(): void {
        this._userService.getScoreClient(this.idclient).subscribe(
            response => {
                this.scoreClient = response[0].available;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Funcion para obtener las estrellas que llevamos en la bolsa
     */
    public getScoreBagClient(): number {
        let auxCont: number = 0;
        for (let item of this.bag) {
            auxCont = auxCont + parseInt(item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity);
        }
        return auxCont;
    }

    /**
     * Funcion para controlar que los productos que metemos en la bolsa de compra no supera el numero de estrellas del cliente
     */
    public checkScoreProductsClient(item: any): boolean {
        let permiso: boolean = false;
        this.scoreBag = this.getScoreBagClient();
        let auxBag = parseInt(this.scoreBag) + parseInt(item.jwhat.payments.loyalty_units[0].loyalty_unit_quantity);
        if (auxBag <= this.scoreClient) {
            permiso = true;
        } else {
            permiso = false;
        }
        return permiso;
    }

    /**
     * Funcion para añadir producto a la bolsa de compra
     * @param item producto que se añade a la bolsa de compra
     */
    public addProductToBag(itemProduct: any): void {
        let exist: boolean = false;
            if (this.bag.length > 0) {
                if (this.bag[0].classification[0] == itemProduct.classification[0]) {
                    if (this.bag[0].jwhat.delivery_type == itemProduct.jwhat.delivery_type) {
                    if (this.checkScoreProductsClient(itemProduct)) {
                        itemProduct.destiny = 'detalle';
                        this.bag.push(itemProduct);
                        localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                        this.textModal = 'Producto añadido a la cesta.';
                        this.textUrl = null;
                        $('#exampleModalNotificationDetalle').modal('show');
                    } else {
                        this.textModal = 'Lo sentimos, saldo insuficiente.';
                        this.textUrl = null;
                        $('#exampleModalNotificationDetalle').modal('show');
                    }
                } else {
                    this.textModal = 'Lo sentimos, debes hacer dos pedidos independientes.';
                    this.textUrl = null;
                    $('#exampleModalNotificationCatalogo').modal('show');
                }
                } else {
                    this.textModal = 'Lo sentimos, no es posible hacer un pedido con productos de diferentes marcas. Puedes hacer dos pedidos independientes.';
                    this.textUrl = null;
                    $('#exampleModalNotificationDetalle').modal('show');
                }
            } else {
                if (this.checkScoreProductsClient(itemProduct)) {
                    itemProduct.destiny = 'detalle';
                    this.bag.push(itemProduct);
                    localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
                    this.textModal = 'Producto añadido a la cesta.';
                    this.textUrl = null;
                    $('#exampleModalNotificationDetalle').modal('show');
                } else {
                    this.textModal = 'Lo sentimos, saldo insuficiente.';
                    this.textUrl = null;
                    $('#exampleModalNotificationDetalle').modal('show');
                }
            }
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }
}
