import { Component, OnInit, HostListener } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-footer-esthederm',
  templateUrl: './footer-esthederm.component.html',
  styleUrls: ['./footer-esthederm.component.css']
})
export class FooterEsthedermComponent implements OnInit {

  public getScreenWidth;
  public areaexpaded1: boolean;
  public areaexpaded2: boolean;
  public areaexpaded3: boolean;
  public areaexpaded4: boolean;
  public areaexpaded5: boolean;
  public areaexpaded6: boolean;

  constructor() {
    this.getScreenWidth = window.innerWidth;
    this.areaexpaded1 = false;
    this.areaexpaded2 = false;
    this.areaexpaded3 = false;
    this.areaexpaded4 = false;
    this.areaexpaded5 = false;
    this.areaexpaded6 = false;
  }

  ngOnInit(): void {

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth < 770) {
      $('#collapseExample1').removeClass('show');
      $('#collapseExample2').removeClass('show');
      $('#collapseExample3').removeClass('show');
      $('#collapseExample4').removeClass('show');
      $('#collapseExample5').removeClass('show');
      $('#collapseExample6').removeClass('show');
      this.areaexpaded1 = true;
      this.areaexpaded2 = true;
      this.areaexpaded3 = true;
      this.areaexpaded4 = true;
      this.areaexpaded5 = true;
      this.areaexpaded6 = true;
    } else if (this.getScreenWidth > 770) {
      $('#collapseExample1').addClass('show');
      $('#collapseExample2').addClass('show');
      $('#collapseExample3').addClass('show');
      $('#collapseExample4').addClass('show');
      $('#collapseExample5').addClass('show');
      $('#collapseExample6').addClass('show');
      this.areaexpaded1 = false;
      this.areaexpaded2 = false;
      this.areaexpaded3 = false;
      this.areaexpaded4 = false;
      this.areaexpaded5 = false;
      this.areaexpaded6 = false;
    }
  }
}
