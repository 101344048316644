<!-- Footer Component -->
<div *ngIf="this.loader" class="container mw-90 w-mobile-100 mw-mobile-100">
    <div class="row footericonspadding">
        <div class="col-md-6 col-sm-12 mt-4 mb-2 footer-sales">
            <label class="blue-text bigger cursor-pointer" (click)="this.goToMapPPVV()">
                <i class="fas fa-map-marker-alt icons-footer"></i>
                Puntos de venta
            </label>
        </div>
        <div class="col-md-6 col-sm-12 mt-4 mb-2 footer-contact">
            <label class="blue-text bigger cursor-pointer" (click)="this.goToContactHome()">
                <i class="fa fa-envelope icons-footer" aria-hidden="true"></i>
                Contáctanos
            </label>
        </div>

    </div>
</div>
<!-- Footer Component -->
