<!-- Loader icon spinner -->
<ng-container *ngIf="!this.loader">
    <div class="row loader">
        <div class="col-12 text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
</ng-container>

<div *ngIf="this.loader" class="container mw-90 w-mobile-100 mw-mobile-100">
    <div class="row text-center align-self-center justify-content-center mx-4 mb-4" *ngFor="let optMenu of this.arrOptionsMenu;let i = index">
        <div class="card card-menu cursor-hand" (click)="this.goToPage(optMenu.path)">
            <div [ngClass]="{'disablecard': optMenu.disabled === true}">
                <div class="row no-gutters">
                    <div class="col-md-5">
                        <img class="card-img card-img-menu h-100 cursor-hand cad-img-tablet"[src]="optMenu.urlimage">
                    </div>
                    <div class="col-md-7">
                        <div class="card-body cursor-hand">
                            <label class="title-card px-4 font-weight-bold cursor-pointer" [innerHTML]="optMenu.name"></label>
                            <hr>
                            <label class="text-body-card pt-2 px-3 cursor-pointer" [innerHTML]="optMenu.description"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
