import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataDirectionComponent } from './datadirection.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

@NgModule({
    declarations: [DataDirectionComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        AutocompleteLibModule,
        NaosNotificationModule
    ],
    exports: [DataDirectionComponent],
    providers: [],
})
export class DataDirectionModule {

}
