import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivationComponent } from './activation.component';
import { LoginModule } from '../login/login.module';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

@NgModule({
    declarations: [ActivationComponent],
    imports: [
        CommonModule,
        LoginModule,
        NaosNotificationModule
    ],
    exports: [ActivationComponent],
    providers: [],
})
export class ActivationModule {}
