import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-naos-cardmenu',
    templateUrl: './cardmenu.component.html',
    styleUrls: ['./cardmenu.component.css']
})
export class CardMenuComponent implements OnInit {

    @Input() nameOpt: string;
    @Input() urlImage: string;
    @Input() iconPath: string;
    @Input() path: string;
    @Input() index: number;
    public offerFree: string;
    public showOffer: boolean;

    public loader: boolean;

    constructor(
        public _router: Router
    ) {
        this.showOffer = false;
        this.loader = false;
    }

    ngOnInit(): void {
        if (localStorage.getItem('offerFree')) {
            this.offerFree = localStorage.getItem('offerFree');
            if (this.offerFree == 'true') {
                this.showOffer = true;
            }
            this.loader = true;
        } else {
            this.showOffer = false;
        }
    }

    public goToPage(page) {
        this._router.navigate([page]);
    }

    public bloquedZoneClic(): void {
        $('#bloquedZone').modal('show');
    }
}
