import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';

@Injectable()
export class StoreService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(private _http: HttpClient) {
        this.url = environment.urlAPI;
    }

    /**
     * Funcion para obtener los productos del catalogo
     */
    public getProductos(): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETPRODUCTS;
        let params: HttpParams = new HttpParams();
        params = params.append('rewardType', 'JREWARDS_PF');
        return this._http.get( this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los productos del catalogo por cliente
     */
    public getProductsByClient(idclient: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETPRODUCTSCLIENT + idclient;
        return this._http.get( this._apiUrl);
    }

    /**
     * Funcion para obtener el detalle del producto
     * @param idproduct de la vista detalle
     */
    public getDetailProduct(idproduct: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETPRODUCTDETAIL + idproduct;
        return this._http.get(this._apiUrl);
    }

    /**
     * Funcion para obtener los ids de las marcas según entorno
     */
    public getMarcasIds(): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETBRANDSIDS;
        return this._http.get(this._apiUrl);
    }

}
