<div>
    <div class="row">
        <div class="col-md-12">
            <input class="inputfalse" (click)="this.showHideDropdown()" [(ngModel)]="this.stringName" type="text"/>
        </div>
    </div>
    <div class="row" *ngIf="this.showDrop">
        <div class="col-md-12">
            <ul id="top-list">
                <ng-container *ngIf="this.type == undefined">
                    <ng-container *ngFor="let item of this.sites">
                        <li class="site" (click)="this.setSelectedSite(item.where_id, item.fullName)">{{item.fullName}}</li>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="this.type == 'pharmacy'">
                    <ng-container *ngFor="let item of this.sitesPharmacy">
                        <li class="site" (click)="this.setSelectedSite(item.where_id, item.fullName)">{{item.fullName}}</li>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="this.type == 'esthetic'">
                    <ng-container *ngFor="let item of this.sitesEsthetic">
                        <li class="site" (click)="this.setSelectedSite(item.where_id, item.fullName)">{{item.fullName}}</li>
                    </ng-container>
                </ng-container>
                
                
            </ul>
            <div class="row">
                <div class="col-md-6">
                    <button *ngIf="this.type == undefined" class="btn btnPageable btnPageabeMinus" (click)="this.setPageMinus()"><i class="fa fa-minus" aria-hidden="true"></i></button>
                    <button *ngIf="this.type == 'pharmacy'" class="btn btnPageable btnPageabeMinus" (click)="this.setPageMinusPharmacy()"><i class="fa fa-minus" aria-hidden="true"></i></button>
                    <button *ngIf="this.type == 'esthetic'" class="btn btnPageable btnPageabeMinus" (click)="this.setPageMinusEsthetic()"><i class="fa fa-minus" aria-hidden="true"></i></button>
                </div>
                <div class="col-md-6">
                    <button *ngIf="this.type == undefined" class="btn btnPageable" (click)="this.setPagePlus()"><i class="fa fa-plus" aria-hidden="true"></i></button>
                    <button *ngIf="this.type == 'pharmacy'" class="btn btnPageable" (click)="this.setPagePlusPharmacy()"><i class="fa fa-plus" aria-hidden="true"></i></button>
                    <button *ngIf="this.type == 'esthetic'" class="btn btnPageable" (click)="this.setPagePlusPEsthetic()"><i class="fa fa-plus" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>