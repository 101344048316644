import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  constructor(private http: HttpClient) { }

  loadLangFile(lang: string): Observable<any> {
    /* return this.http.get(`assets/tarteaucitron/lang/tarteaucitron.${lang}.json`); */
    return this.http.get(`assets/tarteaucitron/lang/tarteaucitron.es.json`);
  }
}