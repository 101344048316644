<div class="container mw-80 w-mobile-100 mw-mobile-100" *ngIf="!this.loader">
    <div class="row loader">
        <div class="col-md-12">
            <img src="../../../assets/images/loader.gif" [alt]="this._naosService.setBrandToTextAlt('Loader')" />
        </div>
    </div>
</div>

<div *ngIf="this.loader" [style.background-image]="'url(' + this.urlImagenMain + ')'" class="container main-header mw-80 hidden-mobile text-center" alt="Imagen cabera {{this.brandPage}}">
    <div class="row justify-content-center align-items-center h-100">
        <div class="col-lg-7 col-md-10">
            <div class="bg-white w-100 text-center divlogo"> <img class="py-4 px-2 w-75 h-auto" [alt]="this._naosService.setBrandToTextAlt('Logo cabecera')" src="../../../assets/images/LOGO-NaosClub@2x.png" /></div>
        </div>
    </div>
</div>

<div *ngIf="this.loader" [style.background-image]="'url(' + this.urlImagenMain + ')'" class="container main-header mw-100 hidden-desktop hidden-tablet ">
    <div class="row justify-content-center align-items-center h-100">
        <div class="col-lg-7 col-md-10">
            <div class="bg-white w-100 text-center imagenlogo"> <img [alt]="this._naosService.setBrandToTextAlt('Logo cabecera')" class=" py-4 px-2 w-75 h-auto" src="../../../assets/images/LOGO-NaosClub@2x.png" /></div>
        </div>
    </div>
</div>

<div *ngIf="this.loader" class="container mw-80 w-mobile-100 mw-mobile-100">
    <div class="row justify-content-center my-md-5 my-3">
        <div class="col-12 text-center"><label class="font-weight-bold main-text">Consigue regalos y ventajas exclusivas
                cuidando tu piel.</label></div>
    </div>

    

    <div class="row justify-content-center mb-md-5 mb-3">
      <div class="col-12 col-lg-6 text-center">
            <span class="main-enter-button w-100 py-3 cursor-pointer d-inline-block" (click)="this.naosIdentificationCookie('up')">Inicia sesión | Regístrate ahora
            </span>
        </div>
    </div>
    <div class="row justify-content-center mt-4 mb-5">
        <div class="col-12 col-lg-8 text-center">
            <video class="border w-100" controls>
                <source src="../../../assets/video/NAOS_CLUB_COMSUMIDORES_WEB.mp4" type="video/mp4">
                <source src="mov_bbb.ogg" type="video/ogg">
                Your browser does not support HTML5 video.
            </video>
        </div>
    </div>
    <div class="row justify-content-center mb-2">
        <div class="col-12 text-center"><label class="font-weight-bold main-text">¿POR QUÉ UNIRSE A NAOS CLUB?</label>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 text-center"><label class="font-weight-bold main-text"> <i
                    class="fas fa-arrow-down"></i></label></div>
    </div>
</div>
<div *ngIf="this.loader" class="container w-100 mb-3 mb-md-5">
    <div class="row justify-content-center ">
        <div class="col-md-12 col-lg-auto order-lg-1 text-center"><label class="main-section-number">1</label></div>
        <div class="col-md-12 col-lg-auto order-lg-3 text-center align-self-center mb-2" [innerHTML]="this.getTitleModuleByName('Imagen Productos Gratis Landing NAOS Club')">
          <label class="main-section-text">PRODUCTOS</label><br>
          <label class="main-section-text yellow font-weight-bold">GRATIS</label>
        </div>
        <div class="col-10 col-md-8 col-lg-4 order-lg-2 text-center"><img class="w-75 h-auto" [alt]="this.getAltImageByName('Imagen Productos Gratis Landing NAOS Club')" [src]="this.getImageModuleByName('Imagen Productos Gratis Landing NAOS Club')"/></div>

    </div>
    <div class="row justify-content-center mt-lg-5 mt-md-2">
        <div class="col-6 col-md-6 col-lg-2 ">
            <hr class="main-section-line"/>
        </div>
    </div>
</div>
<div *ngIf="this.loader" class="container w-100 mb-3 mb-md-5">
    <div class="row justify-content-center ">
        <div class="col-md-12 col-lg-auto order-lg-3 text-center"><label class="main-section-number">2</label></div>
        <div class="col-md-12 col-lg-auto order-lg-1 text-center align-self-center mb-2" [innerHTML]="this.getTitleModuleByName('Imagen Promociones Exclusivas Landing NAOS Club')">
          <label class="main-section-text">PROMOCIONES</label><br>
          <label class="main-section-text yellow font-weight-bold">EXCLUSIVAS</label>
        </div>
        <div class="col-10 col-md-8 col-lg-4 order-lg-2 text-center"><img class="w-75 h-auto" [alt]="this.getAltImageByName('Imagen Promociones Exclusivas Landing NAOS Club')" [src]="this.getImageModuleByName('Imagen Promociones Exclusivas Landing NAOS Club')" /></div>

    </div>
    <div class="row justify-content-center mt-lg-5 mt-md-2">
        <div class="col-6 col-md-6 col-lg-2 ">
            <hr class="main-section-line" />
        </div>
    </div>
</div>
<div *ngIf="this.loader" class="container w-100 mb-3 mb-md-5">
    <div class="row justify-content-center ">
        <div class="col-md-12 col-lg-auto order-lg-1 text-center"><label class="main-section-number">3</label></div>
        <div class="col-md-12 col-lg-auto order-lg-3 text-center align-self-center mb-2" [innerHTML]="this.getTitleModuleByName('Imagen Invitación a Eventos Landing NAOS Club')">
          <label class="main-section-text">INVITACIÓN A</label><br>
          <label class="main-section-text yellow font-weight-bold">EVENTOS</label>
        </div>
        <div class="col-10 col-md-8 col-lg-4 order-lg-2 text-center"><img class="w-75 h-auto" [alt]="this.getAltImageByName('Imagen Invitación a Eventos Landing NAOS Club')" [src]="this.getImageModuleByName('Imagen Invitación a Eventos Landing NAOS Club')" /></div>

    </div>
    <div class="row justify-content-center mt-lg-5 mt-md-2">
        <div class="col-6 col-md-6 col-lg-2 ">
            <hr class="main-section-line" />
        </div>
    </div>
</div>
<div *ngIf="this.loader" class="container w-100 mb-3 mb-md-5">
    <div class="row justify-content-center">
        <div class="col-md-12 col-lg-auto order-lg-3 text-center"><label class="main-section-number">4</label></div>
        <div class="col-md-12 col-lg-auto order-lg-1 text-center align-self-center mb-2" [innerHTML]="this.getTitleModuleByName('Imagen Diagnóstico de Piel Landing NAOS Club')">
          <label class="main-section-text">DIAGNÓSTICO DE PIEL</label><br>
          <label class="main-section-text">& RUTINA&nbsp;</label>
          <label class="main-section-text yellow font-weight-bold">PERSONALIZADA</label>
        </div>
        <div class="col-10 col-md-8 col-lg-4 order-lg-2 text-center"><img class="w-75 h-auto" [alt]="this.getAltImageByName('Imagen Diagnóstico de Piel Landing NAOS Club')" [src]="this.getImageModuleByName('Imagen Diagnóstico de Piel Landing NAOS Club')" /></div>

    </div>




    <div class="row justify-content-center mt-lg-5 mt-md-2">
        <div class="col-6 col-md-6 col-lg-2 ">
            <hr class="main-section-line" />
        </div>
    </div>
</div>
<div *ngIf="this.loader" class="container w-100 mb-3 mb-md-5">
    <div class="row justify-content-center">
        <div class="col-md-12 col-lg-auto order-lg-3 text-center">
            <a class="linkppvvnaos" (click)="this.goToPPVVNaos()">
                <h2><b>Puntos de venta NAOS Club</b>&nbsp;<i class="fas fa-map-marker-alt"></i></h2>
            </a>
        </div>
    </div>
    <div class="row justify-content-center mt-lg-5 mt-md-2">
        <div class="col-6 col-md-6 col-lg-2 ">
            <hr class="main-section-line" />
        </div>
    </div>
</div>


<div *ngIf="this.loader" class="container mw-80 w-mobile-100 mw-mobile-100">
    <div class="row justify-content-center mb-4">
        <div class="col-12 text-center"><label class="font-weight-bold main-text">¿CÓMO FUNCIONA? *</label>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-4 col-lg-auto mx-lg-2 my-2 text-center main-mini-section">
            <img src="../../../assets/images/datedealta.png" class="mb-1" /><br>
            <label>Date de alta en<br> <b>NAOS Club</b></label>
        </div>
        <div class="col-md-4 col-lg-auto mx-lg-2 my-2 text-center main-mini-section"> <img [alt]="this._naosService.setBrandToTextAlt('Primer paso')" src="../../../assets/images/subir.png" class="mb-1" /><br>
            <label class="">Sube el <b>ticket<br> de compra</b></label></div>
        <div class="col-md-4 col-lg-auto mx-lg-2 my-2 text-center main-mini-section"> <img [alt]="this._naosService.setBrandToTextAlt('Segundo paso')" src="../../../assets/images/acumulaestrellas.png" class="mb-1" /><br>
            <label>Acumula estrellas<br> <b>1€ = 1 <img [alt]="this._naosService.setBrandToTextAlt('Tercer paso')" class="imgestrella" src="../../../assets/images/Group.png"/></b></label></div>
        <div class="col-md-4 col-lg-auto mx-lg-2 my-2 text-center main-mini-section"> <img [alt]="this._naosService.setBrandToTextAlt('Cuarto paso')" src="../../../assets/images/QR@2x.png" class="mb-1" /><br>
            <label><b>Canjea</b> tus estrellas<br>por producto y recibe<br>un <b>código QR</b></label></div>
        <div class="col-md-4 col-lg-auto mx-lg-2 my-2 text-center main-mini-section"> <img [alt]="this._naosService.setBrandToTextAlt('Quinto paso')" src="../../../assets/images/validatucodigo@2x.png" class="mb-1" /><br>
            <label><b>Valida</b> tu <b>código QR</b><br> en la farmacia</label></div>
        <div class="col-md-4 col-lg-auto mx-lg-2 my-2 text-center main-mini-section"> <img [alt]="this._naosService.setBrandToTextAlt('Sexto paso')" src="../../../assets/images/envioacasa@2x.png" class="mb-1" /><br>
            <label>Si no tienes una<br> farmacia cerca, te<br> <b>enviamos</b> el producto<br> a tu <b>casa</b></label>
        </div>
    </div>
    <div class="row justify-content-center mt-5 mb-5">
        <div class="col-12 text-center">
            <span class="textCanarias">*NAOS Club en Canarias solo contempla la marca Bioderma, no se validarán los tickets de compra de los usuarios de Institut Esthederm. Asimismo, los consumidores de Canarias sólo podrán canjear en NAOS Club los productos de Bioderma y recogerlos físicamente en su farmacia (sin envío a domicilio).</span>
        </div>
    </div>
    <!-- <div class="row justify-content-center mt-4">
        <div class="col-12 col-lg-8 text-center">
            <video class="border w-100" controls>
                <source src="../../../assets/video/NAOS_CLUB_COMSUMIDORES_WEB.mp4" type="video/mp4">
                <source src="mov_bbb.ogg" type="video/ogg">
                Your browser does not support HTML5 video.
            </video>
        </div>
    </div> -->
</div>
<div *ngIf="this.loader" class="container mw-80 w-mobile-100 mw-mobile-100">
    <div class="row justify-content-center my-5">
        <div class="col-12 col-lg-6 text-center">
            <span class="main-enter-button w-100 py-3 cursor-pointer d-inline-block" (click)="this.naosIdentificationCookie('down')">Inicia sesión | Regístrate ahora
            </span>
        </div>
    </div>
</div>

<div class="registrofijo">
<div class="row text-center mb-2">
  <div class="col-12">
    <button class="btn btnRegistroAmarillo" (click)="this.goToRegister()">Unirme al club</button>
  </div>
</div>
<div class="row text-center mb-2">
  <div class="col-12">
    ¿Ya eres del club?
  </div>
</div>
<div class="row text-center mb-2">
    <div class="col-12">
      <a class="cursorpointer" (click)="this.goToLogin()">Inicia sesión</a>
    </div>
  </div>
</div>
