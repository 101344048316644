import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NotifierService } from 'angular-notifier';
import { StoreService } from 'src/app/services/store.service';
import { BagService } from 'src/app/services/bag.service';
import { environment } from 'src/environments/environment';
import { LogService } from '@dagonmetric/ng-log';
import { NAOSService } from 'src/app/services/naos.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [UserService, StoreService, BagService, LogService, NAOSService]
})
export class NavbarComponent implements OnInit {
    @ViewChild('nextButton', { static: true }) buttonNext: ElementRef;
    @ViewChild('carousel', {static: true}) carousel: any;

    private readonly notifier: NotifierService;

    public idclient: string;
    public scoreSclient: string;
    public scoreSclientEuro: any;
    public scoreClientCaducidad: string;
    public scoreClientCaducidadEuro: any;
    public scoreFreeClient: any;
    public infoClient: any;
    public productInfoUrl: any;
    public productInfo: any;
    public bag: Array<any>;

    public showPopupOferta: boolean;

    public loader: boolean;
    public loaderModal: boolean;
    public contError: number;

    public intervaloHome: any;
    public intervaloRefreshName: any;
    public intervaloRefreshScore: any;

    public urlBanners: string;
    public urlBannersMovil: string;
    public textModal: string;
    public textUrl: string;

    public arrayBannersWeb: Array<any>;
    public arrayBannersMobile: Array<any>;
    public loaderBanner: boolean;
    public arrayBannerAdvServer: Array<any>;
    public advServerLogoHome: any;
    public urlLogoHome: string;

    public homeContentMenu: Array<any>;
    public arrayScoreExpired: Array<any>;
    public showMessageScoreExpired: boolean;
    public subscriber: Subscription;

    constructor(
        private _notifierService: NotifierService,
        private _router: Router,
        private _userService: UserService,
        private _storeService: StoreService,
        private _bagService: BagService,
        private readonly logService: LogService,
        private _naosService: NAOSService
    ) {

        _router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                // Inicilizamos el menu cada vez que se carga la home
                console.log(this._router.url)
                    if (this._router.url.indexOf('clubperks') > -1) {
                        console.log('Entramos ventajas');
                        $('#optmenu1').addClass('selected');
                        $('#optmenu2').removeClass('selected');
                        $('#optmenu3').removeClass('selected');
                    } else if (this._router.url == '/home/transactions') {
                        console.log('Entramos transactions');
                        $('#optmenu1').removeClass('selected');
                        $('#optmenu2').addClass('selected');
                        $('#optmenu3').removeClass('selected');
                    } else if (this._router.url.indexOf('landingpromotions') > -1) {
                        console.log('Entramos canjea');
                        $('#optmenu1').removeClass('selected');
                        $('#optmenu2').removeClass('selected');
                        $('#optmenu3').addClass('selected');
                    } else {
                        console.log('Entramos otro');
                        $('#optmenu1').removeClass('selected');
                        $('#optmenu2').removeClass('selected');
                        $('#optmenu3').removeClass('selected');
                    }

            }
        });

        this.notifier = _notifierService;
        this.scoreSclient = '0';
        this.scoreClientCaducidad = '0';
        this.showPopupOferta = false;
        this.loaderModal = false;
        this.loader = false;
        this.contError = 0;
        this.showMessageScoreExpired = false;
        this.bag = [];
        this.urlBanners = environment.URLAWSBANNERS;
        this.urlBannersMovil = environment.URLAWSBANNERSMOVIL;
        this.arrayBannersWeb = [];
        this.arrayBannersMobile = [];
        this.arrayBannerAdvServer = [];
        this.homeContentMenu = [];
        this.loaderBanner = true;
        this.arrayScoreExpired = [];
    }

    ngOnInit(): void {
        // Inicilizamos el menu cada vez que se carga la home
        
        setTimeout(() => {
            if (this._router.url.indexOf('clubperks') > -1) {
                $('#optmenu1').addClass('selected');
                $('#optmenu2').removeClass('selected');
                $('#optmenu3').removeClass('selected');
            } else if (this._router.url.indexOf('/transactions ') > -1) {
                $('#optmenu1').removeClass('selected');
                $('#optmenu2').addClass('selected');
                $('#optmenu3').removeClass('selected');
            } else if (this._router.url.indexOf('landingpromotions') > -1) {
                $('#optmenu1').removeClass('selected');
                $('#optmenu2').removeClass('selected');
                $('#optmenu3').addClass('selected');
            } else {
                $('#optmenu1').removeClass('selected');
                $('#optmenu2').removeClass('selected');
                $('#optmenu3').removeClass('selected');
            }
        }, 2500);
       

       /*  this.subscriber = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event) => {
             if (event['url'] == '/home') {
                $('#optmenu1').removeClass('selected');
                $('#optmenu2').removeClass('selected');
                $('#optmenu3').removeClass('selected');
             }
          }); */

        

        window.parent.postMessage('HelloParentFrame!', '*');
        this.logService.trackPageView({
            name: 'Panel principal',
            uri: '/home'
        });
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getAdvserverPrivate();
        this.getDataClient();
        this.getScoreClient();
        this.getExpirationScore();
        let intervaloLoader = setInterval(() => {
            if (this.contError == 4) {
                this.loader = true;
                clearInterval(intervaloLoader);
                if (this.showPopupOferta) {
                    $('#modalOfertaFree').modal('toggle');
                }
            }
        }, 1000);

        this.intervaloRefreshName = setInterval(() => {
            if (localStorage.getItem('refreshname')) {
            this.getDataClient();
            localStorage.removeItem('refreshname');
            }
        }, 1000);

        this.intervaloHome = setInterval(() => {
            let ielement = document.getElementById('nextButton') as HTMLElement;
            if (ielement !== null) {
                setTimeout(() => {
                    ielement.click();
                }, 1000);
                clearInterval(this.intervaloHome);
            }
        }, 1000);

    }

    ngOnDestroy(): void {
        this.subscriber?.unsubscribe();
        clearInterval(this.intervaloHome);
        clearInterval(this.intervaloRefreshName);
        clearInterval(this.intervaloRefreshScore);
    }

    /**
     * Metodo para convertir los puntos del usuario a euros
     * @param scoreClient Puntos del usuario
     */
    public getRatioScoreClient(scoreClient: any): any {
        var ratioComplet: any;
        this._naosService.getRatioNaos().subscribe(
            response => {
                ratioComplet = response.ratio;
                var indice = ratioComplet.indexOf('/');
                var ratio = ratioComplet.substring(indice + 1, ratioComplet.length);
                sessionStorage.setItem('TAR', ratio)
                var calc: number = scoreClient / parseInt(ratio)
                this.scoreSclientEuro = Math.trunc(calc);
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    public getRatioScoreClientCaducidad(scoreClientCaducidad: any): any {
        var ratio = sessionStorage.getItem('TAR');
        return parseInt(scoreClientCaducidad) / parseInt(ratio);
    }

     /**
     * Funcion para obtener las estrellas del cliente
     */
    public getScoreClient(): void {
        this._userService.getScoreClient(this.idclient).subscribe(
            response => {
                this.scoreSclient = response[0].available;
                this.getRatioScoreClient(this.scoreSclient);
                /* sessionStorage.setItem('scs', this.scoreSclient); */
                this.contError++;
            },
            error => {
                this.contError++;
            }
        );
    }

    /**
     * Funcion para obtener las estrellas caducadas del cliente
     */
    public getExpirationScore(): void {
      this._userService.getExpirationScoreClient(this.idclient).subscribe(
        response => {
          let todayFormatMs: any = this._naosService.toMsDDMMYYYY(this._naosService.getTodayDDMMYYYY());
          let todayNextMonthMs: any = this._naosService.toMsDDMMYYYY(this._naosService.getTodayNextMonthDDMMYYYY());
          for (let item2 of response) {
            if (this._naosService.toMsYYYYMMDD(item2.date) <= todayNextMonthMs && this._naosService.toMsYYYYMMDD(item2.date) >= todayFormatMs) {
              this.arrayScoreExpired.push(item2);
            }
          }
          // En el caso de que se devuelvan más de tres elementos nos quedamos solo con los tres más proximos
          if (this.arrayScoreExpired.length > 3) {
            this.showMessageScoreExpired = true;
            this.arrayScoreExpired = this.arrayScoreExpired.sort((a, b) => a.date - b.date).slice(0, 3);
          }
          let total: number = 0;
          for (let item of this.arrayScoreExpired) {
            total += parseInt(item.quantity);
          }
          this.scoreClientCaducidad = total.toString();
          setTimeout(() => {
            this.scoreClientCaducidadEuro = Math.trunc(this.getRatioScoreClientCaducidad(this.scoreClientCaducidad));
          },1000);
          
          this.contError++;
        },
        error => {
          console.error('Error ' + JSON.stringify(error));
          this.contError++;
        }
      );
    }

    public clickLinkBanner(): void {
      for (let item of this.arrayBannerAdvServer) {
        if (item.content.clikLink.es == undefined) {
          item.content.clikLink.es = '';
        }
      }
    }

    public goToUrlRara(url: string): void {
      window.open(
        url,
        '_blank'
      );
    }

    /**
     * Funcion para obtener el contenido de advserver de la parte privada
     */
    public getAdvserverPrivate(): void {
        this._naosService.getAdvserverContentPrivate(this.idclient).subscribe(
          response => {
            sessionStorage.setItem('ADVP',this._naosService.encryptData(JSON.stringify(response)));
            this.arrayBannerAdvServer = this._naosService.getOfferAdvByNameContentPlus('Home-Banner', response);
            this.homeContentMenu = this._naosService.getOfferAdvByNameContentPlus('Home-Modulo', response);
            // Formateamos la url de link por si no viene ninguna
            this.clickLinkBanner();
            // Cargamos el contenido del logo del advserver y en caso de que no exista metemos uno por defecto
            if (this._naosService.getOfferAdvByNameContent('Home-Logo', response) !== null) {
                this.advServerLogoHome = this._naosService.getOfferAdvByNameContent('Home-Logo', response);
                this.urlLogoHome = this.advServerLogoHome.content.images.web.es[0].url
            } else {
                this.urlLogoHome = '../../../assets/images/LogoHome.png';
            }
            this.contError++;
          },
          error => {
              console.error('Error ' + JSON.stringify(error));
              this.contError++;
          }
        );
    }

    public getBannersImage(): void {
        this._naosService.getBanners().subscribe(
            response => {
                for (let item of response) {
                    for (let banner of item.jbannerInfo) {
                        if (banner.type === 'web') {
                            this.arrayBannersWeb.push(banner);
                        } else if (banner.type === 'mobile') {
                            this.arrayBannersMobile.push(banner);
                        }
                    }
                }
                this.contError++;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.contError++;
            }
        );
    }

    public goToDestinationBanner(url: string): void {
        this._router.navigate([url]);
    }

    public goToPromotions(): void {
      this._router.navigate(['/home/landingpromotions'], { queryParams: { promotion: '202112NAOS' } });
    }

    /**
     * Funcion para ir a la pantalla de mis compras
     */
    public goToBuy(): void {
        this._router.navigate(['/home/transactions']);
    }

    /**
     * Funcion para ir al contacto de la home
     */
    public goToContactHome(): void {
        this._router.navigate(['/contact']);
    }

    /**
     * Funcion para ir a la pagina de ppvv de naos
     */
    public goToMapPPVV(): void {
      this._router.navigate(['/mapappvv']);
    }

    /**
     * Funcion para redireccionar a la pantalla de perfil
     */
    public goToProfile(): void {
        clearInterval(this.intervaloHome);
        this._router.navigate(['/home/profilepage']);
    }

    /**
     * Funcion para ir al test de piel
     */
    public goToTest(): void {
        clearInterval(this.intervaloHome);
        this._router.navigate(['/home/skintest']);
    }

    /**
     * Funcion para ir al histórico
     */
    public goToHistory(): void {
      clearInterval(this.intervaloHome);
      this._router.navigate(['/home/transactions/history']);
    }

    /**
     * Funcion para añadir producto a la bolsa de compra
     * @param item producto que se añade a la bolsa de compra
     */
    public addProductToBag(item: any): void {
        this.bag.push(item);
        localStorage.setItem('BC' + this.idclient, JSON.stringify(this.bag));
    }

    public goToFaqs(): void {
      localStorage.setItem('faqs','1');
    }

    /**
     * Funcion para ir al carrito de compra y meter el producto en el carro
     */
    public goToStore(): void {
        clearInterval(this.intervaloHome);
        this.productInfo.jwhat.payments.loyalty_units[0].loyalty_unit_quantity = 0;
        this.addProductToBag(this.productInfo);
        $('#modalOfertaFree').modal('toggle');
        this._router.navigate(['/home/store/bag']);
    }

    /**
     * Funcion para obtener el id del producto por la url que nos llega
     * @param url url con la id del producto
     */
    public getIdProduct(url: string): string {
        let arr = url.split('/');
        return arr[arr.length - 1];
    }


    public goToHome(): void {
        this._router.navigate(['/home']);
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                /* sessionStorage.setItem('inclco', JSON.stringify(this.infoClient)); */
                this.contError++;
            },
            error => {
                this.textModal = 'Tu sesión ha caducado.';
                this.textUrl = '/login';
                $('#exampleModalNotificationLogin').modal('show');
                window.parent.postMessage('HelloParentFrame!', '*');
                this.contError++;
            }
        );
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    /**
     * Funcion que actua de submenu dentro de store
     * @param opt numero de opción sobre la que se ha echo clic
     */
    public clickOpt(opt: number): void {
        switch (opt) {
            case 1:
                $('#optmenu1').addClass('selected');
                $('#optmenu2').removeClass('selected');
                $('#optmenu3').removeClass('selected');
                this._router.navigate(['/home/clubperks']);
                break;
            case 2:
                $('#optmenu1').removeClass('selected');
                $('#optmenu2').addClass('selected');
                $('#optmenu3').removeClass('selected');
                this._router.navigate(['/home/transactions']);
                break;
            case 3:
                $('#optmenu1').removeClass('selected');
                $('#optmenu2').removeClass('selected');
                $('#optmenu3').addClass('selected');
                this._router.navigate(['/home/landingpromotions'], { queryParams: { promotion: '202112NAOS' } });
                break;
        }
    }

    /**
     * Metodo para cerrar session
     */
    public closeSession(): void {
        sessionStorage.clear();
        localStorage.clear();
        this._router.navigate(['/']);
    }
}
