import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaveComersComponent } from './leavecomers.component';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

@NgModule({
    declarations: [LeaveComersComponent],
    imports: [
        CommonModule
    ],
    exports: [LeaveComersComponent],
    providers: [],
})
export class LeaveComersModule {}
