import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetalleComponent } from './detalle.component';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';

@NgModule({
    declarations: [DetalleComponent],
    imports: [
        CommonModule,
        NaosNotificationModule
    ],
    exports: [DetalleComponent],
    providers: [],
})
export class DetalleModule {}