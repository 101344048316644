<!-- <div class="footer-container">
  <div class="footer">
      <div class="block left">
          <div class="footer-sitemap">
<div class="footer-category-sitemap">
  <h4 class="footer-category-title" data-toggle="collapse" href="#collapseExample1" [attr.aria-expanded]="this.areaexpaded1">
    Institut Esthederm
  </h4>
  <ul class="footer-sitemap-list collapse show" id="collapseExample1">
    <li>
      <a href="https://www.esthederm.com/es/origen-jean-noel-thorel" title="El origen">
        El origen
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/la-diferencia-de-institut-esthederm" title="La diferencia de Institut Esthederm">
        La diferencia de Institut Esthederm
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/un-agua-de-formulacion-unica" title="Un agua de formulacion unica">
        Un agua de formulacion unica
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/la-ciencia-de-la-celula" title="La ciencia de la célula">
        La ciencia de la célula
      </a>
    </li>
                 <li>
      <a href="https://www.esthederm.com/es/el-arte-del-bronceado" title="El arte del bronceado">
        El arte del bronceado
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/la-piel-es-nuestra-especialidad" title="La piel es nuestra especialidad">
        La piel es nuestra especialidad
      </a>
    </li>
  </ul>
</div>

<div class="footer-category-sitemap">
  <h4 class="footer-category-title" data-toggle="collapse" href="#collapseExample2" [attr.aria-expanded]="this.areaexpaded2">
    Solares
  </h4>
  <ul class="footer-sitemap-list collapse show" id="collapseExample2">
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/preparar.html" title="Preparar">
        Preparar
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteger.html" title="Proteger">
        Proteger
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteccion-solar-facial.html" title="Protección solar facial">
        Protección solar facial
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/protector-mineral.html" title="Protector mineral">
        Protector mineral
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/protector-celular.html" title="Protector celular">
        Protector celular
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteccion-solar-corporal.html" title="Protección solar corporal">
        Protección solar corporal
      </a>
    </li>

    <li>
      <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/autobronceadores.html" title="Autobronceadores">
        Autobronceadores
      </a>
    </li>

    <li>
      <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/posexposicion.html" title="Posexposicion">
        Posexposicion
      </a>
    </li>
  </ul>
</div>

<div class="footer-category-sitemap">
  <h4 class="footer-category-title" data-toggle="collapse" href="#collapseExample3" [attr.aria-expanded]="this.areaexpaded3">
    Tratamientos facial
  </h4>
  <ul class="footer-sitemap-list collapse show" id="collapseExample3">
    <li>
      <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/cuidados-piel.html" title="Cuidados de la piel">
        Cuidados de la piel
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/flacidez-facial.html" title="Flacidez facial">
        Flacidez facial
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/tratamientos-faciales.html" title="Tratamientos faciales">
        Tratamientos faciales
      </a>
    </li>
  <li>
      <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/protector-facial.html" title="Protector facial">
        Protector facial
      </a>
    </li>

  </ul>
</div>

<div class="footer-category-sitemap">
  <h4 class="footer-category-title" data-toggle="collapse" href="#collapseExample4" [attr.aria-expanded]="this.areaexpaded4">
    Tratamientos corporales
  </h4>
  <ul class="footer-sitemap-list collapse show" id="collapseExample4">

    <li>
      <a href="https://www.esthederm.com/es/tratamientos-corporales/por-necesidad/reafirmar.html" title="Reafirmar">
        Reafirmar
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-corporales/por-necesidad/reducir.html" title="Reducir">
        Reducir
      </a>
    </li>
  </ul>
</div>

<div class="footer-category-sitemap">
  <h4 class="footer-category-title" data-toggle="collapse" href="#collapseExample5" [attr.aria-expanded]="this.areaexpaded5">
    Tratamientos profesionales
  </h4>
  <ul class="footer-sitemap-list collapse show" id="collapseExample5">

    <li>
      <a href="https://www.esthederm.com/es/tratamientos-profesionales/por-cuidado/deshidratacion.html" title="Deshidratación">
        Deshidratación
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-profesionales/por-cuidado/piel-sensible.html" title="Piel sensible">
        Piel sensible
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-profesionales/por-cuidado/imperfecciones.html" title="Imperfecciones">
        Imperfecciones
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-profesionales/por-cuidado/textura-irregular-manchas.html" title="Textura irregular, manchas">
        Textura irregular, manchas
      </a>
    </li>
    <li>
      <a href="https://www.esthederm.com/es/tratamientos-profesionales/por-cuidado/densida-arrugas-firmeza.html" title="Densidad, arrugas, firmeza">
        Densidad, arrugas, firmeza
      </a>
    </li>



  </ul>
</div>

<div class="footer-category-sitemap">
  <h4 class="footer-category-title" data-toggle="collapse" href="#collapseExample6" [attr.aria-expanded]="this.areaexpaded6">
    Sets de belleza
  </h4>
  <ul class="footer-sitemap-list collapse show" id="collapseExample6">
    <li>
      <a href="https://www.esthederm.com/es/sets-de-belleza/cofres-de-belleza/packs-de-belleza.html" title="Packs de belleza">
        Packs de belleza
      </a>
    </li>
  </ul>
</div>
</div>
          <div class="sep"></div>
      </div>
      <div class="block right">
          <div class="social">
<h4>SEGUIR</h4>
<ul>
  <li><a class="facebook" href="https://www.facebook.com/ESTHEDERMEspana" title="Facebook">&nbsp;</a></li>
  <li><a class="instagram" href="https://www.instagram.com/esthederm_es/" title="Instagram">&nbsp;</a></li>
</ul>
</div>
<div class="naos">
  <a href="https://naos.com/es/"><img src="https://www.esthederm.com/media/wysiwyg/footer/footer_naos.png" alt="Hydratation & Nutrition" title="Hydratation & Nutrition" /></a>
  <p>La ecobiología es el centro de nuestra filosofía para preservar mejor el ecosistema de la piel y fortalecer sus mecanismos naturales.</p>
</div>        </div>
      <div class="block legal-links">
            <ul class="legal-links-list">
  <li>
    <a href="https://www.esthederm.com/es/contacts" title="Contacto">
      Contacto
    </a>
  </li>
  <li>
    <a href="https://www.esthederm.com/es/politica-de-uso" title="Política de Uso">
         Política de Uso
    </a>
  </li>
  <li>
    <a href="https://www.esthederm.com/es/politica-de-privacidad" title="Política de Privacidad">
         Política de Privacidad
    </a>
  </li>
               <li>
    <a href="https://naos.com/en/" target="_blank" title="NAOS">
      NAOS
    </a>
  </li>
              <li>
    <a href="https://naosclub.loyaltysp.es/legals" target="_blank" title="NAOS : CONDICIONES GENERALES">
      NAOS Club : Condiciones generales
    </a>
  </li>
              <li>
    <a href="https://naosclub.loyaltysp.es/privacity" target="_blank" title="NAOS : Política de Privacidad parte 1">
         NAOS Club : Política de Privacidad
    </a>
  </li>
</ul>
      </div>
  </div>
  <div class="bottom-container">
      <div class="bottom">
          <div class="languages">
                              <span class="label">Languages&nbsp;:&nbsp;&nbsp;</span>
              <span class="current-store es">
                  <span class="arrow"></span>
              </span>
              <ul>
                                                                                                                                                                                                          <li><a target="_blank" class="goto-site fr" href="https://www.esthederm.com/fr/"></a></li>
                                                                                                                                                                                          <li><a target="_blank" class="goto-site it" href="https://www.esthederm.com/it/"></a></li>
                                                                                                                                                                                          <li><a target="_blank" class="goto-site en" href="https://www.esthederm.com/en/"></a></li>
                                                                                                                                                              <li><a target="_blank" class="goto-site ca_fr" href="https://esthederm.ca/"></a></li>
                  <li><a target="_blank" class="goto-site cn" href="https://www.institut-esthederm.com.cn/"></a></li>
                  <li><a target="_blank" class="goto-site bg" href="https://www.esthederm.bg/"></a></li>
                  <li><a target="_blank" class="goto-site co" href="https://www.esthederm.co/"></a></li>
              </ul>
          </div>
      </div>
  </div>
  <div class="end-container">
      <div class="end">
          <a href="http://www.onibi.fr/" target="_blank" class="onibi">
              Onibi - <span title="Agence E-commerce">Agence E-commerce</span>
          </a>
      </div>
  </div>
</div> -->


<div class="footer-container hide-on-mobile-tablet" style="padding: 4% 20% 0% 20%;">
  <div class="row pb-3">
    <div class="col-8">
      <h3 class="titles"><b>INSTITUT ESTHEDERM ES UNA MARCA DE NAOS</b></h3>
      <p class="titles">La ecobiología está en el corazón de NAOS para preservar mejor el ecosistema de la piel y fortalecer sus mecanismos naturales. NAOS ha creado 3 marcas inspiradas en la ecobiología.</p>
    </div>
    <div class="col-4" style="padding-left: 10%;">
      <a class="" href="https://naos.com/es/"><img src="../../../assets/images/inspired_naos.svg"/></a>
    </div>
  </div>
  <div class="row mt-3 pb-5" style="border-bottom: 1px solid silver;">
    <div class="col-12">
      <a href="https://www.bioderma.es/"><img class="imagesFooterBioderma" src="../../../assets/images/biodermaFooterEsthederm.png"/></a>
      <a href="https://www.esthederm.es/"><img class="imagesFooter" src="../../../assets/images/logoesthederm.png"/></a>
      <a href="https://www.etatpur.fr/"><img class="imagesFooterEtat" src="../../../assets/images/etatpur.png"/></a>
    </div>
  </div>
  <div class="row mt-4 mb-2 pt-4 pb-5" style="border-bottom: 1px solid silver;">
    <div class="col-3">
      <b>MAPA DEL SITIO WEB</b><br>
      <p class="mt-3"><a href="https://www.esthederm.es/">Productos</a></p><br>
      <p><a href="https://www.esthederm.es/nuestro-enfoque">Tratamientos de cabina</a></p><br>
      <p><a href="https://www.esthederm.es/nuestra-historia.html">La marca</a></p><br>
      <p><a href="https://www.esthederm.es/blog.html">Blog</a></p>
    </div>
    <div class="col-3">
      <b>INFORMACIÓN</b><br>
      <p class="mt-3"><a href="https://www.esthederm.es/faq/">Preguntas frecuentes</a></p><br>
      <p><a href="https://www.esthederm.es/politica-de-uso">Condiciones de Uso</a></p><br>
      <p><a href="https://www.esthederm.es/politica-de-privacidad">Política de privacidad</a></p><br>
      <p><a href="https://www.esthederm.es/politica-de-cookies">Política de cookies</a></p><br>
      <p><a href="https://naosclub.esthederm.es/">Naos Club</a></p><br>
      <p><a href="https://www.esthederm.es/#">Configuración de cookies</a></p><br>
    </div>
    <div class="col-3">
      <b>SERVICIOS</b><br>
      <p class="mt-3"><a href="https://www.esthederm.es/contact/">Contáctanos</a></p><br>
      <p><a href="https://www.esthederm.es/store-locator/">Puntos de venta Esthederm</a></p><br>
      <p><a href="https://www.skinscannernaos.es/?open=true">Skin SCANNER AI</a></p><br>
      <p><a href="https://www.ask-naos.es/">ASK-NAOS</a></p><br>
    </div>
    <div class="col-3">
      <p><b>SÍGUENOS</b></p><br><br>
      <a class="mt-4 iconrrss" href="https://www.facebook.com/esthedermes/"><i class="fa fa-facebook iconrrss"></i></a>
      <a class="iconrrss" href="https://www.instagram.com/esthederm_es/"><i class="fa fa-instagram iconrrss"></i></a>
      <a class="iconrrss" href="https://www.youtube.com/channel/UCQ5Wg-oSTBQidcenor6uLPg"><i class="fa fa-youtube iconrrss"></i></a>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div style="font-size: 12px;display: inline;">©2023 - reservados todos los derechos</div>
      <div style="float: right;"><a href="https://www.yateo.com/"><img style="height: 10px;width: 10px;display: inline;" src="../../../assets/images/yateo.png"/>Yateo</a></div>
    </div>
  </div>
</div>


<div class="footer-container hide-on-desktop" style="padding: 4% 10% 0% 10%;">
  <div class="row mb-4">
    <div class="col-8">
      <h3 class="titles"><b>INSTITUT ESTHEDERM ES UNA MARCA DE NAOS</b></h3>
    </div>
    <div class="col-4">
      <a href="https://naos.com/es/"><img style="height: 80px;width: 80px;" src="../../../assets/images/inspired_naos.svg"/></a>
    </div>
  </div>
  <div class="row mt-4 mb-4">
    <div class="col-12">
      <p class="titles">La ecobiología está en el corazón de NAOS para preservar mejor el ecosistema de la piel y fortalecer sus mecanismos naturales. NAOS ha creado 3 marcas inspiradas en la ecobiología.</p>
    </div>
  </div>
  <div class="row mt-4 mb-3" style="margin-top: 10%;padding-bottom: 10%; border-bottom: 1px solid silver;">
    <div class="col-4">
      <a href="https://www.bioderma.es/"><img class="imagesFooterBioderma" src="../../../assets/images/biodermaFooterEsthederm.png"/></a>
    </div>
    <div class="col-4">
      <a href="https://www.esthederm.es/"><img class="imagesFooter" src="../../../assets/images/logoesthederm.png"/></a>
    </div>
    <div class="col-4">
      <a href="https://www.etatpur.fr/"><img class="imagesFooterEtat" src="../../../assets/images/etatpur.png"/></a>
    </div>
  </div>
  <div class="row mt-4 mb-4" style="padding-bottom: 10%; border-bottom: 1px solid silver;">
    <div class="col-12">
      <ngb-accordion #acc="ngbAccordion">
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span style="color: #343f48;">MAPA DEL SITIO WEB</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="mt-3"><a href="https://www.esthederm.es/">Productos</a></p><br>
            <p class="mt-3"><a href="https://www.esthederm.es/nuestro-enfoque">Tratamientos de cabina</a></p><br>
            <p class="mt-3"><a href="https://www.esthederm.es/nuestra-historia.html">La marca</a></p><br>
            <p class="mt-3"><a href="https://www.esthederm.es/blog.html">Blog</a></p>
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span style="color: #343f48;">INFORMACIÓN</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="mt-3"><a href="https://www.esthederm.es/faq/">Preguntas frecuentes</a></p><br>
            <p class="mt-3"><a href="https://www.esthederm.es/politica-de-uso">Condiciones de Uso</a></p><br>
            <p class="mt-3"><a href="https://www.esthederm.es/politica-de-privacidad">Política de privacidad</a></p><br>
            <p class="mt-3"><a href="https://www.esthederm.es/politica-de-cookies">Política de cookies</a></p><br>
            <p class="mt-3"><a href="https://naosclub.esthederm.es/">Naos Club</a></p><br>
            <p class="mt-3"><a href="https://www.esthederm.es/#">Configuración de cookies</a></p><br>
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span style="color: #343f48;">SERVICIOS</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <p class="mt-3"><a href="https://www.esthederm.es/contact/">Contáctanos</a></p><br>
            <p class="mt-3"><a href="https://www.esthederm.es/store-locator/">Puntos de venta Esthederm</a></p><br>
            <p class="mt-3"><a href="https://www.skinscannernaos.es/?open=true">Skin SCANNER AI</a></p><br>
            <p class="mt-3"><a href="https://www.ask-naos.es/">ASK-NAOS</a></p><br>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
        <h4>SÍGUENOS</h4>
    </div>
  </div>
  <div class="row" style="padding-bottom: 10%; border-bottom: 1px solid silver;">
    <div class="col-12 text-center">
      <a class="iconrrss" href="https://www.facebook.com/esthedermes/"><i class="fa fa-facebook iconrrss"></i></a>
      <a class="iconrrss" href="https://www.instagram.com/esthederm_es/"><i class="fa fa-instagram iconrrss"></i></a>
      <a class="iconrrss" href="https://www.youtube.com/channel/UCQ5Wg-oSTBQidcenor6uLPg"><i class="fa fa-youtube iconrrss"></i></a>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div style="font-size: 12px;display: inline;">©2023 - reservados todos los derechos</div>
      <div style="float: right;"><a href="https://www.yateo.com/"><img style="height: 10px;width: 10px;display: inline;" src="../../../assets/images/yateo.png"/>Yateo</a></div>
    </div>
  </div>
</div>