import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';
import { NAOSService } from './naos.service';

@Injectable()
export class BuyService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(
        private _http: HttpClient,
        private _naosService: NAOSService
    ) {
        this.url = environment.urlAPI;
    }

    /**
     * Funcion para subir un ticket
     * @param idclient id del cliente que hace el pedido
     * @param body datos del ticket
     */
    public sendTicketPhoto(idclient: any, image: File, base64: any) {
        const formData = new FormData();
        formData.append('imageName', 'ticket' + this._naosService.getDateShortyyyyMMdd());
        formData.append('base64', base64);
        this._apiUrl = this.url + 'user/' + idclient + '/image/external';
        this._http.post(this._apiUrl, formData).subscribe(
            response => {},
            error => {
                console.error('Error' + JSON.stringify(error));
            }
        );
    }

    /**
     * Funcion para obtener los tickets subidos
     * @param idcustomer id del cliente para sacar los tickets
     * @param fechaIni fecha inicio
     * @param fechaFin fecha fin
     */
    public getTicketsList(idcustomer: any, fechaIni: any, fechaFin: any, page: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETTICKETSLIST;
        let params: HttpParams = new HttpParams();
        params = params.append('customerId', idcustomer);
        params = params.append('dateFrom', fechaIni);
        params = params.append('dateTo', fechaFin);
        params = params.append('interactionTypeSd', 'Upload');
        params = params.append('imageClassification', 'external');
        params = params.append('sizePage', '5');
        params = params.append('order', 'DESC');
        params = params.append('onlyParents', 'false');
        params = params.append('page', page);
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los canjes cancelados
     * @param idcustomer id del cliente para sacar los tickets
     * @param fechaIni fecha inicio
     * @param fechaFin fecha fin
     */
    public getTicketsListCancel(idcustomer: any, fechaIni: any, fechaFin: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETTICKETSLIST;
        let params: HttpParams = new HttpParams();
        params = params.append('customerId', idcustomer);
        params = params.append('dateFrom', fechaIni);
        params = params.append('dateTo', fechaFin);
        params = params.append('interactionTypeSd', 'Recompensas');
        return this._http.get(this._apiUrl, {params});
    }

    /**
     * Funcion para obtener los tickets subidos
     * @param idcustomer id del cliente para sacar los tickets
     * @param fechaIni fecha inicio
     * @param fechaFin fecha fin
     */
    public getTicketsListTotal(idcustomer: any, fechaIni: any, fechaFin: any): Observable<any> {
        this._apiUrl = this.url + AppSettings.GETTICKETSLIST;
        let params: HttpParams = new HttpParams();
        params = params.append('customerId', idcustomer);
        params = params.append('dateFrom', fechaIni);
        params = params.append('dateTo', fechaFin);
        params = params.append('order', 'ASC');
        params = params.append('interactionTypeSd', 'Upload');
        params = params.append('imageClassification', 'external');
        /* params = params.append('sizePage', '5'); */
/*         params = params.append('page', page); */
        return this._http.get(this._apiUrl, {params});
    }
}
