import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';
import { FormsModule } from '@angular/forms';
import { NaosNotificationModule } from 'src/app/components/naosnotification/naosnotification.module';
import { NavbarModule } from 'src/app/components/navbar/navbar.module';
import { FooterModule } from 'src/app/components/footer/footer.module';

@NgModule({
    declarations: [ContactComponent],
    imports: [
        CommonModule,
        FormsModule,
        NaosNotificationModule,
        NavbarModule,
        FooterModule
    ],
    exports: [ContactComponent],
    providers: [],
})
export class ContactModule {}
