import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';
import { LogService } from '@dagonmetric/ng-log';

@Component({
    selector: 'naos-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [NAOSService, LogService]
})
export class HomeComponent implements OnInit {

    public sub: any;
    public breadcrumbs: Array<any>;

    constructor(
        private _route: Router,
        private _naosService: NAOSService,
        private readonly logService: LogService
    ) {
        this.breadcrumbs = [{'name': 'Home', 'url': '/home'}];
    }

    ngOnInit(): void {
        this._route.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.setRuteBreadcrumbs(window.location.href);
            }
        });
        this.logService.trackPageView({
            name: 'Home',
            uri: '/home'
        });
    }

    ngOnDestroy () {
        if (this.sub !== undefined) {
            this.sub.unsubscribe();
        }
    }

    /**
     * Actualizar rutas miga de pan
     * @param url Ruta para introducir en el array de migas de pan
     */
    public setRuteBreadcrumbs(url: any): void {
        this.breadcrumbs = [];
        this.breadcrumbs.push({'name': 'Home', 'url': '/home'});
        let arrayRuta: Array<any> = [];
        url.replace('/ /', '/');
        arrayRuta = url.split('/');
        arrayRuta.splice(0, 4);
        for (let item of arrayRuta) {
            if (Object.keys(this._naosService.formatObjBreadcrumbs(item)).length !== 0) {
                this.breadcrumbs.push(this._naosService.formatObjBreadcrumbs(item));
            }
        }
    }
}
