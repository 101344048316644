import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';
import { UserService } from 'src/app/services/user.service';
declare var jQuery: any;
declare var $: any;

@Component({
    selector: 'app-naos-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css'],
    providers: [UserService, NAOSService]
})
export class TransactionsComponent implements OnInit {

    public scoreSclient: string;
    public idclient: any;

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _naosService: NAOSService
    ) {
        this.scoreSclient = '0';
    }

    ngOnInit(): void {
        this.initSubMenu();
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        this.getScoreClient();
    }

    public goToHome(): void {
        this._router.navigate(['/home']);
    }

    /**
     * Funcion para obtener las estrellas del cliente
     */
    public getScoreClient(): void {
        this._userService.getScoreClient(this.idclient).subscribe(
            response => {
                this.scoreSclient = response[0].available;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
            }
        );
    }

    /**
     * Función para inicializar la primera opción del menu al entrar
     */
    public initSubMenu(): void {
        /* $('#opt1').removeClass('optSubMenu');
        $('#opt1').addClass('optSelected'); */
        $('#opt1').addClass('selected');
    }

    /**
     * Funcion que actua de submenu dentro de store
     * @param opt numero de opción sobre la que se ha echo clic
     */
    public clickOpt(opt: number): void {
        switch (opt) {
            case 1:
                $('#opttransaction1').addClass('selected');
                $('#opttransaction2').removeClass('selected');
                this._router.navigate(['/home/transactions/buy']);
                break;
            case 2:
                $('#opttransaction2').addClass('selected');
                $('#opttransaction1').removeClass('selected');
                this._router.navigate(['/home/transactions/history']);
                break;
        }
    }
}
