import { Component, OnInit } from '@angular/core';
import { TestService } from 'src/app/services/test.service';
import { Router } from '@angular/router';
import { MediktorService } from 'src/app/services/mediktor.service';
import { v4 as uuidv4 } from 'uuid';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NAOSService } from 'src/app/services/naos.service';
import { environment } from 'src/environments/environment';
import { LogService } from '@dagonmetric/ng-log';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
    selector: 'app-myskin',
    templateUrl: './myskin.component.html',
    styleUrls: ['./myskin.component.css'],
    providers: [TestService, MediktorService, UserService, NAOSService]
})
export class MySkinComponent implements OnInit {

    public testList: Array<any>;
    public idclient: any;
    public uuidMediktorUSer: string;
    public infoClient: any;
    public externUserId: any;
    public deviceId: any;
    public authToken: any;
    public urlResultTest: any;

    public contLoaderNum: number;
    public loader: boolean;
    public pageTest: number;
    public loaderTestMediktor: boolean;
    public textModal: string;
    public textUrl: string;
    public listTestEsthederm: Array<any>;

    public showpagination: boolean;
    public valor = true;
    public valorEsthederm = true;

    public arrayTestIA: Array<any>;
    public testIADetail: any;

    constructor(
        private _testService: TestService,
        private sanitizer: DomSanitizer,
        private _mediktorService: MediktorService,
        private _router: Router,
        private _userService: UserService,
        public _naosService: NAOSService,
        private readonly logService: LogService,
        private datePipe: DatePipe
    ) {
        this.testList = [];
        this.loader = false;
        this.contLoaderNum = 0;
        this.pageTest = 0;
        this.loaderTestMediktor = false;
        this.listTestEsthederm = [];
        this.arrayTestIA = [];
        this.showpagination = false;
    }

    ngOnInit(): void {
        this.logService.trackPageView({
            name: 'Mi piel',
            uri: '/home/myskin'
        });
        this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
        if (localStorage.getItem('uuid')) {
            this.uuidMediktorUSer = localStorage.getItem('uuid');
        } else {
            this.uuidMediktorUSer = uuidv4();
        }
        this.getDataClient();
        this.getTestIA();
        let intervalo = setInterval(() => {
            if (this.contLoaderNum >= 4) {
                for (let item of this.testList) {
                    if (item.url !== undefined && item.nombretest ) {
                        item.showrow = true;
                    }
                }
                this.loader = true;
                clearInterval(intervalo);
            }
        }, 500);
    }

    public formatDate(fecha: any): number {
        let fechaFormat: number;
        let onlyDate = fecha.toString().slice(0, fecha.indexOf(' ')).split('-');
        let fechaOtherFormat = onlyDate[0] + onlyDate[1] + onlyDate[2];
        fechaFormat = parseInt(fechaOtherFormat);
        return +fechaFormat;
    }

    public getTestIA(): void {
      this._testService.getTestIA().subscribe(
        response => {
          for (let item of response) {
            item.fechaformat = this.formatDate(item.fecha);
          }
          /* this.formatDate(response[0].fecha) */
          this.arrayTestIA = response.sort((a, b) => a.fechaformat < b.fechaformat);
          
          for (let index = 0; index < this.arrayTestIA.length; index++) {

            const [ano, mes, dia] = this.arrayTestIA[index].fecha.split(" ")[0].split("-");
            let fechaFormateada = `${dia}/${mes}/${ano}`;
            this.arrayTestIA[index].fecha = fechaFormateada;
          }
        
        },
        error => {
          console.error('Error ' + JSON.stringify(error))
        }
      );
    }

    public goToHome(): void {
        this._router.navigate(['/home']);
    }

    /**
     * Funcion para obtener los datos del cliente
     */
    public getDataClient(): void {
        this._userService.getDataClient(this.idclient).subscribe(
            response => {
                this.infoClient = response;
                this.getTestTribba('DiagnosticDay');
                this.getTestTribba('SunCoach');
                this.getTestTribba('Skincoach');
                this.contLoaderNum++;
            },
            error => {
                console.error('Error ' + error.error.message);
                this.contLoaderNum++;
            }
        );
    }

    /**
     * Funcion para formatear el sexo del cliente
     * @param sex Sexo del cliente
     */
    public formatSex(sex: string): number {
        switch (sex) {
            case 'H':
                return 0;
            case 'M':
                return 1;
            case 'O':
                return 2;
        }
    }

    public redirectPopupNotification(): void {
        if (this.textUrl !== null) {
            this._router.navigate([this.textUrl]);
        }
    }

    public openTestIA(itemIA: any): void {
      this.testIADetail = itemIA;
      $('#exampleModalIframe').modal('show');
    }

    /**
     * Metodo para abrir en una pantalla nueva la compra de los productos
     */

    public openUrlNewTab(url: any): void {
      window.open(url);
    }

    /**
     * Funcion para obtener los test de Tribba
     * @param type Tipo de test
     */
    public getTestTribba(type: string): void {
        this._mediktorService.getListTestTribba(type, this.idclient).subscribe(
            response => {
                let auxItem = response;
                if (Object.entries(auxItem).length > 0) {
                    auxItem.nombretest = type;
                    this.listTestEsthederm.push(auxItem);
                }
                this.contLoaderNum++;
            },
            error => {
                console.error('Error ' + JSON.stringify(error));
                this.contLoaderNum++;
            }
        );
    }

    public downloadFile(url: string) {
        window.open(url);
    }

    public goToTestIA(): void {
      window.open('https://www.skinscannernaos.es/');
    }


  public FbotonBioderma() {
    var uno = document.getElementById('botonOnBioderma');
    this.valor ? uno.innerText = "Ocultar rutinas" : uno.innerText = "Ver rutinas";
    this.valor = !this.valor
  }

  public FbotonEsthederm() {
    var uno = document.getElementById('botonOnEsthederm');
    this.valorEsthederm ? uno.innerText = "Ocultar diagnósticos" : uno.innerText = "Ver diagnósticos realizados";
    this.valorEsthederm = !this.valorEsthederm
  }

}
