import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarEsthedermComponent } from './navbaresthederm.component';

@NgModule({
  declarations: [NavbarEsthedermComponent],
  imports: [ CommonModule ],
  exports: [NavbarEsthedermComponent],
  providers: [],
})
export class NavbarEsthedermModule {}
