<!-- <div class="row" style="height: auto" id="navbaresthederm">
    <div class="col-12">
      <div class="row hide-on-mobile-tablet" style="background-color:black;padding-left: 2%;height: 35px;">
        <div class="col-6 pl-4">
            <a style="color: white !important" href="https://www.esthederm.com/es/storelocator"><i class="fa fa-map-marker" aria-hidden="true"></i> Encontrar Institut Esthederm</a>
        </div>
        <div class="col-6" style="padding-left: 40%">
          <a class="logonaosclubresponsive" href="https://www.esthederm.com/es/naos-es"><img class="icononaosclub" src="../../../assets/images/naosesthederm.png"/></a>
        </div>
      </div>
      <div class="row hide-on-desktop pl-4 pt-4">
        <div class="col-12">
            <a style="color: black !important" href="https://www.esthederm.com/es/storelocator"><i class="fa fa-map-marker" aria-hidden="true"></i></a>
        </div>
      </div>
      <div class="row pt-4 pb-4 text-center rowlogoresponsive">
        <div class="col-12">
            <img src="../../../assets/images/logoesthederm.png"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <nav class="navbar navbar-expand-sm navbar-light">
            <button class="navbar-toggler botonmenuresponsive" type="button" data-toggle="collapse" data-target="#opciones">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse hide-on-mobile-tablet" id="opciones">

                    <ul class="navbar-nav navbarEscritorio" style="font-size: 16px !important;font-weight: lighter">
                        <li class="nav-item optmenu">
                          <a class="nav-link textmenu cursorpointer" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=1;">INSTITUT ESTHEDERM</a>
                        </li>
                        <li class="nav-item optmenu">
                          <a class="nav-link textmenu cursorpointer" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=2;">SOLARES</a>
                        </li>
                        <li class="nav-item optmenu">
                          <a class="nav-link textmenu cursorpointer" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=3;">TRATAMIENTO FACIAL</a>
                        </li>
                        <li class="nav-item optmenu">
                          <a class="nav-link textmenu cursorpointer" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=4;">TRATAMIENTOS CORPORALES</a>
                        </li>
                        <li class="nav-item optmenu">
                          <a class="nav-link textmenu cursorpointer" href="https://www.esthederm.com/es/noticias.html">NOTICIAS</a>
                        </li>
                        <li class="nav-item optmenu">
                          <a class="nav-link textmenu cursorpointer" href="https://naosclub.esthederm.es">NAOS CLUB</a>
                        </li>
                      </ul>

            </div>

            <div class="collapse navbar-collapse hide-on-desktop" id="opciones">
                <ul class="navbar-nav" style="font-size: 14px !important;font-weight: lighter ">
                  <li class="nav-item optmenu cursorpointer" style="border-bottom: 1px solid grey">
                    <a class="nav-link textmenu" data-toggle="collapse" href="#collapse1" role="button" aria-expanded="false" aria-controls="collapse1"><i class="fa fa-chevron-right" aria-hidden="true"></i> INSTITUT ESTHEDERM</a>
                  </li>
                  <div class="collapse" id="collapse1">
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/la-diferencia-de-institut-esthederm.html">La diferencia de Institut Esthederm</a>
                    </li>
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/agua-celular-bruma.html">Un agua de formulacion unica</a>
                    </li>
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/la-ciencia-de-la-celula.html">La ciencia de la célula</a>
                    </li>
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/el-arte-del-bronzeado.html">El arte del bronceado</a>
                    </li>
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/la-piel-es-nuestra-especialidad.html">La piel es nuestra especialidad</a>
                    </li>
                    <li class="submenuresponsive1">
                        <a class="nav-link textmenu" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/naos.html">NAOS CLUB</a>
                      </li>
                  </div>

                  <li class="nav-item optmenu cursorpointer" style="border-bottom: 1px solid grey">
                    <a class="nav-link textmenu" data-toggle="collapse" href="#collapse5" role="button" aria-expanded="false" aria-controls="collapse5"><i class="fa fa-chevron-right" aria-hidden="true"></i> SOLARES</a>
                  </li>
                  <div class="collapse" id="collapse5">
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" data-toggle="collapse" href="#collapse6" role="button" aria-expanded="false" aria-controls="collapse6">Por categoria</a>
                    </li>
                    <div class="collapse" id="collapse6">
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/preparar.html" class="nav-link textmenu">Preparar</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteger.html" class="nav-link textmenu">Proteger</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteccion-solar-facial.html" class="nav-link textmenu">Protección solar facial</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/protector-mineral.html" class="nav-link textmenu">Protector Mineral</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/protector-celular.html" class="nav-link textmenu">Protector Celular</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteccion-solar-corporal.html" class="nav-link textmenu">Protección solar corporal</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/autobronceadores.html" class="nav-link textmenu">Autobronceadores</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/posexposicion.html" class="nav-link textmenu">Posexposición y After Sun</a>
                      </li>
                    </div>
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" data-toggle="collapse" href="#collapse7" role="button" aria-expanded="false" aria-controls="collapse7">Por necesidad</a>
                    </li>
                    <div class="collapse" id="collapse7">
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/piel-normal.html"><h6 class="fromLeft">Solar piel normal</h6></a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/piel-sensible.html"><h6 class="fromLeft">Solar piel sensible</h6></a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/solares-antiarrugas.html"><h6 class="fromLeft">Protección solar antiarrugas</h6></a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/antimanchas.html"><h6 class="fromLeft">Protectores solares antimanchas</h6></a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/proteccion-alta.html"><h6 class="fromLeft">Protección Solar Alta</h6></a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/intolerancias.html"><h6 class="fromLeft">Intolerancias y alergias al sol</h6></a>
                      </li>
                    </div>
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" data-toggle="collapse" href="#collapse8" role="button" aria-expanded="false" aria-controls="collapse8">Por gama</a>
                    </li>
                    <div class="collapse" id="collapse8">
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-gama/bronz-repair.html" class="nav-link textmenu">Bronz Repair</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-gama/adaptasun.html" class="nav-link textmenu">Adaptasun</a>
                      </li>
                      <li class="submenuresponsive1">
                        <a href="https://www.esthederm.com/es/tratamientos-solares/por-gama/adaptasun-piel-sensible.html" class="nav-link textmenu">Adaptasun - piel sensible</a>
                      </li>
                      <li class="submenuresponsive1">
                          <a href="https://www.esthederm.com/es/tratamientos-solares/por-gama/aceite-solar.html" class="nav-link textmenu">Aceite solar</a>
                        </li>
                        <li class="submenuresponsive1">
                            <a href="https://www.esthederm.com/es/tratamientos-solares/por-gama/reflets-de-soleil.html" class="nav-link textmenu">Reflets de Soleil</a>
                          </li>
                          <li class="submenuresponsive1">
                              <a href="https://www.esthederm.com/es/tratamientos-solares/por-gama/gamme-solaire.html" class="nav-link textmenu">Gama solar</a>
                            </li>
                    </div>
                  </div>
                  <li class="nav-item optmenu cursorpointer" style="border-bottom: 1px solid grey">
                      <a class="nav-link textmenu" data-toggle="collapse" href="#collapse15" role="button" aria-expanded="false" aria-controls="collapse15"><i class="fa fa-chevron-right" aria-hidden="true"></i> TRATAMIENTO FACIAL</a>
                    </li>
                    <div class="collapse" id="collapse15">
                      <li class="submenuresponsive1">
                        <a class="nav-link textmenu" data-toggle="collapse" href="#collapse16" role="button" aria-expanded="false" aria-controls="collapse16">Por categoría</a>
                      </li>
                      <div class="collapse" id="collapse16">
                          <li class="submenuresponsive1">
                              <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/cuidados-piel.html" class="nav-link textmenu">Cuidados de la piel</a>
                            </li>
                            <li class="submenuresponsive1">
                                <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/flacidez-facial.html" class="nav-link textmenu">Flacidez facial</a>
                              </li>
                              <li class="submenuresponsive1">
                                  <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/tratamientos-faciales.html" class="nav-link textmenu">Tratamientos Faciales</a>
                                </li>
                                <li class="submenuresponsive1">
                                    <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/protector-facial.html" class="nav-link textmenu">Protector Facial</a>
                                  </li>
                                  <li class="submenuresponsive1">
                                      <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/tratamientos-contorno-ojos.html" class="nav-link textmenu">Contorno de ojos</a>
                                    </li>
                                    <li class="submenuresponsive1">
                                        <a href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/pack-facial.html" class="nav-link textmenu">Pack facial</a>
                                      </li>
                      </div>
                      <li class="submenuresponsive1">
                        <a class="nav-link textmenu" data-toggle="collapse" href="#collapse17" role="button" aria-expanded="false" aria-controls="collapse17">Por necesidad</a>
                      </li>
                      <div class="collapse" id="collapse17">
                          <li class="submenuresponsive1">
                            <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-antiarrugas.html"><h6 class="fromLeft">Antiarrugas</h6></a>
                          </li>
                          <li class="submenuresponsive1">
                            <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-hidratante.html"><h6 class="fromLeft">TRATAMIENTOS HIDRATANTES</h6></a>
                          </li>
                          <li class="submenuresponsive1">
                            <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-antimanchas.html"><h6 class="fromLeft">Antimanchas</h6></a>
                          </li>
                          <li class="submenuresponsive1">
                            <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/piel-sensible.html"><h6 class="fromLeft">Piel sensible</h6></a>
                          </li>
                          <li class="submenuresponsive1">
                            <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/tratamientos-antipolucion.html"><h6 class="fromLeft">Tratamientos antipolución</h6></a>
                          </li>
                          <li class="submenuresponsive1">
                              <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/rejuvenecimiento-facial.html"><h6 class="fromLeft">Rejuvenecimiento facial</h6></a>
                            </li>
                            <li class="submenuresponsive1">
                                <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/lifting-facial-firmeza.html"><h6 class="fromLeft">Gama Lifting Facial y Firmeza</h6></a>
                              </li>
                              <li class="submenuresponsive1">
                                  <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/piel-apagada.html"><h6 class="fromLeft">PIEL APAGADA Y SIN LUMINOSIDAD</h6></a>
                                </li>
                                <li class="submenuresponsive1">
                                    <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/revitalizante.html"><h6 class="fromLeft">Revitalizante</h6></a>
                                  </li>
                                  <li class="submenuresponsive1">
                                      <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-reafirmante.html"><h6 class="fromLeft">Tratamiento de Densidad y Firmeza</h6></a>
                                    </li>
                                    <li class="submenuresponsive1">
                                        <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-acne-imperfecciones.html"><h6 class="fromLeft">Tratamiento para acné e imperfecciones</h6></a>
                                      </li>
                                      <li class="submenuresponsive1">
                                          <a href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/limpieza-facial.html"><h6 class="fromLeft">Limpieza Facial</h6></a>
                                        </li>
                        </div>
                        <li class="submenuresponsive1">
                          <a class="nav-link textmenu" data-toggle="collapse" href="#collapse18" role="button" aria-expanded="false" aria-controls="collapse18">Por gama</a>
                        </li>
                        <div class="collapse" id="collapse18">
                            <li class="submenuresponsive1">
                                <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/osmoclean.html" class="nav-link textmenu">Osmoclean</a>
                              </li>
                              <li class="submenuresponsive1">
                                  <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/agua-celular.html" class="nav-link textmenu">Agua Celular</a>
                                </li>
                                <li class="submenuresponsive1">
                                    <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/hydra-system.html" class="nav-link textmenu">Hydra System</a>
                                  </li>
                                  <li class="submenuresponsive1">
                                      <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/sensi-system.html" class="nav-link textmenu">Sensi System</a>
                                    </li>
                                    <li class="submenuresponsive1">
                                        <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/pure-system.html" class="nav-link textmenu">Pure System</a>
                                      </li>
                                      <li class="submenuresponsive1">
                                          <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/nutri-system.html" class="nav-link textmenu">Nutri System</a>
                                        </li>
                                        <li class="submenuresponsive1">
                                            <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/radiance.html" class="nav-link textmenu">Radiance</a>
                                          </li>
                                          <li class="submenuresponsive1">
                                              <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/active-repair.html" class="nav-link textmenu">Active Repair</a>
                                            </li>
                                            <li class="submenuresponsive1">
                                                <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/lift-repair.html" class="nav-link textmenu">Lift & Repair</a>
                                              </li>
                                              <li class="submenuresponsive1">
                                                  <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/excellage.html" class="nav-link textmenu">Excellage</a>
                                                </li>
                                                <li class="submenuresponsive1">
                                                    <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/intensive.html" class="nav-link textmenu">Intensive</a>
                                                  </li>
                                                  <li class="submenuresponsive1">
                                                      <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/derm-repair.html" class="nav-link textmenu">Derm Repair</a>
                                                    </li>
                                                    <li class="submenuresponsive1">
                                                        <a href="https://www.esthederm.com/es/tratamiento-facial/por-gama/esthewhite.html" class="nav-link textmenu">EstheWhite</a>
                                                      </li>
                        </div>
                    </div>
                  <li class="nav-item optmenu cursorpointer" style="border-bottom: 1px solid grey">
                    <a class="nav-link textmenu" data-toggle="collapse" href="#collapse10" role="button" aria-expanded="false" aria-controls="collapse10"><i class="fa fa-chevron-right" aria-hidden="true"></i> TRATAMIENTOS CORPORALES</a>
                  </li>
                  <div class="collapse" id="collapse10">
                    <li class="submenuresponsive1">
                      <a class="nav-link textmenu" data-toggle="collapse" href="#collapse11" role="button" aria-expanded="false" aria-controls="collapse11">Por necesidad</a>
                    </li>
                    <div class="collapse" id="collapse11">
                        <li class="submenuresponsive1">
                            <a href="https://www.esthederm.com/es/tratamientos-corporales/por-necesidad/reafirmar.html" class="nav-link textmenu">Reafirmar</a>
                          </li>
                          <li class="submenuresponsive1">
                              <a href="https://www.esthederm.com/es/tratamientos-corporales/por-necesidad/reducir.html" class="nav-link textmenu">Reducir</a>
                            </li>
                    </div>
                  </div>
                  <li class="nav-item optmenu cursorpointer" style="border-bottom: 1px solid grey">
                      <a class="nav-link textmenu" href="https://www.esthederm.com/es/noticias.html"> NOTICIAS</a>
                    </li>
                    <li class="nav-item optmenu cursorpointer" style="border-bottom: 1px solid grey">
                        <a class="nav-link textmenu" href="https://www.esthederm.com/es/naos-es"> NAOS CLUB</a>
                      </li>
                </ul>
              </div>
          </nav>
        </div>
      </div>
      <div class="row hidden-tablet hidden-mobile" style="font-size: 12px !important;padding-bottom: 2%;" id="submenu" *ngIf="this.showsubmenu" (mouseover)="this.showsubmenu=true;" (mouseleave)="this.showsubmenu=false;">
        <div class="col-12">
          <div class="row contentsubmenu" *ngIf="this.idsubmenucontent == 1" style="font-size: 12px !important;">
              <div class="col-lg-2 col-md-2 col-xs-2"></div>
              <div class="col-lg-4 col-md-4 col-xs-4">
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/la-diferencia-de-institut-esthederm.html"><h6 class="fromLeft">La diferencia de Institut Esthederm</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/agua-celular-bruma.html"><h6 class="fromLeft">Un agua de formulacion unica</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/la-ciencia-de-la-celula.html"><h6 class="fromLeft">La ciencia de la célula</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/el-arte-del-bronzeado.html"><h6 class="fromLeft">El arte del bronceado</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/institut-esthederm/l-exception-institut-esthederm/la-piel-es-nuestra-especialidad.html"><h6 class="fromLeft">La piel es nuestra especialidad</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://naosclub.esthederm.es"><h6 class="fromLeft">NAOS CLUB</h6></a></div>
              </div>
              <div class="col-lg-3 col-md-3 col-xs-3">
                  <div class="card" style="width: 18rem;border: none !important">
                      <img class="card-img-top" src="../../../assets/images/eau_cellulaire_200x95.jpg" alt="Card image cap">
                      <div class="card-body text-center">
                        <h6 class="card-title">EAU CELLULAIRE</h6><br>
                        <p class="card-text">Eau cellulaire es la esencia de la juventud</p>
                        <a href="https://www.esthederm.com/media/qaz/qbanner/s/l/slider-4.jpg" class="btn btncardsesthedermmenu"><i class="fa fa-chevron-right" aria-hidden="true"></i> Descubrir</a>
                      </div>
                    </div>
              </div>
              <div class="col-lg-3 col-md-3 col-xs-3">
                  <div class="card" style="width: 18rem;border: none !important">
                      <img class="card-img-top" src="../../../assets/images/Institut_nav_200x95.jpg" alt="Card image cap">
                      <div class="card-body text-center">
                        <h6 class="card-title">ENCONTRAR UN CENTRO</h6><br>
                        <p class="card-text">Centros Institut Esthederm más cercanos</p>
                        <a href="https://www.esthederm.com/media/qaz/qbanner/s/l/slider-3.jpg" class="btn btncardsesthedermmenu"><i class="fa fa-chevron-right" aria-hidden="true"></i> Descubrir</a>
                      </div>
                    </div>
              </div>

          </div>
          <div class="row contentsubmenu" *ngIf="this.idsubmenucontent == 2" style="font-size: 12px !important;">
            <div class="col-3">
              <a style="color: #48545D !important" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria.html">POR CATEGORIA</a><br><br>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/preparar.html"><h6 class="fromLeft">Preparar</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteger.html"><h6 class="fromLeft">Proteger</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteccion-solar-facial.html"><h6 class="fromLeft">Protección solar facial</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/protector-mineral.html"><h6 class="fromLeft">Protección Mineral</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/protector-celular.html"><h6 class="fromLeft">Protección Celular</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/proteccion-solar-corporal.html"><h6 class="fromLeft">Protección solar corporal</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/autobronceadores.html"><h6 class="fromLeft">Autobronceadores</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-categoria/posexposicion.html"><h6 class="fromLeft">Pos Exposición y After Sun</h6></a></div>
            </div>
            <div class="col-3">
              <a style="color: #48545D !important" href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad.html">POR NECESIDAD</a><br><br>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/piel-normal.html"><h6 class="fromLeft">Solar piel normal</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/piel-sensible.html"><h6 class="fromLeft">Solar piel sensible</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/solares-antiarrugas.html"><h6 class="fromLeft">Protección solar antiarrugas</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/antimanchas.html"><h6 class="fromLeft">Protectores solares antimanchas</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/proteccion-alta.html"><h6 class="fromLeft">Protección Solar Alta</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-necesidad/intolerancias.html"><h6 class="fromLeft">Intolerancia y alergia al sol</h6></a></div>
            </div>
            <div class="col-3">
                <a style="color: #48545D !important" href="https://www.esthederm.com/es/tratamientos-solares/por-gama.html">POR GAMA</a><br><br>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-gama/bronz-repair.html"><h6 class="fromLeft">Bronz Repair</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-gama/adaptasun.html"><h6 class="fromLeft">Adaptasun</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-gama/adaptasun-piel-sensible.html"><h6 class="fromLeft">Adaptasun piel - sensible</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-gama/aceite-solar.html"><h6 class="fromLeft">Aceite solar</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-gama/reflets-de-soleil.html"><h6 class="fromLeft">Reflets de Soleil</h6></a></div>
              <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-solares/por-gama/gamme-solaire.html"><h6 class="fromLeft">Gama Solar</h6></a></div>
            </div>
            <div class="col-3">

            </div>
          </div>
          <div class="row contentsubmenu" *ngIf="this.idsubmenucontent == 3" style="font-size: 12px !important;">
              <div class="col-3">
                  <a style="color: #48545D !important" href="https://www.esthederm.com/es/tratamiento-facial/por-categoria.html">POR CATEGORIA</a><br><br>
                <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/cuidados-piel.html"><h6 class="fromLeft">Cuidados de la piel</h6></a></div>
                <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/flacidez-facial.html"><h6 class="fromLeft">Flacidez facial</h6></a></div>
                <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/tratamientos-faciales.html"><h6 class="fromLeft">Tratamientos Faciales</h6></a></div>
                <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/protector-facial.html"><h6 class="fromLeft">Protector facial</h6></a></div>
                <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/tratamientos-contorno-ojos.html"><h6 class="fromLeft">Contorno de ojos</h6></a></div>
                <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-categoria/pack-facial.html"><h6 class="fromLeft">Pack facial</h6></a></div>
              </div>
              <div class="col-3">
                  <a style="color: #48545D !important" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad.html">POR NECESIDAD</a><br><br>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-antiarrugas.html"><h6 class="fromLeft">Antiarrugas</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-hidratante.html"><h6 class="fromLeft">TRATAMIENTOS HIDRATANTES</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-antimanchas.html"><h6 class="fromLeft">Antimanchas</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/piel-sensible.html"><h6 class="fromLeft">Piel sensible</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/tratamientos-antipolucion.html"><h6 class="fromLeft">Tratamientos Antipolución</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/rejuvenecimiento-facial.html"><h6 class="fromLeft">Rejuvenecimiento facial</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/lifting-facial-firmeza.html"><h6 class="fromLeft">Gama Lifting facial y firmeza</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/piel-apagada.html"><h6 class="fromLeft">PIEL APAGADA Y SIN LUMINOSIDAD</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/revitalizante.html"><h6 class="fromLeft">Revitalizante</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-reafirmante.html"><h6 class="fromLeft">Tratamiento de Densidad y Firmeza</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/crema-acne-imperfecciones.html"><h6 class="fromLeft">Tratamiento para acné e imperfecciones</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-necesidad/limpieza-facial.html"><h6 class="fromLeft">Limpieza facial</h6></a></div>
              </div>
              <div class="col-3">
                  <a style="color: #48545D !important" href="https://www.esthederm.com/es/tratamiento-facial/por-gama.html">POR GAMA</a><br><br>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/osmoclean.html"><h6 class="fromLeft">Osmoclean</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/agua-celular.html"><h6 class="fromLeft">Agua Celular</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/hydra-system.html"><h6 class="fromLeft">Hydra System</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/sensi-system.html"><h6 class="fromLeft">Sensi System</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/pure-system.html"><h6 class="fromLeft">Pure system</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/nutri-system.html"><h6 class="fromLeft">Nutri System</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/radiance.html"><h6 class="fromLeft">Radiance</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/active-repair.html"><h6 class="fromLeft">Active Repair</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/lift-repair.html"><h6 class="fromLeft">Lift & Repair</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/excellage.html"><h6 class="fromLeft">Excellage</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/intensive.html"><h6 class="fromLeft">Intensive</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/derm-repair.html"><h6 class="fromLeft">Derm repair</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamiento-facial/por-gama/esthewhite.html"><h6 class="fromLeft">EstheWhite</h6></a></div>
              </div>
              <div class="col-3">

              </div>
          </div>
          <div class="row contentsubmenu" *ngIf="this.idsubmenucontent == 4" style="font-size: 12px !important;">
              <div class="col-lg-4 col-md-4 col-xs-4">
                  <a style="color: #48545D !important" href="https://www.esthederm.com/es/tratamientos-corporales/por-necesidad.html">POR NECESIDAD</a><br><br>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-corporales/por-necesidad/reafirmar.html"><h6 class="fromLeft">Reafirmar</h6></a></div>
                  <div class="enlacesubmenu"><a class="linkmenuesthederm" href="https://www.esthederm.com/es/tratamientos-corporales/por-necesidad/reducir.html"><h6 class="fromLeft">Reducir</h6></a></div>
              </div>
              <div class="col-lg-3 col-md-3 col-xs-3">
                  <div class="card" style="width: 18rem;border: none !important">
                      <img class="card-img-top" src="../../../assets/images/soins_corps_200x95.jpg" alt="Card image cap">
                      <div class="card-body text-center">
                        <h6 class="card-title">TRATAMIENTO DE ADELGAZAMIENTO PROFESIONAL</h6><br>
                        <p class="card-text">Descubra el tratamiento completo para afinar y remodelar la figura.</p>
                        <a href="https://www.esthederm.com/media/qaz/qbanner/s/l/slider-3.jpg" class="btn btncardsesthedermmenu"><i class="fa fa-chevron-right" aria-hidden="true"></i> Descubrir</a>
                      </div>
                    </div>
              </div>
              <div class="col-lg-3 col-md-3 col-xs-3">
                  <div class="card" style="width: 18rem;border: none !important">
                      <img class="card-img-top" src="../../../assets/images/Institut_nav_200x95.jpg" alt="Card image cap">
                      <div class="card-body text-center">
                        <h6 class="card-title">ENCONTRAR UN CENTRO</h6><br>
                        <p class="card-text">Centros Institut Esthederm más cercanos</p>
                        <a href="https://www.esthederm.com/media/qaz/qbanner/s/l/slider-2.jpg" class="btn btncardsesthedermmenu"><i class="fa fa-chevron-right" aria-hidden="true"></i> Descubrir</a>
                      </div>
                    </div>
              </div>
              <div class="col-lg-2 col-md-2 col-xs-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->


<div class="row" style="height: auto" id="navbaresthederm">
  <div class="col-12 hidden-mobile navbarBackground">
    <div class="row">
      <div class="col-2 iconsPrimary1"></div>
      <div class="col-3 iconsPrimary">
        <a style="color: #343f48 !important;font-size: 16px;" href="https://www.esthederm.es/store-locator/"><img class="ic-locator" src="https://www.esthederm.es/static/version1685115422/frontend/Esthederm/Esthederm/es_ES/images/locator.svg"/> Puntos de venta Esthederm</a>
      </div>
      <div class="col-2 iconsPrimary2">
        <a href="https://www.esthederm.es/"><img style="width: 146px;height: 54px;" src="../../../assets/images/logoesthederm.png"/></a>
      </div>
      <div class="col-3 iconsPrimary3">
        <a href="https://naosclub.esthederm.es/"><img style="width: 220px;height: 40px;" src="../../../assets/images/naosclubiconobioderma.png"/></a>
      </div>
      <div class="col-2 iconsPrimary"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8" style="padding-left: 10%;">
        <div class="row">
          <div class="col-12">
            <div class="padSecondary"><a class="iconsSecondary" href="https://www.esthederm.es/productos.html">PRODUCTOS</a></div>
            <div class="padSecondary"><a class="iconsSecondary" href="https://www.esthederm.es/los-tratamientos.html">TRATAMIENTOS</a></div>
            <div class="padSecondary"><a class="iconsSecondary" href="https://www.esthederm.es/nuestra-historia">LA MARCA</a></div>
            <div class="padSecondary"><a class="iconsSecondary" href="https://www.esthederm.es/blog.html">MAGAZINE</a></div>
            <div class="padSecondary"><a class="iconsSecondary" style="color: #0070a1 !important;" href="https://www.esthederm.es/ciencia-proteoma">LA CIENCIA DEL PROTEOMA</a></div>
          </div>
          
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>  
  <div class="col-12 hide-on-desktop navbarBackground">
    <div class="row" style="padding: 5% 0% 5% 0%;">
      <div class="col-3" style="padding-left: 6%;">
        <button class="navbar-toggler" style="border: 1px solid silver;margin-left: 5%;" type="button" data-toggle="collapse" data-target="#opciones">
          <img style="height: 25px;width: 25px;" src="../../../assets/images/hamburguer.png"/>
        </button>
      </div>
      <div class="col-4 text-center">
        <a style="margin-right: -60px;" href="https://www.esthederm.es/"><img style="width: 100px;height: auto;" src="../../../assets/images/logoesthederm.png"/></a>
      </div>
      <div class="col-5 text-center">
        <a style="margin-right: -40px;" href="https://naosclub.esthederm.es/"><img style="width: 100px;height: 20px;" src="../../../assets/images/naosclubiconobioderma.png"/></a>
      </div>
      <!-- enlaces -->
    <div class="collapse navbar-collapse" id="opciones">   
      <ul class="navbar-nav" style="padding: 10%;">
        <li class="nav-item" style="border-bottom: 1px solid silver;padding: 3%;">
          <a style="color: #343f48 !important;" class="nav-link" href="https://www.esthederm.es/productos.html">PRODUCTOS</a>
        </li>
        <li class="nav-item" style="border-bottom: 1px solid silver;padding: 3%;">
          <a style="color: #343f48 !important;" class="nav-link" href="https://www.esthederm.es/los-tratamientos.html">TRATAMIENTOS</a>
        </li>
        <li class="nav-item" style="border-bottom: 1px solid silver;padding: 3%;">
          <a style="color: #343f48 !important;" class="nav-link" href="https://www.esthederm.es/nuestra-historia">LA MARCA</a>
        </li>
        <li class="nav-item" style="border-bottom: 1px solid silver;padding: 3%;">
          <a style="color: #343f48 !important;" class="nav-link" href="https://www.esthederm.es/blog.html">MAGAZINE</a>
        </li>    
        <li class="nav-item" style="border-bottom: 1px solid silver;padding: 3%;">
          <a style="color: #0070a1 !important;" class="nav-link" href="https://www.esthederm.es/ciencia-proteoma">LA CIENCIA DEL PROTEOMA</a>
        </li>  
        <li class="nav-item" style="border-bottom: 1px solid silver;padding: 3%;">
          <a class="nav-link" href="https://www.esthederm.es/blog.html">
            <a style="color: #343f48 !important;font-size: 16px;" href="https://www.esthederm.es/store-locator/"><img class="ic-locator" src="https://www.esthederm.es/static/version1685115422/frontend/Esthederm/Esthederm/es_ES/images/locator.svg"/> Dónde encontrarnos</a>
          </a>
        </li>          
      </ul>
    </div>
    </div>
  </div>
</div>