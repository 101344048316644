import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacityNewSecondComponent } from './privacitynewsecond.component';

@NgModule({
  declarations: [PrivacityNewSecondComponent],
  imports: [ CommonModule ],
  exports: [PrivacityNewSecondComponent],
  providers: [],
})
export class PrivacityNewSecondModule {}
