import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-naos-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() contenidoApartadosHome: any;
  public arrOptionsMenu: Array<any>;

    constructor(
      public _router: Router
    ) {
      this.arrOptionsMenu = [];
    }

    ngOnInit(): void {
      this.formatArrayHome(this.contenidoApartadosHome);
    }

    /**
     * Funcion para formatear las opciones del menu y que se muestren correctamente
     * @param arrayMenuContent array con las opciones del menu
     */
    public formatArrayHome(arrayMenuContent: Array<any>): void {
      let arrayAux: Array<any> = [];
      for (let item of arrayMenuContent) {
        let obj = {
          'name': item.content.item_ld.es,
          'path': item.content.clikLink.es,
          'urlimage': item.content.images.web.es[0].url,
          'order': item.content.locationPriority,
          'description': item.content.instructions.es.web
        };
        arrayAux.push(obj);
      }
      this.arrOptionsMenu = arrayAux;
    }

  public goToPage(page: any) {
    if (page.indexOf('https') > -1) {
      window.open(page, '_blank');
    } else {
      if (page.indexOf('?') > -1) {
        let url = page.substring(0, page.indexOf('?'));
        this._router.navigate([url], { queryParams: { promotion: '202112NAOS' } });
      } else {
        this._router.navigate([page]);
      }
    }
  }
}


