import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { MenuModule } from 'src/app/components/menu-naos/menu.module';
import { ProfileModule } from 'src/app/components/profile/profile.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        CommonModule,
        MenuModule,
        ProfileModule,
        NgbModule
    ],
    exports: [DashboardComponent],
    providers: [],
})
export class DashboardModule {}