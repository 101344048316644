import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAOSService } from 'src/app/services/naos.service';
declare var $: any;
declare var require: any;

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css'],
  providers: [NAOSService]
})
export class PromotionsComponent implements OnInit {

  public intervalRefresAdvserver: any;
  public contentAdvServerPromotions: Array<any>;
  public loaderPromotions: boolean;
  public idclient: any;
  public contentCabecera: any;
  public showCumplimiento: boolean;

  constructor(
    private _router: Router,
    private _naosService: NAOSService
  ) {
    this.contentAdvServerPromotions = [];
    this.loaderPromotions = false;
    $('[data-toggle=popover]').popover();
    this.showCumplimiento = false;
  }

  ngOnInit(): void {
    this.idclient = this._naosService.desencryptData(sessionStorage.getItem('ILCK'));
    this.contentAdvServerPromotions = this._naosService.getOfferAdvByNameContentPlusPromotions('Promotions - promotion', JSON.parse(this._naosService.desencryptData(sessionStorage.getItem('ADVP'))));
    this.contentCabecera = this._naosService.getOfferAdvByNameContent('Promotions', JSON.parse(this._naosService.desencryptData(sessionStorage.getItem('ADVP'))));
    this.intervalRefresAdvserver = setInterval(() => {
      this.getAdvserverPrivate();
      this.contentAdvServerPromotions = this._naosService.getOfferAdvByNameContentPlusPromotions('Promotions - promotion', JSON.parse(this._naosService.desencryptData(sessionStorage.getItem('ADVP'))));
      this.contentCabecera = this._naosService.getOfferAdvByNameContent('Promotions', JSON.parse(this._naosService.desencryptData(sessionStorage.getItem('ADVP'))));
      if (this.contentAdvServerPromotions.length > 0) {
        clearInterval(this.intervalRefresAdvserver);
      }
    }, 1000);
    this.getAdvserverPrivate();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalRefresAdvserver);
  }

  public sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  /**
   * Funcion para calcular el porcentaje de la oferta que ha completado el usuario
   * @param cumplido numero de ofertas/challenges cumplidos por el cliente
   * @param completo numero de oferas/challenges que tiene que cumplir el cliente
   */
  public calcPerfect(cumplido: any,completo: any): string {
    let percent: any = 0;
    let completoParse = parseInt(completo);
    if (completoParse > 0) {
      percent = (cumplido / completoParse)*100;
      percent = Math.round(percent);
    } else {
      percent = 0;
    }
    return percent.toString() + '%';
  }

  /**
   * Funcion para calcular el % de retos que tiene completados el usuario
   * @param arrayRetos Array con los retos de la promocion
   */
  public calcPercentArray(arrayRetos: Array<any>): string {
    let percent: any = 0;
    let numCompletadas: number = 0;
    let numIncompletas: number = 0;
    let numTotal: number = 0;
    for (let reto of arrayRetos) {
      for (let ofertaReto of reto.offers) {
        if (ofertaReto.accomplishment == '1') {
          numCompletadas++;
        } else if(ofertaReto.accomplishment == '0') {
          numIncompletas++;
        }
        numTotal++;
      }
    }
    percent = (numCompletadas / numTotal)*100;
    return percent.toString();
  }

  public showCumplimientoHover(indice: any): void {
    this.showCumplimiento = true;
    let marca2 = '#cumplimientotexto' + indice;
    $(marca2).addClass('cumplimiento');
    $(marca2).removeClass('nomostrar');

  }

  public deleteCumplimientoHover(indice: any): void {
    this.showCumplimiento = false;
    let marca2 = '#cumplimientotexto' + indice;
    $(marca2).removeClass('cumplimiento');
    $(marca2).addClass('nomostrar');
  }

  public goToButtonLink(url: any): void {
    if (url.indexOf('https://') > -1) {
      window.open(
        url,
        '_blank'
      );
    } else {
      window.location.href = url;
    }
  }

  /**
     * Funcion para volver atras
     */
    public goToAfter(): void {
      this._naosService.returnNavigationPageBack();
    }

  public goToHome(): void {
    this._router.navigate(['/home']);
  }

  public getAdvserverPrivate(): void {
    this._naosService.getAdvserverContentPrivate(this.idclient).subscribe(
      response => {
        sessionStorage.setItem('ADVP',this._naosService.encryptData(JSON.stringify(response)));
        this.loaderPromotions = true;
      },
      error => {
          console.error('Error ' + JSON.stringify(error));
          this.loaderPromotions = true;
      }
    );
}
}
