import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../constants.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class LandingService {

    /**
     * Variables para la ruta al API
     */
    private _apiUrl: string;
    private url: string;

    constructor(
        private _http: HttpClient,
        private router: Router
    ) {
        this.url = environment.urlAPI;
    }

    /**
     * Peticion de registro usuario
     */
    public sendCode(code: any, clientId: any, promotionId: any): Observable<any> {
      let obj = {
        'campaign_cd': promotionId,
        'code': code,
        'customer_id': parseInt(clientId)
      };
        this._apiUrl = this.url + AppSettings.CHECKCODELANDING;
        return this._http.post(this._apiUrl, obj);
    }
}
